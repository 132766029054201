import { useQuery } from 'react-query';
import { useIntentContext } from '../../hooks';

// API REQUESTS
import { fetchConsumerIntPFAData } from '../../api';

// COMPONENTS
import DemandF from './PFA_comp/DemandF';
import DemandFG from './PFA_comp/DemandFG';
import YoYChange from './PFA_comp/YoYChange';
import OutletSpinner from '../OutletSpinner';
import ProductGrowth from './PFA_comp/ProductGrowth';
import YoYChangeDemandFTR from './PFA_comp/YoYChangeDemandFTR';
import MindsetBreakdownperProduct from './PFA_comp/MindsetBreakdownperProduct';

const ProductFeatureAnalysis: React.FC = () => {
  const { selectedProducts, selectedVerticals, dateRange } = useIntentContext();

  const {
    error,
    data: consumerIntPFAData,
    isLoading,
  } = useQuery(
    [
      'consumerIntPFAData',
      dateRange?.startDate,
      dateRange?.endDate,
      selectedVerticals,
      selectedProducts,
    ],
    () =>
      fetchConsumerIntPFAData(dateRange, selectedVerticals, selectedProducts),
    {
      enabled: !!dateRange?.startDate && !!dateRange.endDate,
    }
  );

  // JSX
  return isLoading ? (
    <OutletSpinner />
  ) : error ? (
    <div>Error occurred while fetching data.</div>
  ) : consumerIntPFAData ? (
    <>
      <ProductGrowth
        data={consumerIntPFAData?.product_growth_and_decline_chart}
        fastest_growing_product={
          consumerIntPFAData?.fastest_growing_product_scorecard
        }
        emerging_products={consumerIntPFAData?.emerging_products_scorecard}
        fastest_growing_product_real_terms={
          consumerIntPFAData?.fastest_growing_product_real_terms_scorecard
        }
      />
      <MindsetBreakdownperProduct
        data={consumerIntPFAData?.mindset_breakdown_per_product_chart}
      />
      <DemandFG data={consumerIntPFAData?.demand_by_feature_group_chart} />
      <DemandF data={consumerIntPFAData?.demand_by_feature_chart} />
      <YoYChange
        data={consumerIntPFAData?.yoy_change_in_demand_by_feature_group_chart}
      />
      <YoYChangeDemandFTR
        data={consumerIntPFAData?.yoy_change_in_demand_by_feature_chart}
      />
    </>
  ) : null;
};

export default ProductFeatureAnalysis;
