import 'chart.js/auto';
import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartOptions, Chart, registerables } from 'chart.js';

Chart.register(...registerables);

interface YoYGrowthBarChartProps {
  growthPercentageData: Array<{
    Feature_Group: string;
    demand_previous_year: number;
    demand_current_year: number;
    growth_percentage: number;
  }>;
}

const YoYGrowthBarChart: React.FC<YoYGrowthBarChartProps> = ({
  growthPercentageData,
}) => {
  const colors = [
    'rgba(34, 34, 34, 0.80)',
    'rgba(89, 89, 89, 0.8)',
    'rgba(144, 144, 144, 0.8)',
    'rgba(50, 215, 111, 0.8)',
    'rgba(3, 197, 255, 0.8)',
    'rgba(84, 79, 197, 0.8)',
    'rgba(254, 106, 53, 0.8)',
    'rgba(107, 138, 188, 0.8)',
    'rgba(213, 104, 251, 0.8)',
    'rgba(47, 224, 202, 0.8)',
    'rgba(250, 75, 66, 0.8)',
    'rgba(254, 181, 106, 0.8)',
    'rgba(254, 181, 106, 0.8)',
  ];

  const labels = growthPercentageData.map((item) => item.Feature_Group);
  const dataValues = growthPercentageData.map((item) => item.growth_percentage);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartWidth = chartRef.current ? chartRef.current.offsetWidth : 800;
  const calculatedBarThickness = labels.length === 1 ? 96 : Math.max(10, chartWidth / (labels.length * 2));


  const YoYGrowthData = {
    labels,
    datasets: [
      {
        label: 'YoY Growth',
        data: dataValues,
        backgroundColor: colors,
        borderWidth: 1,
        borderRadius: 8,
        barThickness: calculatedBarThickness,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toFixed(2) + '%';
            }
            return label;
          }
        }
      },
      title: {
        display: false,
      },
      datalabels: {
        color: '#4D4D4D',
        font: {
          weight: 'bold',
          size: 14,
        },
        formatter: (value) => value.toFixed(2) + '%',
        align: 'top',
        offset: 2,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Feature Group',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: '% YoY Growth',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          stepSize: 50,
          align: 'center',
          callback: (value) => `${value}%`,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
        max: 100,
        min: -100,
      },
    },
  };

  // const drawLabel = (chart: Chart<'bar'>) => {
  //   const ctx = chart.ctx;
  //   ctx.save();

  //   chart.data.datasets.forEach((dataset, datasetIndex) => {
  //     chart.getDatasetMeta(datasetIndex).data.forEach((bar, index) => {
  //       const value = dataset.data[index] as number;
  //       const model = bar as BarElement;
  //       const yScale = chart.scales.y;
  //       const base = yScale.getPixelForValue(0);
  //       const barHeight = Math.abs(base - model.y);
  //       const label = `${value.toFixed(2)}%`;

  //       ctx.font = 'bold 14px DM Sans';
  //       ctx.textAlign = 'center';

  //       const barBgColor = colors[index % colors.length];

  //       if (barBgColor === 'rgba(34, 34, 34, 0.80)') {
  //         if (barHeight < 10 && barHeight > 0.1) {
  //           ctx.fillStyle = '#1A1A1A';
  //           ctx.fillText(label, model.x, model.y - 5);
  //         } else {
  //           ctx.fillStyle = '#ffffff';
  //           const centerY =
  //             value > 0 ? model.y + barHeight / 2 : model.y - barHeight / 2;
  //           ctx.fillText(label, model.x, centerY);
  //         }
  //       } else {
  //         ctx.fillStyle = '#1A1A1A';
  //         if (barHeight < 10 && barHeight > 0.1) {
  //           ctx.fillText(label, model.x, model.y - 5);
  //         } else {
  //           const centerY =
  //             value > 0 ? model.y + barHeight / 2 : model.y - barHeight / 2;
  //           ctx.fillText(label, model.x, centerY);
  //         }
  //       }
  //     });
  //   });

  //   ctx.restore();
  // };

  // const customPlugin: Plugin<'bar'> = {
  //   id: 'customPlugin',
  //   afterDatasetsDraw: (chart) => {
  //     drawLabel(chart);
  //   },
  // };

  return (
    <div ref={chartRef} className="bg-light rounded-2xl">
      <div className="flex relative">
        <div className="flex px-2 py-6 justify-center items-center md:h-[295px] w-full h-[295px]">
          <Bar
            data={YoYGrowthData}
            options={options}
            plugins={[ChartDataLabels]}
          />
        </div>
      </div>
    </div>
  );
};

export default YoYGrowthBarChart;
