import React, { useState, useEffect } from 'react';

type Category = 'Auto Optimize' | 'Insights' | 'Competitive Intel' | 'Consumer Intent' | string;

interface GlossaryItem {
  term: string;
  description: string;
  category: Category;
}

const categoryColors: Record<Category, string> = {
  'Auto Optimize': 'bg-blue500 text-light',
  'Insights': 'bg-yellow500 text-secondarygray',
  'Competitive Intel': 'bg-success text-light',
  'Consumer Intent': 'bg-thirdgray text-light',
  'default': 'bg-gray100 text-secondarygray',
};

const Glossary: React.FC = () => {
  const [glossaryData, setGlossaryData] = useState<Record<string, GlossaryItem[]>>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLetter, setSelectedLetter] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/glossary/');
      const data = await response.json();
      setGlossaryData(data);
    };

    fetchData();
  }, []);

  const getFilteredData = () => {
    if (!selectedLetter) {
      // Return all data if no specific letter is selected
      return Object.keys(glossaryData).reduce((result, letter) => {
        result[letter] = glossaryData[letter].filter((item: GlossaryItem) =>
          item.term.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return result;
      }, {} as Record<string, GlossaryItem[]>);
    } else {
      return {
        [selectedLetter]: glossaryData[selectedLetter]?.filter((item: GlossaryItem) =>
          item.term.toLowerCase().includes(searchTerm.toLowerCase())
        ) || []
      };
    }
  };

  const filteredData = getFilteredData();

  // Alphabet array for navigation
  const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

  return (
    <div>
      {/* Navigation and Search Bar */}
      <div className="flex flex-row gap-4 items-center justify-between bg-light border text-sm rounded-2xl px-4 py-2 mt-12">
        <div className="flex flex-wrap space-x-2 bg-fourthgray py-1 px-1 rounded-lg">
          {alphabet.map((letter) => (
            <button
              key={letter}
              className={`px-1 py-1 rounded ${
                selectedLetter === letter ? 'bg-primary text-primarygray font-bold shadow' : ''
              } ${glossaryData[letter] && glossaryData[letter].length > 0 ? '' : 'text-gray100'}`}
              onClick={() => setSelectedLetter(letter)}
              disabled={!glossaryData[letter] || glossaryData[letter].length === 0}
            >
              {letter}
            </button>
          ))}
        </div>
        <div className="relative text-thirdgray text-sm font-bold border border-gray300 rounded-full w-full lg:w-[401px]">
          <input
            type="search"
            name="search"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="h-10 text-sm pl-10 pr-4 w-full rounded-full"
          />
          <img
            src={`${process.env.PUBLIC_URL}/static/img/Trailing_icon.svg`}
            alt="search_icon"
            className="absolute left-3 top-3 h-4 w-4 fill-current"
          />
        </div>
      </div>

      {/* Glossary Content */}
      <div className="my-8">
        {Object.keys(filteredData).map((letter) => (
          <div key={letter} className="mb-8 rounded-2xl bg-light shadow">
            <div className="border-b border-success px-4 py-2">
              <h2 className="text-primarygray text-2xl font-bold">{letter}</h2>
            </div>
            <div className="mt-2 grid grid-cols-1 lg:grid-cols-2 gap-2">
              {filteredData[letter].length > 0 ? (
                filteredData[letter].map((item: GlossaryItem, index: number) => (
                  <div key={index} className="m-4">
                    <div className="flex space-x-2 items-center">
                      <h3 className="font-bold">{item.term}</h3>
                      <span
                        className={`px-4 py-1 rounded-md text-sm ${
                          categoryColors[item.category] || categoryColors['default']
                        }`}
                      >
                        {item.category}
                      </span>
                    </div>
                    <p className="text-sm text-thirdgray">{item.description}</p>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray500 px-4 py-1">No results found for &quot;{searchTerm}&quot;.</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Glossary;