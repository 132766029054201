import React, { useEffect, useState, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import CustomLegend2 from './CustomLegend2';
import { ChartOptions, ChartData } from 'chart.js';
import { useQuery } from 'react-query';
import LoadingSpinner from './LoadingSpinner';
import { downloadImage,downloadXLS } from 'utils/download'

interface ClusterData {
  cluster_product_name: string;
  organic_revenue: number;
  paid_revenue: number;
  organic_transactions: number;
  paid_transactions: number;
  year_week: string;
}

interface APIResponse {
  overview: ClusterData[];
}

interface Dataset {
  label: string;
  data: number[];
  borderColor: string;
  borderDash?: number[];
  borderWidth: number;
  fill: boolean;
  pointBackgroundColor: string;
  pointBorderColor: string;
  pointRadius: number;
  tension: number;
  hidden?: boolean;
}

interface Cluster1Props {
  startDate: string;
  endDate: string;
}

const Cluster1: React.FC<Cluster1Props> = ({ startDate, endDate }) => {
  const [revenueOverviewData, setRevenueOverviewData] = useState<ChartData<'line'>>({
    labels: [],
    datasets: [],
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [visibility, setVisibility] = useState<Record<string, boolean>>({
    'Organic revenue Brand Generic': true,
    'Paid revenue Brand Generic': true,
    'Organic revenue Product Line': true,
    'Paid revenue Product Line': true,
    'Organic revenue Other': true,
    'Paid revenue Other': true,
  });

  const [isError404, setIsError404] = useState(false);

  const fetchRevenueData = async (): Promise<APIResponse> => {
    const response = await fetch(`/get_cluster_data/overview/${startDate}/${endDate}`);
    if (!response.ok) {
      if (response.status === 404) {
        setIsError404(true);
      }
      const errorData = await response.json();
      if (errorData.error === "'json_data'") {
        throw new Error('No data for this time period, please change the date range');
      }
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  };

  const { data, isLoading, isError } = useQuery(['revenueData', startDate, endDate], fetchRevenueData);

  const isAllNumbersZero = (data: ClusterData[]): boolean => {
    return data.every(item =>
      item.organic_revenue === 0 &&
      item.paid_revenue === 0
    );
  };

  useEffect(() => {
    if (data) {
      const relevantData = data.overview.filter(item =>
        item.cluster_product_name === 'Product Line' ||
        item.cluster_product_name === 'Brand Generic' ||
        item.cluster_product_name === 'Other'
      );

      if (relevantData.length === 0 || isAllNumbersZero(relevantData)) {
        return;
      }

      const weekSet = new Set(relevantData.map(item => {
        const weekStr = item.year_week.split('_')[1];
        return `Week ${weekStr.replace('W', '')}`;
      }));

      const weeks = Array.from(weekSet);

      const datasets: Dataset[] = [
        {
          label: 'Organic revenue Brand Generic',
          data: weeks.map(week => {
            const weekData = relevantData.filter(item => `Week ${item.year_week.split('_')[1].replace('W', '')}` === week);
            return weekData.reduce((acc, item) => acc + (item.cluster_product_name === 'Brand Generic' ? item.organic_revenue : 0), 0);
          }),
          borderColor: 'rgba(50, 215, 111, 0.8)',
          borderWidth: 1.5,
          fill: false,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: 'rgba(50, 215, 111, 0.8)',
          pointRadius: 2,
          tension: 0.4,
          hidden: !visibility['Organic revenue Brand Generic'],
        },
        {
          label: 'Paid revenue Brand Generic',
          data: weeks.map(week => {
            const weekData = relevantData.filter(item => `Week ${item.year_week.split('_')[1].replace('W', '')}` === week);
            return weekData.reduce((acc, item) => acc + (item.cluster_product_name === 'Brand Generic' ? item.paid_revenue : 0), 0);
          }),
          borderColor: 'rgba(50, 215, 111, 0.8)',
          borderDash: [9, 7],
          borderWidth: 1.5,
          fill: false,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: 'rgba(50, 215, 111, 0.8)',
          pointRadius: 2,
          tension: 0.4,
          hidden: !visibility['Paid revenue Brand Generic'],
        },
        {
          label: 'Organic revenue Product Line',
          data: weeks.map(week => {
            const weekData = relevantData.filter(item => `Week ${item.year_week.split('_')[1].replace('W', '')}` === week);
            return weekData.reduce((acc, item) => acc + (item.cluster_product_name === 'Product Line' ? item.organic_revenue : 0), 0);
          }),
          borderColor: 'rgba(3, 197, 255, 0.8)',
          borderWidth: 1.5,
          fill: false,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: 'rgba(3, 197, 255, 0.8)',
          pointRadius: 2,
          tension: 0.4,
          hidden: !visibility['Organic revenue Product Line'],
        },
        {
          label: 'Paid revenue Product Line',
          data: weeks.map(week => {
            const weekData = relevantData.filter(item => `Week ${item.year_week.split('_')[1].replace('W', '')}` === week);
            return weekData.reduce((acc, item) => acc + (item.cluster_product_name === 'Product Line' ? item.paid_revenue : 0), 0);
          }),
          borderColor: 'rgba(3, 197, 255, 0.8)',
          borderDash: [9, 7],
          borderWidth: 1.5,
          fill: false,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: 'rgba(3, 197, 255, 0.8)',
          pointRadius: 2,
          tension: 0.4,
          hidden: !visibility['Paid revenue Product Line'],
        },
        {
          label: 'Organic revenue Other',
          data: weeks.map(week => {
            const weekData = relevantData.filter(item => `Week ${item.year_week.split('_')[1].replace('W', '')}` === week);
            return weekData.reduce((acc, item) => acc + (item.cluster_product_name === 'Other' ? item.organic_revenue : 0), 0);
          }),
          borderColor: 'rgba(84, 79, 197, 0.8)',
          borderWidth: 1.5,
          fill: false,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: 'rgba(84, 79, 197, 0.8)',
          pointRadius: 2,
          tension: 0.4,
          hidden: !visibility['Organic revenue Other'],
        },
        {
          label: 'Paid revenue Other',
          data: weeks.map(week => {
            const weekData = relevantData.filter(item => `Week ${item.year_week.split('_')[1].replace('W', '')}` === week);
            return weekData.reduce((acc, item) => acc + (item.cluster_product_name === 'Other' ? item.paid_revenue : 0), 0);
          }),
          borderColor: 'rgba(84, 79, 197, 0.8)',
          borderDash: [9, 7],
          borderWidth: 1.5,
          fill: false,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: 'rgba(84, 79, 197, 0.8)',
          pointRadius: 2,
          tension: 0.4,
          hidden: !visibility['Paid revenue Other'],
        },
      ];

      setRevenueOverviewData({
        labels: weeks,
        datasets: datasets,
      });
    }
  }, [data, visibility]);

  const handleLegendClick = (label: string) => {
    setVisibility(prevState => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  const formatYAxisTick = (tickValue: number) => {
    if (tickValue >= 1_000_000) {
      return `$${(tickValue / 1_000_000).toFixed(0)}M`;
    } else if (tickValue >= 1_000) {
      return `$${(tickValue / 1_000).toFixed(0)}K`;
    } else {
      return tickValue.toString();
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [])

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Weeks',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Revenue',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return formatYAxisTick(tickValue);
            }
            return tickValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError404) {
    return (
      <div className="relative">
        <div className="bg-light rounded-2xl shadow-sm mt-8 blur">
          <div className="flex justify-between items-center border-b border-success mb-2">
            <div>
              <h2 className="text-primarygray text-2xl font-bold mb-2 pl-4 pt-2">Revenue Overview</h2>
              <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">Understand evolution of holistic search revenue</h3>
            </div>
            <img src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`} alt="dots" className="h-6 w-6 mr-4" />
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[80%] xl:w-[80%] md:w-[80%] w-[80%]">
              <Line data={revenueOverviewData} options={options} />
            </div>
            <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
              <CustomLegend2 legendSet="set1" onLegendClick={handleLegendClick} visibility={visibility} />
            </div>
          </div>
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-light mt-4 bg-thirdgray rounded-2xl shadow-sm py-2 px-4">
            An error has occurred
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return <div>No data for this time period, please change the date range</div>;
  }

  if (revenueOverviewData.datasets.length === 0) {
    return null;
  }

  const downloadChartData = () => {
    const headers = ['Week', ...revenueOverviewData.datasets.map(dataset => dataset.label as string)];
    const labels = revenueOverviewData.labels as string[];
    const columns = revenueOverviewData.datasets.map(dataset => dataset.data as number[]);
  
    downloadXLS('revenue_overview', headers, labels, columns);
  };

  const downloadChartImage = () => {
    downloadImage('revenueOverviewChart');
  };

  return (
    <div id="revenueOverviewChart" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-2 pl-4 pt-2">Revenue Overview</h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">Understand evolution of holistic search revenue</h3>
        </div>
        <div className='flex items-center' ref={dropdownRef} id='downloadDropdownMenu-revenueOverviewChart'>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50" style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}>
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartData();
                      closeDropdown();
                    }}
                >
                    Download XLS
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[80%] xl:w-[80%] md:w-[80%] w-[80%]">
          <Line data={revenueOverviewData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <CustomLegend2 legendSet="set1" onLegendClick={handleLegendClick} visibility={visibility} />
        </div>
      </div>
    </div>
  );
};

export default Cluster1;
