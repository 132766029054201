import React from 'react';
import { subDays } from 'date-fns';
import { formatDateRange } from 'utils';
import Datepicker, { DatepickerType } from 'react-tailwindcss-datepicker';

interface DatepickerComponentProps extends DatepickerType {
  product: string;
}

const DatepickerComponent: React.FC<DatepickerComponentProps> = ({
  value,
  product,
  onChange,
  ...props
}) => {
  let maxDate;
  if (product === 'osab') {
    maxDate = subDays(new Date(), 4);
  } else if (product === 'insights') {
    maxDate = subDays(new Date(), 3);
  } else if (product === 'comp_int') {
    maxDate = subDays(new Date(), 0);
  } else {
    maxDate = new Date();
  }

  return (
    <div className="flex border rounded-lg bg-light text-sm text-primarygray dark:bg-light font-bold py-2 px-1 h-10 w-full min-w-[320px] max-w-[320px]">
      <Datepicker
        primaryColor={'green'}
        separator={' - '}
        showShortcuts={true}
        showFooter={false}
        value={value}
        displayFormat="DD MMM, YYYY"
        inputClassName="w-full text-center"
        maxDate={maxDate}
        popoverDirection="down"
        onChange={(newValue) => onChange(formatDateRange(newValue))}
        {...props}
      />
      <img
        src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
        className="h-3 w-3 my-1 ml-1 transform rotate-90"
        alt="Arrow"
      />
    </div>
  );
};

export default DatepickerComponent;
