import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

type TabOption = {
  label: string;
  value: string;
};

interface NavTabsProps {
  options: TabOption[] | readonly TabOption[];
  onChange?: (value: string) => void;
  value?: string;
  children: React.ReactNode;
  customItems?: React.ReactNode;
}

// CONSUMER INTENT (MAIN PAGE)
function NavTabs({
  options,
  onChange,
  value,
  children,
  customItems,
}: NavTabsProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(options[0].value);

  useEffect(() => {
    const active = options.find(({ value }) => value === pathname)?.value;
    if (!active) {
      navigate(options[0].value);
      return;
    }
    setActiveTab(active || options[0].value);
  }, [pathname, options, navigate]);

  useEffect(() => {
    if (!value) return;
    setActiveTab(value);
  }, [value]);

  const renderedTabs = options.map(({ label, value }) => (
    <div
      className={`text-primarygray font-medium cursor-pointer py-4 px-8 ${
        activeTab === value ? 'border-b-2 border-primarygray' : ''
      }`}
      onClick={() => {
        if (onChange) {
          onChange(value);
        }
        navigate(value);
      }}
      key={value}
    >
      {label}
    </div>
  ));

  // JSX
  return (
    <div className="flex flex-col">
      <div className="sticky top-[94px] z-10 flex bg-body border-b border-gray300 whitespace-nowrap">
        {renderedTabs}
        {customItems ? (
          <div className="flex-1 flex justify-end items-center">
            {customItems}
          </div>
        ) : null}
      </div>
      <div className="flex-1">{children}</div>
    </div>
  );
}

export default NavTabs;
