import { useEffect, useState } from 'react';

interface TablePaginatorProps {
  page?: number;
  totalItems: number;
  itemsPerPage: number;
  onChange: (pageNum: number, startIndex: number, endIndex: number) => void;
}

function TablePaginator({
  page,
  totalItems,
  itemsPerPage,
  onChange,
}: TablePaginatorProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    if (page) {
      setCurrentPage(page);
    }
  }, [page]);

  // HANDLE PAGE FORWARD
  const onPageForward = () => {
    setCurrentPage((curr) => {
      const nextPage = curr + 1;

      // inclusive
      const startIndex = Math.min((currentPage - 1) * itemsPerPage, totalItems);
      // exclusive
      const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
      const newPageNum = Math.min(nextPage, maxPages);

      onChange(newPageNum, startIndex, endIndex);

      return Math.min(nextPage, maxPages);
    });
  };

  // HANDLE PAGE BACK
  const onPageBack = () => {
    setCurrentPage((curr) => {
      const prevPage = curr - 1;

      // inclusive
      const startIndex = Math.min((currentPage - 1) * itemsPerPage, totalItems);
      // exclusive
      const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

      const newPageNum = Math.max(prevPage, 0);
      onChange(newPageNum, startIndex, endIndex);

      return Math.max(prevPage, 0);
    });
  };

  // JSX
  return (
    <div className="flex flex-col lg:flex-row justify-end items-center px-5 py-2">
      <span className="text-xs text-gray700 font-normal mr-3 mb-2 lg:mb-0">
        Showing
        <span className="text-secondarygray font-bold tabular-nums">
          {' '}
          {Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)}
        </span>{' '}
        to
        <span className="text-secondarygray font-bold tabular-nums">
          {' '}
          {Math.min(currentPage * itemsPerPage, totalItems)}
        </span>{' '}
        of
        <span className="text-secondarygray font-bold tabular-nums">
          {' '}
          {totalItems}
        </span>{' '}
        Entries
      </span>
      <div className="flex items-center relative">
        <button
          onClick={onPageBack}
          disabled={currentPage === 1}
          className="text-sm text-light bg-primarygray px-3 py-[6px] rounded-l-lg disabled:cursor-not-allowed"
        >
          PREV
        </button>
        <div
          style={{ width: '1px', backgroundColor: 'white', margin: '0.1px' }}
        ></div>
        <button
          onClick={onPageForward}
          disabled={currentPage === maxPages}
          className="text-sm text-light bg-primarygray px-3 py-[6px] rounded-r-lg disabled:cursor-not-allowed"
        >
          NEXT
        </button>
      </div>
    </div>
  );
}

export default TablePaginator;
