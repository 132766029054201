import { useQuery } from 'react-query';
import { useIntentContext } from '../../hooks';

// API REQUESTS
import { fetchConsumerIntRCData } from '../../api';

// COMPONENTS
import OutletSpinner from '../OutletSpinner';
import RetailerOverview from './RC_comp/RetailerOverview';
import RetailerDeepDive from './RC_comp/RetailerDeepDive';

// CONSUMER INTENT RETAILER COMPARISON TAB
function RetailerComparison() {
  const { selectedProducts, selectedVerticals, dateRange } = useIntentContext();

  const { data: consumerIntRCData, isLoading } = useQuery(
    ['consumerIntRCData', dateRange, selectedVerticals, selectedProducts],
    () =>
      fetchConsumerIntRCData(dateRange, selectedVerticals, selectedProducts),
    {
      enabled: !!dateRange?.startDate && !!dateRange?.endDate,
    }
  );

  return isLoading ? (
    <OutletSpinner />
  ) : consumerIntRCData ? (
    <>
      <RetailerOverview
        data={consumerIntRCData.retailer_bar_chart}
        timeseries_chart_data={consumerIntRCData.timeseries_chart}
        mindset_bar_chart_data={consumerIntRCData.mindset_bar_chart}
      />
      <RetailerDeepDive
        retailer_deep_dive_timeseries_chart_data={
          consumerIntRCData.retailer_deep_dive_timeseries_chart
        }
        retailer_deep_dive_bar_chart_data={
          consumerIntRCData.retailer_deep_dive_bar_chart
        }
      />
    </>
  ) : null;
}

export default RetailerComparison;
