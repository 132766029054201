import React, { FC, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartOptions, ChartData } from 'chart.js';
import 'chart.js/auto';
import CustomLegend2 from './CustomLegend2';
import { BrandData } from './CustomLegend2';

interface MetricData {
  rank: number;
  coverage: number;
  search_terms_count: number;
}

interface AdTypeData {
  [date: string]: MetricData;
}

interface CoverageCompData {
  [ad_type: string]: AdTypeData;
}

interface DeviceData {
  [brand: string]: CoverageCompData;
}

interface OverviewProps {
  data: {
    desktop: { coverageComp: DeviceData };
    mobile: { coverageComp: DeviceData };
  };
  device: string;
  activeTab: string;
  selectedFilters: string[];
  competitorsToBeIncluded: string[];
}

const OverviewLineChart: FC<OverviewProps> = ({
  data,
  device,
  activeTab,
  selectedFilters,
  competitorsToBeIncluded,
}) => {
  const [activeTabCleaned, setActiveTabCleaned] = useState('coverage');
  const [isLegendExpanded, setIsLegendExpanded] = useState(false);

  useEffect(() => {
    if (activeTab === 'Rank') {
      setActiveTabCleaned('rank');
    } else if (activeTab === '# Search Terms') {
      setActiveTabCleaned('search_terms_count');
    } else {
      setActiveTabCleaned('coverage');
    }
  }, [activeTab]);

  const normalizedDevice = device === 'mobile' ? 'mobile' : 'desktop';

  // get filtered data based on the selected device
  const getFilteredDataByDevice = (deviceData: DeviceData, brand: string): CoverageCompData => {
    const filteredData: CoverageCompData = {};
    selectedFilters.forEach((filter) => {
      if (deviceData[brand] && deviceData[brand][filter]) {
        filteredData[filter] = deviceData[brand][filter];
      }
    });
    return filteredData;
  };

  const [visibility, setVisibility] = useState<Record<string, boolean>>({});
  const [chartParameters, setChartParameters] = useState<ChartData<'line'>>({
    labels: [],
    datasets: [],
  });
  const [legendData, setLegendData] = useState<BrandData[]>([]);

  useEffect(() => {
    const updatedVisibility: Record<string, boolean> = {};
    
    const deviceData: DeviceData =
      normalizedDevice === 'desktop' ? data.desktop.coverageComp : data.mobile.coverageComp;

    Object.keys(deviceData).forEach((brand) => {
      updatedVisibility[brand] = true;
    });
    setVisibility((prevState) => {
      return { ...prevState, ...updatedVisibility };
    });
  }, [data, normalizedDevice]);

  const colors: string[] = [
    'rgba(34, 34, 34, 0.80)',
    'rgba(89, 89, 89, 0.8)',
    'rgba(144, 144, 144, 0.8)',
    'rgba(50, 215, 111, 0.8)',
    'rgba(3, 197, 255, 0.8)',
    'rgba(84, 79, 197, 0.8)',
    'rgba(254, 106, 53, 0.8)',
    'rgba(107, 139, 188, 0.8)',
    'rgba(213, 104, 251, 0.8)',
    'rgba(47, 254, 202, 0.8)',
  ];

  useEffect(() => {
    const deviceData: DeviceData =
      normalizedDevice === 'desktop' ? data.desktop.coverageComp : data.mobile.coverageComp;

    const allDates = new Set<string>();
    Object.keys(deviceData).forEach((brand) => {
      Object.keys(deviceData[brand]).forEach((adType) => {
        Object.keys(deviceData[brand][adType]).forEach((date) => {
          allDates.add(date);
        });
      });
    });

    const sortedDates = Array.from(allDates).sort();

    const brandsData = Object.keys(deviceData).map((brand, index) => {
      const filteredData = getFilteredDataByDevice(deviceData, brand);

      // Combine data for all ad types for this brand, filling missing dates with zero values
      const combinedData: Record<string, MetricData> = {};

      sortedDates.forEach((date) => {
        combinedData[date] = {
          rank: 0,
          coverage: 0,
          search_terms_count: 0,
        };
        Object.keys(filteredData).forEach((adType) => {
          if (filteredData[adType][date]) {
            combinedData[date].rank += filteredData[adType][date].rank || 0;
            combinedData[date].coverage += filteredData[adType][date].coverage || 0;
            combinedData[date].search_terms_count += filteredData[adType][date].search_terms_count || 0;
          }
        });
      });

      return {
        label: brand,
        color: colors[index % colors.length],
        data: sortedDates.map((date) => combinedData[date][activeTabCleaned as keyof MetricData]),
      };
    });
    setLegendData(brandsData);

    const datasets = brandsData.map((brand) => ({
      label: brand.label,
      data: brand.data,
      borderColor: brand.color,
      borderWidth: 1.5,
      fill: false,
      pointBackgroundColor: brand.color,
      pointBorderColor: brand.color,
      pointRadius: 1.5,
      tension: 0.4,
      hidden: !visibility[brand.label.replace(' ', '')],
    }));

    setChartParameters({ labels: sortedDates, datasets });
  }, [data, normalizedDevice, visibility, activeTabCleaned, selectedFilters]);

  const handleLegendClick = (label: string) => {
    setVisibility((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Dates',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 45,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text:
            activeTab === 'Coverage'
              ? 'Coverage (%)'
              : activeTab === 'Rank'
              ? 'Rank'
              : '# Search Terms',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          stepSize: activeTab === 'Coverage' ? 25 : 1,
          callback: function (value) {
            return activeTab === 'Coverage' ? value + '%' : value;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const visibleBrands = isLegendExpanded ? legendData : legendData.slice(0, 8);

  return (
    <div className="">
      <div className="flex">
        <div className="flex relative px-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[calc(92%-200px)] md:w-[65%] w-[60%] h-[295px]">
          <Line data={chartParameters} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <CustomLegend2
            brandsData={visibleBrands}
            onLegendClick={handleLegendClick}
            visibility={visibility}
          />
          <button
            onClick={() => setIsLegendExpanded(!isLegendExpanded)}
            className="mt-2 bg-light hover:underline"
          >
            {legendData.length > 8
              ? isLegendExpanded
                ? 'Show Less'
                : 'Show More'
              : ''}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OverviewLineChart;
