import React, { useState } from 'react';
import Glossary from './Resources_comp/Glossary';
import FAQ from './Resources_comp/FAQ';



const ResourcesPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('Glossary');

  const renderContent = () => {
    return activeTab === 'Glossary' ? <Glossary /> : <FAQ />;
  };

  const renderMenu = () => (
    <div className="sticky top-[94px] z-10 flex bg-body border-b border-gray300 whitespace-nowrap">
      <div
        className={`text-primarygray font-medium cursor-pointer py-4 px-8 ${activeTab === 'Glossary' ? 'border-b-2 border-primarygray' : ''}`}
        onClick={() => {
          setActiveTab('Glossary');
        }}
      >
        Glossary
      </div>
      <div
        className={`text-primarygray font-medium cursor-pointer py-4 px-8 ${activeTab === 'FAQ' ? 'border-b-2 border-primarygray' : ''}`}
        onClick={() => {
          setActiveTab('FAQ');
        }}
      >
       FAQ
      </div>
    </div>
  );

  return (
    <div className="flex flex-col">
      {renderMenu()}
      <div className="flex-1">
        {renderContent()}
      </div>
    </div>
  );
};

export default ResourcesPage;
