import React, { FC, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import 'chart.js/auto';
import CustomLegend from '../CustomLegend';
import Dropdown from '../../Dropdown';

interface BrandFocusDoughnutChartProps {
  data: {
    [type: string]: {
      [platform: string]: {
        [site: string]: number;
      }
    };
  };
  percData: {
    [brand: string]: 'N/A' | {
      [device: string]: 'N/A' | {
        [ad_type: string]: number | 'N/A';
      }
    };
  };
  selectedFilter: string;
  arrowSrc: (isPositive: boolean) => string;
  changeColor: (isPositive: boolean) => string;
  clientDomain: string;
}

const BrandFocusDoughnutChart: FC<BrandFocusDoughnutChartProps> = ({ data, percData, selectedFilter, arrowSrc, changeColor, clientDomain }) => {
  const defaultStructure = {labels: ['Coverage'], datasets: [{data: [0, 0], backgroundColor: ['#222222cc', '#e0e0e0'], hoverBackgroundColor: ['#222222cc', '#e0e0e0'], borderWidth: 0,},],}
  const [desktopData, setDesktopData] = useState(defaultStructure)
  const [mobileData, setMobileData] = useState(defaultStructure)

  const desktopKeys = Object.keys(data?.[selectedFilter]?.desktop || {});
  const mobileKeys = Object.keys(data?.[selectedFilter]?.mobile || {});
  const combinedSet = new Set<string>();
  
  desktopKeys.forEach(key => combinedSet.add(key));
  mobileKeys.forEach(key => combinedSet.add(key));
  
  const competitorOptions: string[] = Array.from(combinedSet);

  const [selectedCompetitor, setSelectedCompetitor] = useState<string>(clientDomain ? clientDomain : competitorOptions[0]);

  const getFilteredData = (device: 'desktop' | 'mobile') => {
      const total = parseFloat((data?.[selectedFilter]?.[device]?.[selectedCompetitor] || 0).toFixed(2))
      const remaining = 100 - total;
      return {
      labels: ['Coverage'],
      datasets: [
        {
          data: [total, remaining],
          backgroundColor: ['#222222cc', '#e0e0e0'],
          hoverBackgroundColor: ['#222222cc', '#e0e0e0'],
          borderWidth: 0,
        },
      ],
    };
  };

  useEffect(() => {
    setDesktopData(getFilteredData('desktop'));
    setMobileData(getFilteredData('mobile'));
  }, [data, selectedFilter, selectedCompetitor]);

  useEffect(() => {
    setSelectedCompetitor(clientDomain ? clientDomain : competitorOptions[0]);
  }, [data]);

  const isPositive = (value: number | 'N/A'): boolean => {
    return value !== 'N/A' && value >= 0;
  };
  
  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    cutout: '80%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed !== null) {
              label += context.parsed.toFixed(2) + '%';
            }
            return label;
          }
        }
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  const getCorrespondingPercentage = (device: 'desktop' | 'mobile') => {
    const competitorData = percData?.[selectedCompetitor];
  
    if (competitorData === 'N/A') {
      return 0;
    }
  
    const deviceData = competitorData?.[device];
  
    if (deviceData === 'N/A' || deviceData?.[selectedFilter] === 'N/A') {
      return 0;
    } else {
      return deviceData?.[selectedFilter] ?? 0;
    }
  };  

  return (
    <div className="mt-6">
      <div>
        <h2 className="text-primarygray font-bold mb-2 pl-4 pt-2">Average Coverage
        <div className="relative ml-1 mb-2 group inline-block">
              <img src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`} alt="tooltip" className="tooltip-image" />
              <span className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                <p className='px-4 py-2'>Percent of time a brand appeared on a search term/ number of times<br></br>
                the keyword was queried during a selected time period
                </p>
                <img src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`} alt="arrow" className="absolute left-1/2 transform -translate-x-1/2 -top-[6px] rotate-180" />
              </span>
            </div>
        </h2>
      </div>
      <div className="flex relative">
        <div className="flex flex-col items-center pl-2 py-6 2xl:w-[1280px] xl:h-[300px] md:w-[876px] h-[700px] w-[370px]">
          <div className="flex flex-col xl:flex-row items-start gap-8 xl:gap-52">
            <div className="flex flex-col items-start">
              <h3 className="text-bold mb-2">Desktop</h3>
              <div className="relative w-[315px] h-[280px]">
                <Doughnut data={desktopData} options={options} />
                <div className="absolute inset-0 flex flex-col items-center justify-center text-center mr-12">
                  <div className="flex items-center">
                    <img src={arrowSrc(isPositive(getCorrespondingPercentage("desktop")))} alt="arrow" className="h-4 w-4 mx-auto" />
                    <span className={`text-sm ${changeColor(isPositive(getCorrespondingPercentage("desktop")))}`}>
                      {getCorrespondingPercentage("desktop")}%
                    </span>
                  </div>
                  <span className="text-3xl font-bold">{desktopData.datasets[0].data[0]}%</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start">
              <h3 className="text-bold mb-2">Mobile</h3>
              <div className="relative w-[315px] h-[280px]">
                <Doughnut data={mobileData} options={options} />
                <div className="absolute inset-0 flex flex-col items-center justify-center text-center mr-12">
                  <div className="flex items-center">
                  <div className="relative ml-1 mb-2 group inline-block">
                    <img src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`} alt="tooltip" className="tooltip-image" />
                    <span className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
                      <p className='px-4 py-2'>Comparison of timeframe and the<br></br>
                      chosen comparison timeframe
                      </p>
                      <img src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`} alt="arrow" className="absolute left-1/2 transform -translate-x-1/2 -top-[6px] rotate-180" />
                    </span>
                  </div>
                    <img src={arrowSrc(isPositive(getCorrespondingPercentage("mobile")))} alt="arrow" className="h-4 w-4 mx-auto" />
                    <span className={`text-sm ${changeColor(isPositive(getCorrespondingPercentage("mobile")))}`}>
                      {getCorrespondingPercentage("mobile")}%
                    </span>
                  </div>
                  <span className="text-3xl font-bold">{mobileData.datasets[0].data[0]}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-none text-xs flex flex-col items-start px-2 py-6">
          <CustomLegend legendSet="set5" />
          <Dropdown title={`${selectedCompetitor}`} options={competitorOptions} onOptionClick={setSelectedCompetitor} dropdownLength='max-h-44'/>
        </div>
      </div>
    </div>
  );
};

export default BrandFocusDoughnutChart;
