import React from 'react';

interface LegendItem {
  label: string;
  icon: string;
}

interface LegendsData {
  [key: string]: LegendItem[];
}

const legendsData: LegendsData = {
  set1: [
    { label: 'On', icon: `${process.env.PUBLIC_URL}/static/img/organic.svg` },
    { label: 'Off', icon: `${process.env.PUBLIC_URL}/static/img/paid.svg` },
  ],
  set2: [
    { label: 'Total', icon: `${process.env.PUBLIC_URL}/static/img/total_ao.svg` },
    { label: 'Organic', icon: `${process.env.PUBLIC_URL}/static/img/total_green_ao.svg` },
    { label: 'Paid', icon: `${process.env.PUBLIC_URL}/static/img/paid_blue_ao.svg` },
  ],
  set3: [
    { label: 'Cost', icon: `${process.env.PUBLIC_URL}/static/img/total_green_ao.svg` },
    { label: 'Expected Cost', icon: `${process.env.PUBLIC_URL}/static/img/organic_ao.svg` },
  ]
};

interface CustomLegendProps {
  legendSet: keyof LegendsData;
  onLegendClick: (label: string) => void;
  visibility: Record<string, boolean>;
}

const CustomLegend: React.FC<CustomLegendProps> = ({ legendSet, onLegendClick, visibility }) => {
  const legends = legendsData[legendSet];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '10px' }}>
      {legends.map((legend, index) => (
        <div
          key={index}
          style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', cursor: 'pointer', opacity: visibility[legend.label] ? 1 : 0.5 }}
          onClick={() => onLegendClick(legend.label)}
        >
          <img
            src={legend.icon}
            alt={legend.label}
            style={{ width: '16px', height: '16px', marginRight: '10px' }}
          />
          <span>{legend.label}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomLegend;
