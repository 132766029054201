import 'chart.js/auto';

// COMPONENTS
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';

interface ChangeLogBProps {
  data: {
    on: number;
    off: number;
  };
}

// AUTO OPTIMIZE CHANGE LOG "B" CHART
function ChangeLogB({ data }: ChangeLogBProps) {
  const impressionShown = data.on;
  const impressionNotShown = data.off;

  const revenuePerBrandTypeData = {
    labels: ['Negative Keyword Removed', 'Negative Keyword Applied'],
    datasets: [
      {
        label: 'Impressions',
        data: [impressionShown, impressionNotShown],
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderWidth: 1,
        borderRadius: 8,
        barThickness: 144,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Total Count',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          stepSize: 50,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  // JSX
  return (
    <div className="bg-light rounded-2xl shadow-sm">
      <div className="flex">
        <div className="flex relative px-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[calc(92%-200px)] md:w-[65%] w-[60%] h-[295px]">
          <Bar data={revenuePerBrandTypeData} options={options} />
        </div>
      </div>
    </div>
  );
}

export default ChangeLogB;
