import { useQuery } from 'react-query';
import { useIntentContext } from '../../hooks';

// API REQUESTS
import { fetchConsumerIntData } from '../../api';

// COMPONENTS
import OutletSpinner from '../OutletSpinner';
import SearchTerms from './OVComponents/SearchTerms';
import MindsetBreakdown from './OVComponents/MindsetBreakdown';

function Overview() {
  const { selectedProducts, selectedVerticals, dateRange } = useIntentContext();

  const { data: consumerIntData, isLoading } = useQuery({
    queryKey: [
      'consumerIntData',
      dateRange?.startDate,
      dateRange?.endDate,
      selectedVerticals,
      selectedProducts,
    ],
    queryFn: () =>
      fetchConsumerIntData(dateRange, selectedVerticals, selectedProducts),
    enabled: !!dateRange?.startDate && !!dateRange?.endDate,
  });

  // JSX
  return isLoading ? (
    <OutletSpinner />
  ) : consumerIntData ? (
    <>
      <SearchTerms
        data={consumerIntData.demand_chart}
        uniqueQueryCount={consumerIntData.unique_query_count_scorecard}
        yoyGrowthDemand={consumerIntData.yoy_growth_demand_scorecard}
        grandTotalDemand={consumerIntData.grand_total_demand}
      />

      <MindsetBreakdown data={consumerIntData.mindset_chart} />
    </>
  ) : null;
}

export default Overview;
