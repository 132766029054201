import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import LoadingSpinner from '../LoadingSpinner';
import UsersManagement from './UsersManagement';

interface User {
  id: number;
  is_superuser: boolean;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  user_type: string;
  agencies: string;
  clients: string;
  markets: string;
}

interface LineOfBusiness {
  id: number;
  name: string;
}

interface Client {
  id: number;
  name: string;
  lines_of_business: LineOfBusiness[];
}

interface Agency {
  id: number;
  name: string;
  clients: Client[];
}

interface Market {
  id: number;
  name: string;
}

const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
};

const fetchArchivedUsers = async (): Promise<User[]> => {
  const response = await fetch('/users/');
  if (!response.ok) {
    throw new Error('Failed to fetch archived users');
  }
 
  const users: (Omit<User, 'agencies' | 'clients'> & { agencies: Agency[], clients: Client[], markets : Market[]})[] = await response.json();

  return users
  .filter(user => !user.is_active) // Filter only inactive users
  .map((user) => {
    const agencies = user.agencies.map((agency) => agency.name).join(', ');
    const clients = user.clients.map((client) => client.name).join(', ');
    const markets = user.markets.map((market) => market.name).join(', ');

    return {
      ...user,
      user_type: user.is_superuser // Map the user_type based on is_superuser and is_staff
        ? 'Admin'
        : 'Standard',
      agencies: agencies,
      clients: clients,
      markets: markets,
    };
});
};

const updateUser = async (user: User): Promise<User> => {
    const response = await fetch(`/update-user/${user.id}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  
    return response.json();
  };
  

const ArchiveUsers: React.FC = () => {
    const queryClient = useQueryClient();
  const { data, isLoading, isError, refetch } = useQuery<User[]>('archivedUsers', fetchArchivedUsers);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<{ key: keyof User, direction: 'asc' | 'desc' } | null>(null);
  const [selectedNavigation, setSelectedNavigation] = useState<'Active' | 'Archive'>('Archive');
  const [error, setError] = useState<string | null>(null);

  const updateUserMutation = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleNavChange = (navigation: 'Active' | 'Archive') => {
    setSelectedNavigation(navigation);
  };

  const handleSort = (key: keyof User) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (updateUserMutation.isSuccess ) {
      refetch();
    }
  }, [updateUserMutation.isSuccess, refetch]);

  const handleEnableUser = async (user: User) => {
    const confirmed = window.confirm(`Are you sure you want to enable the user?`);
  
    if (!confirmed) {
      return;
    }
  
    try {
      await updateUserMutation.mutateAsync({
        ...user,
        is_active: true,
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
    }
  };

  const sortUsers = (users: User[]) => {
    if (!sortConfig) return users;
    return [...users].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const filteredUsers = data?.filter(
    (user: User) =>
      user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (user.first_name && user.first_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.last_name && user.last_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.agencies && user.agencies.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.clients && user.clients.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.markets && user.markets.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const sortedUsers = sortUsers(filteredUsers || []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <div>Failed to fetch archived users</div>;
  }

  const tableHeaders = [
    { label: 'User', key: 'first_name', sortable: true },
    { label: 'Username', key: 'username', sortable: true },
    { label: 'Agency', key: 'agencies', sortable: true },
    { label: 'Client', key: 'clients', sortable: true },
    { label: 'Market', key: 'markets', sortable: true },
    { label: 'Created On', key: 'date_joined', sortable: true },
    { label: 'User Type', key: 'user_type', sortable: true },
  ];

  return (
    <div>
      {selectedNavigation === 'Archive' ? (
        <div className="bg-light my-8 rounded-2xl shadow-sm">
          <div className="flex flex-col lg:flex-row justify-end px-4 pt-2 border-b border-success pb-2">
            <div className="flex flex-col lg:flex-row items-center w-full lg:w-auto">
              <div className="relative text-thirdgray text-sm font-bold border border-gray300 rounded-full w-full lg:w-[401px] mb-4 lg:mb-0">
                <input
                  type="search"
                  name="search"
                  placeholder="Search"
                  className="h-10 text-sm pl-10 pr-4 w-full rounded-full"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/Trailing_icon.svg`}
                  alt="search_ico"
                  className="absolute left-3 top-3 h-4 w-4 fill-current"
                />
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
                alt="dots"
                className="h-6 w-6 lg:ml-4"
              />
            </div>
          </div>

          {/* Navigation Buttons */}
          <div className="flex bg-fourthgray rounded-lg w-48 h-10 mt-6 ml-4">
            {['Active', 'Archive'].map((navigation) => (
              <button
                key={navigation}
                className={`flex-1 rounded-md text-sm ${
                  selectedNavigation === navigation ? 'bg-light text-primarygray m-1' : 'text-gray400'
                }`}
                onClick={() => handleNavChange(navigation as 'Active' | 'Archive')}
              >
                {navigation}
              </button>
            ))}
          </div>

          {error && (
                  <div className="text-error text-sm mt-2 ml-4">
                    {error}
                  </div>
          )}

          <div className="overflow-auto py-6 px-4">
            <table className="min-w-full bg-light table-auto">
              <thead className="text-primarygray text-xs font-normal">
                <tr>
                  {tableHeaders.map(header => (
                    <th key={header.label} className="py-2 px-4 text-left">
                      <div className="flex items-center">
                        {header.label.toUpperCase()}
                        {header.sortable && (
                          <img
                            src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`}
                            alt="sort-icon"
                            className="ml-2 cursor-pointer"
                            onClick={() => handleSort(header.key as keyof User)}
                          />
                        )}
                      </div>
                    </th>
                  ))}
                  <th className="py-2 px-2 text-right">ACTIONS</th>
                </tr>
              </thead>
              <tbody className="text-primarygray text-xs">
                {sortedUsers.map((user, index) => (
                  <tr key={user.id} className={index % 2 === 0 ? 'bg-gray50' : 'bg-gray100'}>
                    <td className="py-2 px-4">
                      {user.first_name || user.last_name
                        ? `${user.first_name} ${user.last_name}`
                        : 'N/A'}
                    </td>
                    <td className="py-2 px-4">
                      <a href={`mailto:${user.username}`} className="font-medium underline">
                        {user.username || 'N/A'}
                      </a>
                    </td>
                    <td className="py-2 px-4">
                      {truncateText(user.agencies, 30)}
                    </td>
                    <td className="py-2 px-4">
                      {truncateText(user.clients, 30)}
                    </td>
                    <td className="py-2 px-4">
                      {truncateText(user.markets, 30)}
                    </td>
                    <td className="py-2 px-4 text-thirdgray">
                      {user.date_joined}
                    </td>
                    <td className="py-2 px-4">
                      <div
                        className={`rounded-md px-4 py-1 inline-block ${
                          user.user_type === 'Admin'
                            ? 'bg-blue100 text-blue800'
                            : user.user_type === 'Standard'
                              ? 'bg-yellow100 text-yellow800'
                            : user.user_type === "Regional Admin"
                              ? 'bg-orange-100 text-orange-800'
                              : 'bg-disgreen100 text-disgreen800'
                          }`}
                      >
                        {user.user_type}
                      </div>
                    </td>
                    <td className="py-2 px-2 flex justify-end">
                      <button onClick={() => handleEnableUser(user)}>
                        <img
                          src={`${process.env.PUBLIC_URL}/static/img/archive.svg`}
                          alt="archive"
                          className="h-4 w-4"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <UsersManagement />
      )}
    </div>
  );
};

export default ArchiveUsers;