import React from 'react';

interface LegendItem {
  label: string;
  color: string;
  icon: React.ReactNode;
}

interface CustomLegendProps {
  legends: LegendItem[];
}

const CustomLegend: React.FC<CustomLegendProps> = ({ legends }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '10px' }}>
      {legends.map((legend, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {legend.icon}
          </div>
          <span style={{ marginLeft: '10px' }}>{legend.label}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomLegend;
