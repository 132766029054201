import React, { useState, useRef, useEffect } from 'react';
import { Bubble } from 'react-chartjs-2';
import CustomLegend2 from './CustomLegend2';
import { downloadImage, downloadXLS } from 'utils/download'
import {
  Chart as ChartJS,
  BubbleController,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  Chart,
  Element,
} from 'chart.js';

// Register the required components with Chart.js
ChartJS.register(BubbleController, LinearScale, PointElement, Tooltip, Legend);

interface BubbleData {
  x: number;
  y: number;
  r: number;
}

// Custom plugin to display rank inside the bubble
const customPlugin = {
  id: 'rankInsideBubble',
  afterDraw: (chart: Chart<'bubble'>) => {
    // const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (dataset.hidden) return; // Skip if dataset is hidden
      dataset.data.forEach((value, index) => {
        const data = value as BubbleData;
        const meta = chart.getDatasetMeta(datasetIndex);
        const point = meta.data[index] as Element;
        const { x, y } = point.getProps(['x', 'y'], true);

        // ctx.fillStyle = '#222';
        // ctx.textAlign = 'center';
        // ctx.textBaseline = 'middle';
        // ctx.font = '12px DM Sans';
        // ctx.fillText((data.r / 2).toFixed(2).toString(), x, y);
      });
    });
  },
};

interface BrandPresenceData {
  data: {
    [domain: string]: {
      rank: number;
      coverage: number;
      creative_count: number;
    };
  };
}

interface BrandData {
  label: string;
  color: string;
}

// TOOLTIP
const COVERAGE_TOOLTIP =
  'Percent of time a brand appeared on a search term/number of times the keyword was queried during a selected time period';

const BrandPresenceChart: React.FC<BrandPresenceData> = ({ data }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const colors: string[] = [
    'rgba(34, 34, 34, 0.80)',
    'rgba(89, 89, 89, 0.8)',
    'rgba(144, 144, 144, 0.8)',
    'rgba(50, 215, 111, 0.8)',
    'rgba(3, 197, 255, 0.8)',
    'rgba(84, 79, 197, 0.8)',
    'rgba(254, 106, 53, 0.8)',
    'rgba(107, 139, 188, 0.8)',
    'rgba(213, 104, 251, 0.8)',
    'rgba(47, 254, 202, 0.8)',
  ];

  const [visibility, setVisibility] = useState<Record<string, boolean>>(() => {
    const brands = Object.keys(data);
    const top10Brands = brands.slice(0, 10);
    return brands.reduce((acc, brand) => {
      acc[brand] =
        top10Brands.includes(brand) && brand.toLowerCase() !== 'uncategorized';
      return acc;
    }, {} as Record<string, boolean>);
  });

  const [isLegendExpanded, setIsLegendExpanded] = useState(false);

  const [bubbleChartData, setBubbleChartData] = useState<ChartData<'bubble'>>({
    datasets: [],
    labels: [],
  });
  const [legendData, setLegendData] = useState<BrandData[]>([]);

  const handleLegendClick = (label: string) => {
    setVisibility((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  useEffect(() => {
    const brandsData = Object.keys(data).map((brand, index) => ({
      label: brand,
      color: colors[index % colors.length],
    }));

    const bubbleDatasets = brandsData.map((brand) => ({
      label: brand.label,
      data: [
        {
          x: data[brand.label].coverage,
          y: data[brand.label].rank,
          r: data[brand.label].creative_count * 2,
        },
      ],
      backgroundColor: brand.color,
      hidden: !visibility[brand.label],
    }));

    const bubbleChartDataCleaned: ChartData<'bubble'> = {
      datasets: bubbleDatasets,
    };

    setBubbleChartData(bubbleChartDataCleaned);
    setLegendData(brandsData);
  }, [data, visibility]);

  useEffect(() => {
    const updatedVisibility: Record<string, boolean> = {};
    Object.keys(data).forEach(brand => {
      updatedVisibility[brand] = true;
    })
    setVisibility(prevState => {return { ...prevState, ...updatedVisibility };})
  }, [data]);

  const options: ChartOptions<'bubble'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const data = context.raw as BubbleData;
            return `${context.dataset.label}: Rank: ${(data.y).toFixed(2)}, Coverage: ${(data.x).toFixed(2)}%, Creative count: ${(data.r / 2).toFixed(2)}`;
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          // text: 'Coverage (%)',
          // color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        min: 0,
        max: 100,
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          callback: function (value) {
            return value + '%';
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Rank',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        min: 0,
        reverse: false,
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const visibleBrands = isLegendExpanded ? legendData : legendData.slice(0, 8);

  const downloadChartImage = () => {
    downloadImage('BrandPresence');
  };

  const downloadChartData = () => {
    const headers = ['Brand', 'Coverage (%)', 'Rank', 'Creative Count'];

    const brands = Object.keys(data);
    const labels = brands;
    const columns = [
      brands.map(brand => data[brand].coverage),
      brands.map(brand => data[brand].rank),
      brands.map(brand => data[brand].creative_count),
    ];

    downloadXLS('BrandPresence', headers, labels, columns);
  };

  return (
    <div id="BrandPresence" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold pl-4 pt-2">
            Brand Presence
          </h2>
          <h3 className="text-gray800 text-sm font-bold mb-2 pl-4">
            Your brand position compared to your chosen competitors
          </h3>
        </div>
        <div className="relative mr-4" ref={dropdownRef} id='downloadDropdownMenu-BrandPresence'>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className="absolute right-0 mt-2 w-48 bg-light text-sm text-gray700 rounded-md z-50"
              style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <ul>
                {/* <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={closeDropdown}
                >
                  View data table
                </li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartData();
                    closeDropdown();
                  }}
                >
                  Download XLS
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="relative flex">
        <div className="absolute top-1/3 translate-x-1 translate-y-2 ml-1 -mt-1 z-50">
          <div className="relative ml-1 mb-2 group inline-block">
            <img
              src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
              alt="tooltip"
              className="tooltip-image"
            />
            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
              <p className="px-4 py-2">
                Average of all positions for a given advertiser/total number
                <br></br>
                of positions possible (or results displayed) when seen
              </p>
              <img
                src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                alt="arrow"
                className="absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]"
              />
            </span>
          </div>
        </div>
        <div className="flex relative px-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[calc(92%-200px)] md:w-[65%] w-[60%] h-[295px]">
          <Bubble
            data={bubbleChartData}
            options={options}
            plugins={[customPlugin]}
          />
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 ml-1 -mt-1">
            <div className="relative ml-1 mb-2 group flex gap-2 text-sm">
              <span className="font-bold text-[#4D4D4D]">Coverage (%)</span>
              <img
                src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
                alt="tooltip"
                className="tooltip-image"
              />
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                <p className="px-4 py-2 w-80 whitespace-normal">
                  {COVERAGE_TOOLTIP}
                </p>
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                  alt="arrow"
                  className="absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <CustomLegend2
            brandsData={visibleBrands}
            onLegendClick={handleLegendClick}
            visibility={visibility}
          />
          <button
            onClick={() => setIsLegendExpanded(!isLegendExpanded)}
            className="mt-2 bg-light hover:underline"
          >
            {visibleBrands.length > 8 ? (
              isLegendExpanded ? 'Show Less' : 'Show More'
            ) : (
              ''
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrandPresenceChart;
