interface TooltipProps {
  text?: string | string[];
  position?: 'above' | 'below';
}

function Tooltip({ text, position = 'below' }: TooltipProps) {
  return (
    <div className="relative ml-1 group inline-block">
      <img
        src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
        alt="tooltip"
        className="tooltip-image"
      />
      <span
        className={`absolute ${
          position === 'above' ? 'bottom-full mb-2' : 'top-full mt-2'
        } left-1/2 transform -translate-x-1/2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex flex-col items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 px-4 py-2`}
      >
        {Array.isArray(text)
          ? text.map((line, index) => <div key={index}>{line}</div>)
          : text}
        <img
          src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
          alt="arrow"
          className={`absolute left-1/2 transform -translate-x-1/2 ${
            position === 'above' ? '-bottom-[6px]' : '-top-[6px] rotate-180'
          }`}
        />
      </span>
    </div>
  );
}

export default Tooltip;
