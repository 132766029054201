import { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// COMPONENTS
import LoadingSpinner from '../LoadingSpinner';

// TYPES
import { User } from '../../protectedRoute';

interface GeoTargeting {
  customer_id: string;
  geo_id: number;
  engine: string;
  device: string;
  country: string;
  country_code: string;
  language: string;
  language_code: string;
  state: string;
  city: string;
  postal_code: string;
  latitude: string;
  longitude: string;
  radius: string;
}

interface SearchGroup {
  customer_id: string;
  id: number;
  group_id: number;
  group_name: string;
  search_terms: string;
}

interface CompetitorGroup {
  customer_id: string;
  id: number;
  group_id: number;
  group_name: string;
  domains: string;
}

interface Configuration {
  study_id: number;
  study_name: string;
  frequency: number;
  provider: string;
  domain: string;
  geo_targeting: GeoTargeting[];
  search_groups: SearchGroup[];
  competitor_groups: CompetitorGroup[];
}

interface CustomerInfo {
  customer_id: string;
  agency: string;
  client: string;
  line_of_business: string;
  market: string;
  region: string;
  platform: string[];
}

interface CompetitiveIntelligenceData {
  customer_info: CustomerInfo;
  configurations: Configuration[];
}

interface TableProps<T> {
  data: T[];
  columns: (keyof T)[];
  onEdit?: (item: T) => void;
  onDelete?: (id: number) => void;
  onAdd?: () => void;
}

function formatHeader(header: string): string {
  let formatted = header.replace(/_/g, ' ');
  formatted = formatted.replace(/([A-Z])/g, ' $1');
  return formatted.toUpperCase();
}

function Table<T extends { geo_id?: number; group_id?: number }>({
  data,
  columns,
  onEdit,
  onDelete,
  onAdd,
}: TableProps<T>) {
  return (
    <div className="overflow-auto max-h-96">
      <table className="min-w-full bg-light table-auto">
        <thead className="text-primarygray text-xs font-normal">
          <tr>
            {columns.map((column, index) => (
              <th key={index} className="py-2 px-4 text-left">
                <div className="flex items-center">
                  {formatHeader(column as string)}
                  <img
                    src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`}
                    alt="sort-icon"
                    className="ml-2"
                  />
                </div>
              </th>
            ))}
            <th className="py-2 px-2 text-right">ACTIONS</th>
          </tr>
        </thead>
        <tbody className="text-primarygray text-xs font-normal rounded-md">
          {data.map((item, index) => (
            <tr
              key={item.geo_id || item.group_id}
              className={index % 2 === 0 ? 'bg-gray50' : 'bg-gray100'}
            >
              {columns.map((column) => (
                <td key={column as string} className="py-2 px-4">
                  {String(item[column])}
                </td>
              ))}
              <td className="py-2 px-2 flex justify-end">
                {onEdit && (
                  <button onClick={() => onEdit(item)}>
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/edit-03.svg`}
                      alt="edit"
                      className="h-4 w-4 mr-2"
                    />
                  </button>
                )}
                {onDelete && (
                  <button
                    onClick={() => onDelete(item.geo_id || item.group_id!)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/delete_option.svg`}
                      alt="delete"
                      className="h-4 w-4"
                    />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="border border-thirdgray rounded-[20px] px-1 mt-2 w-[68px]">
        <button
          className="text-sm text-thirdgray font-bold flex items-center"
          onClick={onAdd}
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/img/plus_gray.svg`}
            alt="plus_icon"
            className="w-[18px] h-[18px] mr-1"
          />
          ADD
        </button>
      </div>
    </div>
  );
}

interface CompetitiveIntProps {
  customerId: string;
  enabled: boolean;
  loggedInUser: User | null;
}

const fetchCompetitiveIntelligenceData = async (customerId: string) => {
  const response = await fetch('/competitive-intelligence-list/');
  const data: CompetitiveIntelligenceData[] = await response.json();
  const filteredData = data.find(
    (d) => d.customer_info.customer_id === customerId
  );
  return filteredData || null;
};

const updateConfiguration = async (config: Configuration) => {
  try {
    const response = await fetch(
      `edit-configuration-comp-int/${config.study_id}/`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(config),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      alert(`Update configuration failed: ${errorText}`);
      return null;
    }

    return response.json();
  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

const deleteGeoTargeting = async (geoId: number) => {
  const response = await fetch(`/delete-item/geo-targeting/${geoId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const deleteSearchGroup = async (groupId: number) => {
  const response = await fetch(`/delete-item/search-group/${groupId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const deleteCompetitorGroup = async (groupId: number) => {
  const response = await fetch(`/delete-item/competitor-group/${groupId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const uploadFile = async ({
  CompIntfileCFG,
  customerId,
  studyName,
  domain,
  frequency,
  provider,
}: {
  CompIntfileCFG: File;
  customerId: string;
  studyName: string;
  domain: string;
  frequency: number;
  provider: string;
}) => {
  const formData = new FormData();
  formData.append('file', CompIntfileCFG);
  formData.append('product', 'comp_int');

  if (customerId) {
    formData.append('customer_id', customerId);
  }
  formData.append('study_name', studyName);
  formData.append('domain', domain);
  formData.append('frequency', frequency.toString());
  formData.append('provider', provider);

  try {
    const response = await fetch('/upload/', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`File upload failed: ${errorText}`);
      return null;
    }

    return response.json();
  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

const createGeoTargeting = async (geoTargeting: GeoTargeting) => {
  try {
    const response = await fetch('/create-geo-targeting/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(geoTargeting),
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`Create Geo Targeting failed: ${errorText}`);
      return null;
    }

    return response.json();
  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

const createSearchGroup = async (searchGroup: SearchGroup) => {
  try {
    const response = await fetch('/create-search-group/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchGroup),
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`Create Search Group failed: ${errorText}`);
      return null;
    }

    return response.json();
  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

const createCompetitorGroup = async (competitorGroup: CompetitorGroup) => {
  try {
    const response = await fetch('/create-competitor-group/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(competitorGroup),
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`Create Competitor Group failed: ${errorText}`);
      return null;
    }

    return response.json();
  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

function CompetitiveInt({
  customerId,
  enabled,
  loggedInUser,
}: CompetitiveIntProps) {
  const queryClient = useQueryClient();
  const { data, isLoading, isError, refetch } = useQuery(
    ['competitiveData', customerId],
    () => fetchCompetitiveIntelligenceData(customerId),
    {}
  );

  const [editableItem, setEditableItem] = useState<
    GeoTargeting | SearchGroup | CompetitorGroup | null
  >(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editType, setEditType] = useState<
    'geoTargeting' | 'searchGroup' | 'competitorGroup' | null
  >(null);

  const [CompIntfileCFG, setCompIntFileCFG] = useState<File | null>(null);

  const [studyName, setStudyName] = useState<string>('');
  const [domain, setDomain] = useState<string>('');
  const [frequency, setFrequency] = useState<number>(3);
  const [provider, setProvider] = useState<string>('SerpAPI');

  const [initialStudyName, setInitialStudyName] = useState<string>('');
  const [initialDomain, setInitialDomain] = useState<string>('');
  const [initialFrequency, setInitialFrequency] = useState<number>(3);
  const [initialProvider, setInitialProvider] = useState<string>('SerpAPI');

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [newGeoTargeting, setNewGeoTargeting] = useState<GeoTargeting | null>(
    null
  );
  const [newSearchGroup, setNewSearchGroup] = useState<SearchGroup | null>(
    null
  );
  const [newCompetitorGroup, setNewCompetitorGroup] =
    useState<CompetitorGroup | null>(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [addType, setAddType] = useState<
    'geoTargeting' | 'searchGroup' | 'competitorGroup' | null
  >(null);

  useEffect(() => {
    if (data && data.configurations.length > 0) {
      const config = data.configurations[0];
      setStudyName(config.study_name);
      setDomain(config.domain);
      setFrequency(config.frequency);
      setProvider(config.provider);

      setInitialStudyName(config.study_name);
      setInitialDomain(config.domain);
      setInitialFrequency(config.frequency);
      setInitialProvider(config.provider);
    }
  }, [data]);

  const mutation = useMutation(updateConfiguration, {
    onSuccess: () => {
      queryClient.invalidateQueries(['competitiveData', customerId]);
      setIsEditing(false);
      setHasChanges(false);
    },
  });

  const deleteGeoMutation = useMutation(deleteGeoTargeting, {
    onSuccess: () => {
      queryClient.invalidateQueries(['competitiveData', customerId]);
    },
  });

  const deleteSearchMutation = useMutation(deleteSearchGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(['competitiveData', customerId]);
    },
  });

  const deleteCompetitorMutation = useMutation(deleteCompetitorGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(['competitiveData', customerId]);
    },
  });

  useEffect(() => {
    if (
      deleteGeoMutation.isSuccess ||
      deleteSearchMutation.isSuccess ||
      deleteCompetitorMutation.isSuccess
    ) {
      refetch();
    }
  }, [
    deleteGeoMutation.isSuccess,
    deleteSearchMutation.isSuccess,
    deleteCompetitorMutation.isSuccess,
    refetch,
  ]);

  const handleEditGeoTargeting = (item: GeoTargeting) => {
    setEditableItem(item);
    setEditType('geoTargeting');
    setIsEditing(true);
  };

  const handleEditSearchGroup = (item: SearchGroup) => {
    setEditableItem(item);
    setEditType('searchGroup');
    setIsEditing(true);
  };

  const handleEditCompetitorGroup = (item: CompetitorGroup) => {
    setEditableItem(item);
    setEditType('competitorGroup');
    setIsEditing(true);
  };

  const handleDeleteGeoTargeting = (geoId: number) => {
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this geo targeting?'
    );
    if (userConfirmed) {
      deleteGeoMutation.mutate(geoId);
    }
  };

  const handleDeleteSearchGroup = (groupId: number) => {
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this search group?'
    );
    if (userConfirmed) {
      deleteSearchMutation.mutate(groupId);
    }
  };

  const handleDeleteCompetitorGroup = (groupId: number) => {
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this competitor group?'
    );
    if (userConfirmed) {
      deleteCompetitorMutation.mutate(groupId);
    }
  };

  const handleSave = () => {
    if (editableItem && data) {
      const updatedConfigurations = data.configurations.map((config) => {
        if (
          editType === 'geoTargeting' &&
          config.geo_targeting.some(
            (gt) => gt.geo_id === (editableItem as GeoTargeting).geo_id
          )
        ) {
          return {
            ...config,
            geo_targeting: config.geo_targeting.map((gt) =>
              gt.geo_id === (editableItem as GeoTargeting).geo_id
                ? (editableItem as GeoTargeting)
                : gt
            ),
          };
        } else if (
          editType === 'searchGroup' &&
          config.search_groups.some(
            (sg) => sg.group_id === (editableItem as SearchGroup).group_id
          )
        ) {
          return {
            ...config,
            search_groups: config.search_groups.map((sg) =>
              sg.group_id === (editableItem as SearchGroup).group_id
                ? (editableItem as SearchGroup)
                : sg
            ),
          };
        } else if (
          editType === 'competitorGroup' &&
          config.competitor_groups.some(
            (cg) => cg.group_id === (editableItem as CompetitorGroup).group_id
          )
        ) {
          return {
            ...config,
            competitor_groups: config.competitor_groups.map((cg) =>
              cg.group_id === (editableItem as CompetitorGroup).group_id
                ? (editableItem as CompetitorGroup)
                : cg
            ),
          };
        }
        return config;
      });

      const updatedConfiguration = updatedConfigurations.find(
        (config) =>
          (editType === 'geoTargeting' &&
            config.geo_targeting.some(
              (gt) => gt.geo_id === (editableItem as GeoTargeting).geo_id
            )) ||
          (editType === 'searchGroup' &&
            config.search_groups.some(
              (sg) => sg.group_id === (editableItem as SearchGroup).group_id
            )) ||
          (editType === 'competitorGroup' &&
            config.competitor_groups.some(
              (cg) => cg.group_id === (editableItem as CompetitorGroup).group_id
            ))
      );

      if (updatedConfiguration) {
        mutation.mutate(updatedConfiguration);
      }
    }
  };

  const uploadMutation = useMutation(uploadFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['competitiveData', customerId]);
      setCompIntFileCFG(null);
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setCompIntFileCFG(event.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (hasChanges) {
      alert(
        'You have unsaved changes. Please save your changes before uploading a file.'
      );
      return; // Prevent file upload until changes are saved
    }

    if (CompIntfileCFG) {
      const userConfirmed = window.confirm(
        'Are you sure you want to upload this file?'
      );

      if (userConfirmed) {
        await uploadMutation.mutateAsync({
          CompIntfileCFG,
          customerId,
          studyName,
          domain,
          frequency,
          provider,
        });
        setCompIntFileCFG(null);
        const fileInput = document.getElementById(
          'CompIntfileCFG'
        ) as HTMLInputElement | null;
        if (fileInput) {
          fileInput.value = '';
        }
      }
    } else {
      alert('No file selected.');
    }
  };

  const handleSaveDetails = () => {
    const userConfirmed = window.confirm(
      'Are you sure you want to save these changes?'
    );

    if (userConfirmed && data) {
      const updatedConfig = {
        ...data.configurations[0],
        study_name: studyName,
        domain,
        frequency,
        provider,
      };

      mutation.mutate(updatedConfig);
    }
  };

  const handleChange = <T,>(
    setter: React.Dispatch<React.SetStateAction<T>>,
    value: T
  ) => {
    setter(value);
    setHasChanges(true);
  };

  // Function to reset values back to initial values
  const resetToInitialValues = () => {
    setStudyName(initialStudyName);
    setDomain(initialDomain);
    setFrequency(initialFrequency);
    setProvider(initialProvider);
    setHasChanges(false);
  };

  const handleAddGeoTargeting = () => {
    setNewGeoTargeting({
      customer_id: customerId,
      geo_id: 0,
      engine: '',
      device: '',
      country: '',
      country_code: '',
      language: '',
      language_code: '',
      state: '',
      city: '',
      postal_code: '',
      latitude: '',
      longitude: '',
      radius: '',
    });
    setAddType('geoTargeting');
    setIsAdding(true);
  };

  const handleAddSearchGroup = () => {
    setNewSearchGroup({
      customer_id: customerId,
      id: 0,
      group_id: 0,
      group_name: '',
      search_terms: '',
    });
    setAddType('searchGroup');
    setIsAdding(true);
  };

  const handleAddCompetitorGroup = () => {
    setNewCompetitorGroup({
      customer_id: customerId,
      id: 0,
      group_id: 0,
      group_name: '',
      domains: '',
    });
    setAddType('competitorGroup');
    setIsAdding(true);
  };

  const handleSaveNewItem = async () => {
    if (addType === 'geoTargeting' && newGeoTargeting) {
      await createGeoTargeting(newGeoTargeting);
      queryClient.invalidateQueries(['competitiveData', customerId]);
    } else if (addType === 'searchGroup' && newSearchGroup) {
      await createSearchGroup(newSearchGroup);
      queryClient.invalidateQueries(['competitiveData', customerId]);
    } else if (addType === 'competitorGroup' && newCompetitorGroup) {
      await createCompetitorGroup(newCompetitorGroup);
      queryClient.invalidateQueries(['competitiveData', customerId]);
    }
    setIsAdding(false);
  };

  const handleExportCompIntent = (customerId: string) => {
    const url = `/export-competitive-intelligence/${customerId}/`;

    fetch(url, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error('Failed to download the file');
        }
      })
      .then((blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `competitive_intelligence_data_${customerId}.xlsx`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const isAdmin = loggedInUser?.role === 'Admin';

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <div>Failed to fetch data</div>;
  }

  if (!data) {
    return enabled ? (
      <div className="bg-light border-t border-success">
        <h2 className="text-primarygray text-2xl font-bold mt-4">
          Competitive Intelligence
        </h2>
        <div className="flex items-center mt-6 grid-cols-2">
          <div className="border border-thirdgray rounded-md px-6">
            <label
              htmlFor="CompIntfileCFG"
              className="cursor-pointer text-sm font-bold flex items-center text-thirdgray"
            >
              {CompIntfileCFG
                ? CompIntfileCFG.name
                : `competitive_intelligence_study_${customerId}`}
            </label>
            <input
              type="file"
              id="CompIntfileCFG"
              className="hidden"
              accept=".xlsx"
              onChange={handleFileChange}
            />
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/refresh.svg`}
            alt="refresh-icon"
            className="ml-2 cursor-pointer"
            onClick={handleFileUpload}
          />
        </div>

        <div className="flex flex-col space-y-4 py-6">
          <div className="flex flex-row space-x-4">
            <div className="flex flex-col w-1/2">
              <label className="font-bold text-sm mb-2">Study Name</label>
              <input
                type="text"
                className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                placeholder="Us-Test-demo - Market Analysis"
                value={studyName}
                onChange={(e) => setStudyName(e.target.value)}
              />
            </div>
            <div className="flex flex-col w-1/2">
              <label className="font-bold text-sm mb-2">Domain</label>
              <input
                type="text"
                className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
            </div>
          </div>
          {isAdmin ? (
            <div className="flex flex-row space-x-4 py-4">
              <div className="flex flex-col w-1/2">
                <label className="font-bold text-sm mb-2">Frequency</label>
                <input
                  type="number"
                  className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                  value={3}
                  readOnly
                />
              </div>
              <div className="flex flex-col w-1/2">
                <label className="font-bold text-sm mb-2">Provider</label>
                <select
                  className="border border-gray200 rounded p-2.5 text-thirdgray text-sm"
                  value={provider}
                  onChange={(e) => setProvider(e.target.value)}
                >
                  <option value="Oxylabs">Oxylabs</option>
                  <option value="SerpAPI">SerpAPI</option>
                </select>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  }

  const geoTargetingData = data.configurations.flatMap((config) =>
    config.geo_targeting.map((gt) => ({ ...gt, id: config.study_id }))
  );
  const searchGroupsData = data.configurations.flatMap((config) =>
    config.search_groups.map((sg) => ({
      ...sg,
      id: config.study_id,
      group_id: sg.group_id,
    }))
  );
  const competitorGroupsData = data.configurations.flatMap((config) =>
    config.competitor_groups.map((cg) => ({
      ...cg,
      id: config.study_id,
      group_id: cg.group_id,
    }))
  );

  return (
    <div className="bg-light border-t border-success">
      <h2 className="text-primarygray text-2xl font-bold mt-4">
        Competitive Intelligence
      </h2>
      <div className="flex items-center mt-6 grid-cols-2">
        <div className="border border-thirdgray rounded-md px-6">
          <label
            htmlFor="CompIntfileCFG"
            className="cursor-pointer text-sm font-bold flex items-center text-thirdgray"
          >
            {CompIntfileCFG
              ? CompIntfileCFG.name
              : `competitive_intelligence_study_${customerId}`}
          </label>
          <input
            type="file"
            id="CompIntfileCFG"
            className="hidden"
            accept=".xlsx"
            onChange={handleFileChange}
          />
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/static/img/download_green.svg`}
          alt="download_green-icon"
          className="ml-2 cursor-pointer"
          onClick={() => handleExportCompIntent(customerId)}
        />
        <img
          src={`${process.env.PUBLIC_URL}/static/img/refresh.svg`}
          alt="refresh-icon"
          className="ml-2 cursor-pointer"
          onClick={handleFileUpload}
        />
      </div>
      <div className="flex flex-col space-y-4 py-6">
        {data.configurations.map((config, index) => (
          <div key={index} className="flex flex-col gap-4">
            <div className="flex flex-row space-x-4">
              <div className="flex flex-col w-1/2">
                <label className="font-bold text-sm mb-2">Study Name</label>
                <input
                  type="text"
                  className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                  placeholder="Us-Test-demo - Market Analysis"
                  value={studyName}
                  onChange={(e) => handleChange(setStudyName, e.target.value)}
                />
              </div>
              <div className="flex flex-col w-1/2">
                <label className="font-bold text-sm mb-2">Domain</label>
                <input
                  type="text"
                  className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                  value={domain}
                  onChange={(e) => handleChange(setDomain, e.target.value)}
                />
              </div>
            </div>

            {/* --- ADMIN ONLY --- */}
            {isAdmin ? (
              <div className="flex flex-row space-x-4">
                <div className="flex flex-col w-1/2">
                  <label className="font-bold text-sm mb-2">Frequency</label>
                  <input
                    type="number"
                    className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                    value={3}
                    readOnly
                  />
                </div>
                <div className="flex flex-col w-1/2">
                  <label className="font-bold text-sm mb-2">Provider</label>
                  <select
                    className="border border-gray200 rounded p-2.5 text-thirdgray text-sm"
                    value={provider}
                    onChange={(e) => handleChange(setProvider, e.target.value)}
                  >
                    <option value="Oxylabs">Oxylabs</option>
                    <option value="SerpAPI">SerpAPI</option>
                  </select>
                </div>
              </div>
            ) : null}

            {/* Save & Reset Buttons */}
            <div className="flex justify-end space-x-4">
              {hasChanges && (
                <button
                  className="px-4 py-2 rounded-full bg-blue800 text-light"
                  onClick={resetToInitialValues}
                >
                  Reset
                </button>
              )}
              <button
                className={`px-4 py-2 rounded-full ${
                  hasChanges ? 'bg-success' : 'bg-gray200'
                } text-light`}
                onClick={handleSaveDetails}
                disabled={!hasChanges} // Disable the button if no changes
              >
                Save
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* #!TODO:MISSING Excel file of study */}
      <h2 className="text-primarygray text-sm font-bold">Geo Targeting</h2>
      <div className="overflow-x-auto mt-4">
        <Table<GeoTargeting>
          data={geoTargetingData}
          columns={[
            'engine',
            'device',
            'country',
            'language',
            'state',
            'city',
            'postal_code',
            'latitude',
            'longitude',
            'radius',
          ]}
          onEdit={handleEditGeoTargeting}
          onDelete={handleDeleteGeoTargeting}
          onAdd={handleAddGeoTargeting}
        />
      </div>

      <h2 className="text-primarygray text-sm font-bold mt-6">Search Groups</h2>
      <div className="overflow-x-auto mt-4">
        <Table<SearchGroup>
          data={searchGroupsData}
          columns={['group_name', 'search_terms']}
          onEdit={handleEditSearchGroup}
          onDelete={handleDeleteSearchGroup}
          onAdd={handleAddSearchGroup}
        />
      </div>

      <h2 className="text-primarygray text-sm font-bold mt-6">
        Competitor Groups
      </h2>
      <div className="overflow-x-auto mt-4">
        <Table<CompetitorGroup>
          data={competitorGroupsData}
          columns={['group_name', 'domains']}
          onEdit={handleEditCompetitorGroup}
          onDelete={handleDeleteCompetitorGroup}
          onAdd={handleAddCompetitorGroup}
        />
      </div>

      {isEditing && editableItem && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-light p-4 rounded shadow-md w-1/2 overflow-auto max-h-96">
            <h2 className="text-primarygray text-2xl font-bold">
              Edit{' '}
              {editType === 'geoTargeting'
                ? 'Geo Targeting'
                : editType === 'searchGroup'
                ? 'Search Group'
                : 'Competitor Group'}
            </h2>
            <div className="mt-4">
              {editType === 'geoTargeting' && (
                <>
                  <label className="block text-sm font-medium text-primarygray">
                    Engine
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as GeoTargeting).engine}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        engine: e.target.value,
                      } as GeoTargeting)
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    Device
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as GeoTargeting).device}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        device: e.target.value,
                      } as GeoTargeting)
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    Country
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as GeoTargeting).country}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        country: e.target.value,
                      } as GeoTargeting)
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    Language
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as GeoTargeting).language}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        language: e.target.value,
                      } as GeoTargeting)
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    State
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as GeoTargeting).state}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        state: e.target.value,
                      } as GeoTargeting)
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    City
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as GeoTargeting).city}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        city: e.target.value,
                      } as GeoTargeting)
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    Postal Code
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as GeoTargeting).postal_code}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        postal_code: e.target.value,
                      } as GeoTargeting)
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    Latitude
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as GeoTargeting).latitude}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        latitude: e.target.value,
                      } as GeoTargeting)
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    Longitude
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as GeoTargeting).longitude}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        longitude: e.target.value,
                      } as GeoTargeting)
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    Radius
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as GeoTargeting).radius}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        radius: e.target.value,
                      } as GeoTargeting)
                    }
                  />
                </>
              )}
              {editType === 'searchGroup' && (
                <>
                  <label className="block text-sm font-medium text-primarygray">
                    Group Name
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as SearchGroup).group_name}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        group_name: e.target.value,
                      } as SearchGroup)
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    Search Terms
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as SearchGroup).search_terms}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        search_terms: e.target.value,
                      } as SearchGroup)
                    }
                  />
                </>
              )}
              {editType === 'competitorGroup' && (
                <>
                  <label className="block text-sm font-medium text-primarygray">
                    Group Name
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as CompetitorGroup).group_name}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        group_name: e.target.value,
                      } as CompetitorGroup)
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    Domains
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={(editableItem as CompetitorGroup).domains}
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        domains: e.target.value,
                      } as CompetitorGroup)
                    }
                  />
                </>
              )}
            </div>
            <div className="mt-4 flex justify-end">
              <button
                className="mr-2 bg-thirdgray text-light px-4 py-2 rounded"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>
              <button
                className="bg-success text-light px-4 py-2 rounded"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {isAdding && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-light p-4 rounded shadow-md w-1/2 overflow-auto max-h-96">
            <h2 className="text-primarygray text-2xl font-bold">
              Add New{' '}
              {addType === 'geoTargeting'
                ? 'Geo Targeting'
                : addType === 'searchGroup'
                ? 'Search Group'
                : 'Competitor Group'}
            </h2>
            <div className="mt-4">
              {addType === 'geoTargeting' && newGeoTargeting && (
                <>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-primarygray">
                        Engine
                      </label>
                      <input
                        type="text"
                        className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                        value={newGeoTargeting.engine}
                        onChange={(e) =>
                          setNewGeoTargeting({
                            ...newGeoTargeting,
                            engine: e.target.value,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-primarygray">
                        Device
                      </label>
                      <input
                        type="text"
                        className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                        value={newGeoTargeting.device}
                        onChange={(e) =>
                          setNewGeoTargeting({
                            ...newGeoTargeting,
                            device: e.target.value,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-primarygray">
                        Country
                      </label>
                      <input
                        type="text"
                        className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                        value={newGeoTargeting.country}
                        onChange={(e) =>
                          setNewGeoTargeting({
                            ...newGeoTargeting,
                            country: e.target.value,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-primarygray">
                        Country Code
                      </label>
                      <input
                        type="text"
                        className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                        value={newGeoTargeting.country_code}
                        onChange={(e) =>
                          setNewGeoTargeting({
                            ...newGeoTargeting,
                            country_code: e.target.value,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-primarygray">
                        Language
                      </label>
                      <input
                        type="text"
                        className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                        value={newGeoTargeting.language}
                        onChange={(e) =>
                          setNewGeoTargeting({
                            ...newGeoTargeting,
                            language: e.target.value,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-primarygray">
                        Language Code
                      </label>
                      <input
                        type="text"
                        className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                        value={newGeoTargeting.language_code}
                        onChange={(e) =>
                          setNewGeoTargeting({
                            ...newGeoTargeting,
                            language_code: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-primarygray">
                        State
                      </label>
                      <input
                        type="text"
                        className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                        value={newGeoTargeting.state}
                        onChange={(e) =>
                          setNewGeoTargeting({
                            ...newGeoTargeting,
                            state: e.target.value,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-primarygray">
                        City
                      </label>
                      <input
                        type="text"
                        className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                        value={newGeoTargeting.city}
                        onChange={(e) =>
                          setNewGeoTargeting({
                            ...newGeoTargeting,
                            city: e.target.value,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-primarygray">
                        Postal Code
                      </label>
                      <input
                        type="text"
                        className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                        value={newGeoTargeting.postal_code}
                        onChange={(e) =>
                          setNewGeoTargeting({
                            ...newGeoTargeting,
                            postal_code: e.target.value,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-primarygray">
                        Latitude
                      </label>
                      <input
                        type="text"
                        className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                        value={newGeoTargeting.latitude}
                        onChange={(e) =>
                          setNewGeoTargeting({
                            ...newGeoTargeting,
                            latitude: e.target.value,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-primarygray">
                        Longitude
                      </label>
                      <input
                        type="text"
                        className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                        value={newGeoTargeting.longitude}
                        onChange={(e) =>
                          setNewGeoTargeting({
                            ...newGeoTargeting,
                            longitude: e.target.value,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-primarygray">
                        Radius
                      </label>
                      <input
                        type="text"
                        className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                        value={newGeoTargeting.radius}
                        onChange={(e) =>
                          setNewGeoTargeting({
                            ...newGeoTargeting,
                            radius: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              {addType === 'searchGroup' && newSearchGroup && (
                <>
                  <label className="block text-sm font-medium text-primarygray">
                    Group Name
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={newSearchGroup.group_name}
                    onChange={(e) =>
                      setNewSearchGroup({
                        ...newSearchGroup,
                        group_name: e.target.value,
                      })
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    Search Terms
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={newSearchGroup.search_terms}
                    onChange={(e) =>
                      setNewSearchGroup({
                        ...newSearchGroup,
                        search_terms: e.target.value,
                      })
                    }
                  />
                </>
              )}
              {addType === 'competitorGroup' && newCompetitorGroup && (
                <>
                  <label className="block text-sm font-medium text-primarygray">
                    Group Name
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={newCompetitorGroup.group_name}
                    onChange={(e) =>
                      setNewCompetitorGroup({
                        ...newCompetitorGroup,
                        group_name: e.target.value,
                      })
                    }
                  />
                  <label className="block text-sm font-medium text-primarygray">
                    Domains
                  </label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={newCompetitorGroup.domains}
                    onChange={(e) =>
                      setNewCompetitorGroup({
                        ...newCompetitorGroup,
                        domains: e.target.value,
                      })
                    }
                  />
                </>
              )}
            </div>
            <div className="mt-4 flex justify-end">
              <button
                className="mr-2 bg-thirdgray text-light px-4 py-2 rounded"
                onClick={() => setIsAdding(false)}
              >
                Cancel
              </button>
              <button
                className="bg-success text-light px-4 py-2 rounded"
                onClick={handleSaveNewItem}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompetitiveInt;
