import { useEffect, useRef, useState } from 'react';

// COMPONENTS
import ToggleSwitch from '../ToggleSwitch';

// UTILS
import { downloadImage, downloadXLS, DownloadXLSParams } from 'utils/download';

interface ChartWrapperProps {
  header: string;
  description?: string;
  toggle?: {
    labels?: [string | null, string | null];
    checked: boolean;
    onChange: (
      isChecked: boolean,
      evt?: React.ChangeEvent<HTMLInputElement>
    ) => void;
  };
  children: React.ReactNode;
  downloadParams: DownloadXLSParams;
}

// AUTO OPTIMIZE CHART WRAPPER
function ChartContainer({
  header,
  toggle,
  children,
  description,
  downloadParams,
}: ChartWrapperProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // JSX
  return (
    <div
      className="bg-light rounded-2xl shadow-sm mt-8"
      id="autoOptimizeCostVsExpectation"
    >
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">
            {header}
          </h2>
          <h3 className="text-gray800 text-sm font-bold mb-2 pl-4">
            {description}
          </h3>
        </div>
        <div className="flex items-center gap-4">
          {toggle ? <ToggleSwitch {...toggle} /> : null}
          <div
            className="relative"
            ref={dropdownRef}
            id="downloadDropdownMenu-autoOptimizeCostVsExpectation"
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
              alt="dots"
              className="h-6 w-6 mr-4 cursor-pointer"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div
                className="absolute right-4 mt-2 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
              >
                <ul>
                  {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      closeDropdown();
                      downloadXLS(
                        downloadParams.filename,
                        downloadParams.headers,
                        downloadParams.labels,
                        downloadParams.columns
                      );
                    }}
                  >
                    Download XLS
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      closeDropdown();
                      downloadImage(downloadParams.filename);
                    }}
                  >
                    Download PNG image
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}

export default ChartContainer;
