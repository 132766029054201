import Insights from '../components/homepage/Insights';
import AutoOptimize from '../components/homepage/AutoOptimize';
import ConsumerIntent from '../components/homepage/ConsumerIntent';
import CompetitiveIntelligence from '../components/homepage/CompetitiveIntelligence';

function Home() {
  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <div className="grid grid-cols-1 xl-1400:grid-cols-2 gap-6 justify-items-center">
          <div className="w-full">
            <ConsumerIntent />
          </div>
          <div className="w-full">
            <CompetitiveIntelligence />
          </div>
          <div className="w-full">
            <AutoOptimize />
          </div>
          <div className="w-full">
            <Insights />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
