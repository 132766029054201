import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartOptions, Chart, registerables } from 'chart.js';

Chart.register(...registerables);

interface YoYChangeBarChartProps {
  growthPercentageData: Array<{
    Feature: string;
    demand_previous_year: number;
    demand_current_year: number;
    growth_percentage: number;
  }>;
}

const YoYChangeBarChart: React.FC<YoYChangeBarChartProps> = ({ growthPercentageData }) => {
  const colors = [
    'rgba(34, 34, 34, 0.80)',
    'rgba(89, 89, 89, 0.8)',
    'rgba(144, 144, 144, 0.8)',
    'rgba(50, 215, 111, 0.8)',
    'rgba(3, 197, 255, 0.8)',
    'rgba(84, 79, 197, 0.8)',
    'rgba(254, 106, 53, 0.8)',
    'rgba(107, 138, 188, 0.8)',
    'rgba(213, 104, 251, 0.8)',
    'rgba(47, 224, 202, 0.8)',
    'rgba(250, 75, 66, 0.8)',
    'rgba(254, 181, 106, 0.8)',
    'rgba(254, 181, 106, 0.8)'
  ];

  const labels = growthPercentageData.map(item => item.Feature);
  const dataValues = growthPercentageData.map(item => (item.growth_percentage).toFixed(2));
  const chartRef = useRef<HTMLDivElement>(null);

  const calculateBarThickness = (chartWidth: number, numberOfLabels: number) => {
    return Math.max(10, chartWidth / (numberOfLabels * 2));
  };

  const chartWidth = chartRef.current ? chartRef.current.offsetWidth : 800;
  const barThickness = calculateBarThickness(chartWidth, labels.length);

  const YoYChangeData = {
    labels,
    datasets: [
      {
        label: 'Demand',
        data: dataValues,
        backgroundColor: colors,
        borderWidth: 1,
        barThickness: barThickness,
        borderRadius: 8,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toFixed(2) + '%';
            }
            return label;
          }
        }
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Feature',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
          maxRotation: 90,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: '% YoY Growth',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
          stepSize: 50,
          align: 'center',
          callback: (value) => `${value}%`,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
        max: 100,
        min: -100,
      },
    },
  };

  return (
    <div className="bg-light rounded-2xl">
      <div className="flex relative">
        <div ref={chartRef} className="flex px-2 py-6 justify-center items-center md:h-[295px] w-full h-[295px]">
          <Bar data={YoYChangeData} options={options}/>
        </div>
      </div>
    </div>
  );
};

export default YoYChangeBarChart;
