import 'chart.js/auto';
import { subDays } from 'date-fns';
import { useQuery } from 'react-query';
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';

// API REQUESTS
import { fetchNewSearchTermOverlaps } from 'api';

// COMPONENTS
import Tooltip from '../../Tooltip';
import { Bar } from 'react-chartjs-2';
import MultiDropdown from '../../MultiDropdown';
import LoadingSpinner from '../../LoadingSpinner';
import DatepickerComponent from '../../DatepickerComponent';

// TYPES
import {
  LegendData,
  BubbleChartDataset,
  SearchTermOverlapData,
  CustomBarPluginOptions,
} from 'types/comp-intel/search-term-overlap';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { TimeFrameDateRange } from '../../TimeFramePicker';
import { ChartOptions } from 'chart.js';

// UTILS
import { downloadImage, downloadXLS } from 'utils/download';

/* 
  Bubble/Venn Diagram toggle and option has been removed/hidden (not a good fit for data)
  TODO - cleanup code once certain this won't be asked to be available again
*/

interface SearchTermOverlapsProps {
  data: SearchTermOverlapData;
  startDate: Date | null;
  endDate: Date | null;
  compBrands: string[];
  searchTerms: string[];
}

const calculateBarThickness = (chartWidth: number, numberOfLabels: number) => {
  return Math.max(10, chartWidth / (numberOfLabels * 2));
};

const getDefaultBubbleState = (hiddenDataSets: string[]) => ({
  datasets: [
    {
      label: 'No data',
      data: [{ x: 0, y: 0, r: 0 }],
      backgroundColor: '#222222CC',
      hoverBackgroundColor: '#222222CC',
      hidden: hiddenDataSets.includes('No data'),
    },
  ],
});

const COLORS: string[] = [
  'rgba(34, 34, 34, 0.80)',
  'rgba(89, 89, 89, 0.8)',
  'rgba(144, 144, 144, 0.8)',
  'rgba(50, 215, 111, 0.8)',
  'rgba(3, 197, 255, 0.8)',
  'rgba(84, 79, 197, 0.8)',
  'rgba(254, 106, 53, 0.8)',
  'rgba(107, 139, 188, 0.8)',
  'rgba(213, 104, 251, 0.8)',
  'rgba(47, 254, 202, 0.8)',
];

const DEFAULT_BAR_STATE = {
  labels: [['No data']],
  datasets: [
    {
      label: 'Searches',
      data: [0],
      backgroundColor: ['#222222CC'],
      borderRadius: 8,
      barThickness: 96,
    },
  ],
};

const BAR_CHART_OPTIONS: ChartOptions<'bar'> & CustomBarPluginOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (context) => context[0].label.split(',').join(' '),
      },
      enabled: true,
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Search Terms',
        color: '#4D4D4D',
        font: {
          size: 14,
          style: 'normal',
          weight: 700,
          lineHeight: 1.28,
          family: 'DM Sans',
        },
      },
      ticks: {
        color: '#333',
        font: {
          size: 14,
          style: 'normal',
          weight: 500,
          lineHeight: 1.28,
          family: 'DM Sans',
        },
        maxRotation: 0,
        minRotation: 0,
        autoSkip: false,
      },
      grid: {
        color: '#B3B3B3',
      },
      border: {
        color: '#333333',
        display: true,
      },
    },
    y: {
      title: {
        display: true,
        text: 'Overlap (%)',
        color: '#4D4D4D',
        font: {
          size: 14,
          style: 'normal',
          weight: 700,
          lineHeight: 1.28,
          family: 'DM Sans',
        },
      },
      ticks: {
        display: true,
        color: '#333',
        font: {
          size: 14,
          style: 'normal',
          weight: 500,
          lineHeight: 1.28,
          family: 'DM Sans',
        },
        align: 'center',
        callback: function (tickValue: string | number) {
          return tickValue + '%';
        },
        stepSize: 25,
      },
      grid: {
        color: '#B3B3B3',
      },
      border: {
        color: '#333333',
        display: true,
      },
    },
  },
};

// const BUBBLE_CHART_OPTIONS: ChartOptions<'bubble'> & CustomPluginOptions = {
//   responsive: true,
//   maintainAspectRatio: false,
//   plugins: {
//     legend: {
//       display: false,
//     },
//     tooltip: {
//       callbacks: {
//         label: function (context) {
//           const label = context.dataset.label || '';
//           const value = (context.raw as BubbleData).r / 2;
//           return `${label}: ${value}`;
//         },
//       },
//       enabled: true,
//     },
//     extendedGridLinePlugin: false,
//   },
//   scales: {
//     x: {
//       display: false,
//     },
//     y: {
//       display: false,
//     },
//   },
// };

// SEARCH TERMS OVERLAP CHARTS (BAR/VENN)
function SearchTermsOverlap({
  endDate,
  compBrands,
  searchTerms,
}: SearchTermOverlapsProps) {
  const [view] = useState('bars');

  const [dateRange, setDateRange] = useState<TimeFrameDateRange>({
    startDate: endDate || subDays(new Date(), 1),
    endDate: endDate || subDays(new Date(), 1),
  });

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [hiddenDatasets, setHiddenDatasets] = useState<string[]>([]);

  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const chartRef = useRef<HTMLDivElement>(null);

  const { data: currentData, isLoading } = useQuery({
    queryKey: [dateRange.startDate, selectedBrands, searchTerms],
    queryFn: () =>
      fetchNewSearchTermOverlaps(
        dateRange?.startDate?.toISOString().slice(0, 10) || '',
        selectedBrands.join(','),
        searchTerms
      ),
    enabled: !!dateRange.startDate && selectedBrands.length > 1,
  });

  const preSelectedOptions = useMemo(() => {
    const selections = compBrands.slice(0, 3);
    setSelectedBrands([...selections]);
    return selections;
  }, [compBrands]);

  const competitorOptions = useMemo(() => [...compBrands], [compBrands]);

  // HANDLE DATE PICKER SELECTIONS
  const onChangeDatePicker = (newValue: DateValueType) => {
    if (newValue?.startDate) {
      setDateRange({
        startDate: new Date(newValue.startDate),
        endDate: new Date(newValue.startDate),
      });
    } else {
      setDateRange({ startDate: null, endDate: null });
    }
  };

  // BUBBLE CHART STATE
  const [bubbleData, setBubbleData] = useState(
    getDefaultBubbleState(hiddenDatasets)
  );
  // const [filteredBubbleData, setFilteredBubbleData] = useState(
  //   getDefaultBubbleState(hiddenDatasets)
  // );

  // BAR CHART STATE
  const [barData, setBarData] = useState({ ...DEFAULT_BAR_STATE });
  const [filteredBarData, setFilteredBarData] = useState({
    ...DEFAULT_BAR_STATE,
  });

  const toggleDatasetVisibility = (label: string) => {
    setHiddenDatasets((prev) =>
      prev.includes(label)
        ? prev.filter((item) => item !== label)
        : [...prev, label]
    );
  };

  const getFilteredBubbleData = useCallback(
    (device: 'desktop' | 'mobile') => {
      const bubbleDatasets: BubbleChartDataset = { datasets: [] };

      if (!currentData?.[device]) {
        return getDefaultBubbleState(hiddenDatasets);
      }

      const sortedSearchTerms = Object.entries(currentData[device])
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(([searchTerm]) => searchTerm);

      sortedSearchTerms.forEach((searchTerm, index) => {
        const bubbleDataset = {
          label: `${searchTerm}`,
          data: [
            {
              x: Math.random() * 40,
              y: Math.random() * 40,
              r: currentData?.[device]?.[searchTerm] * 2,
            },
          ],
          backgroundColor: COLORS[index % COLORS.length],
          hoverBackgroundColor: COLORS[index % COLORS.length],
          hidden: hiddenDatasets.includes(`${searchTerm}`),
        };

        bubbleDatasets.datasets.push(bubbleDataset);
      });

      return bubbleDatasets;
    },
    [currentData, hiddenDatasets]
  );

  const getFilteredBarData = useCallback(
    (device: 'desktop' | 'mobile') => {
      const overlapValues: number[] = [];

      if (!currentData?.[device]) {
        return DEFAULT_BAR_STATE;
      }

      const sortedSearchTerms = Object.entries(currentData[device])
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(([searchTerm]) => searchTerm);

      sortedSearchTerms.forEach((searchTerm) => {
        overlapValues.push(
          parseFloat((currentData?.[device]?.[searchTerm] || 0).toFixed(2))
        );
      });

      const labels = sortedSearchTerms.map((term) => term.split(' '));
      const chartWidth = chartRef.current ? chartRef.current.offsetWidth : 800;
      const barThickness = calculateBarThickness(chartWidth, labels.length);
      const barData = {
        labels: labels,
        datasets: [
          {
            label: 'Searches',
            data: overlapValues,
            backgroundColor: COLORS,
            borderRadius: 8,
            barThickness: barThickness,
          },
        ],
      };

      return barData;
    },
    [currentData]
  );

  useEffect(() => {
    setBubbleData(getFilteredBubbleData('desktop'));
    setBarData(getFilteredBarData('desktop'));
  }, [
    currentData,
    view,
    selectedBrands,
    getFilteredBubbleData,
    getFilteredBarData,
  ]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const filteredBarData = {
      ...barData,
      datasets: barData.datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data.map((value, index) =>
          hiddenDatasets.includes(barData.labels[index].join(' ')) ? 0 : value
        ),
      })),
    };

    setFilteredBarData(filteredBarData);

    // const filteredBubbleData = {
    //   ...bubbleData,
    //   datasets: bubbleData.datasets.map((dataset) => ({
    //     ...dataset,
    //     hidden: hiddenDatasets.includes(dataset.label),
    //     data: dataset.hidden ? [] : dataset.data,
    //   })),
    // };

    // setFilteredBubbleData(filteredBubbleData);
  }, [bubbleData, barData, currentData, hiddenDatasets]);

  const [isLegendExpanded, setIsLegendExpanded] = useState(false);
  const [legendData, setLegendData] = useState<LegendData[]>([]);

  const calcLegendData = (bubbleDataset: BubbleChartDataset) => {
    const legendData = bubbleDataset.datasets.map((dataset) => ({
      label: dataset.label,
      color: dataset.backgroundColor,
      dataLabel: dataset.data[0]?.r / 2 + '%',
    }));

    return legendData;
  };

  useEffect(() => {
    setLegendData(calcLegendData(bubbleData));
  }, [currentData, selectedBrands, bubbleData]);
  const visibleBrands = isLegendExpanded ? legendData : legendData.slice(0, 8);

  const downloadChartImage = () => {
    downloadImage('SearchTermsOverlap');
  };

  const downloadChartData = () => {
    const chartData = filteredBarData;
    let headers: string[] = [];
    let labels: string[] = [];
    let columns: Array<Array<string | number>> = [];

    if ('labels' in chartData && chartData.labels) {
      headers = ['Search Terms', 'Overlap (%)'];
      labels = chartData.labels.map((label: string[]) => label.join(' '));
      columns = [chartData.datasets[0].data.map((value: number) => value)];
    }

    downloadXLS('Search_Terms_Overlap', headers, labels, columns);
  };

  // JSX
  return (
    <div
      id="SearchTermsOverlap"
      className="bg-light rounded-2xl shadow-sm mt-8"
    >
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">
            Search Terms Overlap{' '}
            <Tooltip
              className="w-72 whitespace-normal text-left"
              content={
                <span>
                  Percentage of time a search term appears for{' '}
                  <span className="italic">all of the selected brands</span>.
                </span>
              }
            />
          </h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">
            Identify Search term overlaps and niches in the competitive
            landscape
          </h3>
        </div>
        <div className="flex items-center">
          <div className="mr-8">
            <DatepickerComponent
              value={dateRange}
              onChange={onChangeDatePicker}
              product="search_terms"
              showShortcuts={false}
              asSingle={true}
            />
          </div>
          {/* <div className="flex items-center mr-4 text-xs">
            <span
              className={
                view === 'bars' ? 'text-primarygray mr-2' : 'text-gray400 mr-2'
              }
            >
              Bar chart
            </span>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={view === 'bubbles'}
                onChange={() => setView(view === 'bars' ? 'bubbles' : 'bars')}
              />
              <div className="relative w-10 h-5 bg-lightblue rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
            </label>
            <span
              className={
                view === 'bubbles'
                  ? 'text-primarygray ml-2'
                  : 'text-gray400 ml-2'
              }
            >
              Venn Diagram
            </span>
          </div> */}
          <div
            className="flex items-center mr-4"
            ref={dropdownRef}
            id="downloadDropdownMenu-SearchTermsOverlap"
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
              alt="dots"
              className="h-6 w-6 cursor-pointer"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div
                className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
              >
                <ul>
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartData();
                      closeDropdown();
                    }}
                  >
                    Download XLS
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartImage();
                      closeDropdown();
                    }}
                  >
                    Download PNG image
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex relative">
        {isLoading ? (
          <div className="flex relative px-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[calc(92%-200px)] md:w-[65%] w-[60%] h-[295px]">
            <LoadingSpinner />
          </div>
        ) : (
          <div
            ref={chartRef}
            className={
              view === 'bubbles'
                ? 'flex relative px-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[calc(92%-200px)] md:w-[65%] w-[60%] h-[295px]'
                : 'flex relative px-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[calc(92%-200px)] md:w-[65%] w-[60%] h-[295px]'
            }
          >
            <Bar data={filteredBarData} options={BAR_CHART_OPTIONS} />
          </div>
        )}
        <div className="flex-none text-xs flex flex-col items-start pl-8 pr-4 py-6">
          <div>
            {visibleBrands.map((legend, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  checked={!hiddenDatasets.includes(legend.label)}
                  onChange={() => toggleDatasetVisibility(legend.label)}
                  style={{ accentColor: legend.color }}
                  className="mr-2"
                />
                <span className="ml-2">
                  {legend.label}: <strong>{legend.dataLabel}</strong>
                </span>
              </div>
            ))}
            {legendData.length > 8 && (
              <button
                onClick={() => setIsLegendExpanded(!isLegendExpanded)}
                className="mt-2 bg-light hover:underline"
              >
                {isLegendExpanded ? 'Show Less' : 'Show More'}
              </button>
            )}
            <div className="mt-6 text-sm font-bold flex gap-2 flex-col items-start">
              <p className="text-secondarygray">Filter:</p>
              <MultiDropdown
                placeholder="Select brands"
                options={competitorOptions}
                maxNChoices={3}
                preselectedOptions={preSelectedOptions}
                onOptionChange={setSelectedBrands}
                selectAllOption={false}
                applyButton={false}
                dropdownOpenWidth="max-w-80"
              />
              <p className="text-gray-400 text-xs">
                Min two brands and max three.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchTermsOverlap;
