import React from 'react';

interface LegendItem {
  label: string;
  icon: string;
}

interface LegendsData {
  [key: string]: LegendItem[];
}

const legendsData: LegendsData = {
  set1: [
    { label: 'Negative Keyword Removed', icon: `${process.env.PUBLIC_URL}/static/img/organic.svg` },
    { label: 'Negative Keyword Applied', icon: `${process.env.PUBLIC_URL}/static/img/paid.svg` },
  ],
  set2: [
    { label: 'Total', icon: `${process.env.PUBLIC_URL}/static/img/total_ao.svg` },
    { label: 'Organic', icon: `${process.env.PUBLIC_URL}/static/img/organic_ao.svg` },
    { label: 'Paid', icon: `${process.env.PUBLIC_URL}/static/img/paid_ao.svg` },
  ],
  set3: [
    { label: 'Cost', icon: `${process.env.PUBLIC_URL}/static/img/total_ao.svg` },
    { label: 'Expected Cost', icon: `${process.env.PUBLIC_URL}/static/img/organic_ao.svg` },
  ]
};

interface CustomLegendProps {
  legendSet: keyof LegendsData;
}

const CustomLegend: React.FC<CustomLegendProps> = ({ legendSet }) => {
  const legends = legendsData[legendSet];

  return React.createElement(
    'div',
    { style: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '10px' } },
    legends.map((legend, index) =>
      React.createElement(
        'div',
        { key: index, style: { display: 'flex', alignItems: 'center', marginBottom: '5px' } },
        React.createElement('img', {
          src: legend.icon,
          alt: legend.label,
          style: { width: '16px', height: '16px', marginRight: '10px' }
        }),
        React.createElement('span', null, legend.label)
      )
    )
  );
};

export default CustomLegend;
