import { DateType, DateValueType } from 'react-tailwindcss-datepicker';

export const formatDateRange = (dateRange: DateValueType) => {
  let startDate: DateType = dateRange?.startDate || null;
  let endDate: DateType = dateRange?.endDate || null;

  if (typeof dateRange?.startDate === 'string') {
    const temp = dateRange.startDate.split('-').map((val) => parseInt(val));
    startDate = new Date(temp[0], temp[1] - 1, temp[2]);
  }

  if (typeof dateRange?.endDate === 'string') {
    const temp = dateRange.endDate.split('-').map((val) => parseInt(val));
    endDate = new Date(temp[0], temp[1] - 1, temp[2]);
  }

  return {
    startDate,
    endDate,
  };
};

export const sortFuncStrings = (
  a: string,
  b: string,
  dir: 'asc' | 'desc' = 'asc'
) => {
  if (a.toLowerCase() < b.toLowerCase()) {
    return dir === 'asc' ? -1 : 1;
  }
  if (a.toLowerCase() > b.toLowerCase()) {
    return dir === 'asc' ? 1 : -1;
  }
  return 0;
};

export const sortFuncDropdownOptions = <T extends string | { label: string }>(
  optA: T,
  optB: T,
  dir: 'asc' | 'desc' = 'asc'
) => {
  const a = typeof optA === 'string' ? optA : optA.label;
  const b = typeof optB === 'string' ? optB : optB.label;

  if (a.toLowerCase() < b.toLowerCase()) {
    return dir === 'asc' ? -1 : 1;
  }
  if (a.toLowerCase() > b.toLowerCase()) {
    return dir === 'asc' ? 1 : -1;
  }
  return 0;
};

export const toFixedNum = (num: number, fixedPlaces = 0) => {
  return num.toLocaleString(undefined, {
    minimumFractionDigits: fixedPlaces,
    maximumFractionDigits: fixedPlaces,
  });
};
