import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import LoadingSpinner from '../LoadingSpinner';

interface Configuration {
  config_id: number;
  customer_id: string;
  cluster_name: string;
  cluster_group: string;
  sort: string;
  definition: string;
  brand: string;
  category: string;
  product: string;
  active: boolean;
}

interface CustomerInfo {
  customer_id: string;
  agency: string;
  client: string;
  line_of_business: string;
  market: string;
  region: string;
  platform: string[];
}

interface InsightsData {
  customer_info: CustomerInfo;
  configurations: Configuration[];
}

interface TableProps<T> {
  data: T[];
  columns: (keyof T)[];
  toggleView?: (id: number) => void;
  onEdit?: (item: T) => void;
  onDelete?: (id: number) => void;
  onAdd?: () => void;
}

function formatHeader(header: string): string {
  let formatted = header.replace(/_/g, ' ');
  formatted = formatted.replace(/([A-Z])/g, ' $1');
  return formatted.toUpperCase();
}

function Table<T extends {
  active: boolean; config_id: number 
}>({ data, columns, toggleView, onEdit, onDelete, onAdd }: TableProps<T>) {
  return (
    <div className="overflow-auto max-h-96">
      <table className="min-w-full bg-light table-auto">
        <thead className="text-primarygray text-xs font-normal">
          <tr>
            {columns.map((column, index) => (
              <th key={index} className="py-2 px-4 text-left">
                <div className="flex items-center">
                  {formatHeader(column as string)}
                  <img src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`} alt="sort-icon" className="ml-2" />
                </div>
              </th>
            ))}
            <th className="py-2 px-2 text-right">ACTIONS</th>
          </tr>
        </thead>
        <tbody className="text-primarygray text-xs font-normal rounded-md">
          {data.map((item, index) => (
            <tr key={item.config_id} className={index % 2 === 0 ? 'bg-gray50' : 'bg-gray100'}>
              {columns.map((column) => (
                <td key={column as string} className="py-2 px-4">
                  {column === 'active' && toggleView ? (
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        checked={item.active}
                        onChange={() => toggleView(item.config_id)}
                      />
                      <div className="relative w-10 h-5 bg-gray200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
                    </label>
                  ) : (
                    String(item[column])
                  )}
                </td>
              ))}
              <td className="py-2 px-2 flex justify-end">
                {onEdit && (
                  <button onClick={() => onEdit(item)}>
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/edit-03.svg`}
                      alt="edit"
                      className="h-4 w-4 mr-2"
                    />
                  </button>
                )}
                {onDelete && (
                  <button onClick={() => onDelete(item.config_id)}>
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/delete_option.svg`}
                      alt="delete"
                      className="h-4 w-4"
                    />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="border border-thirdgray rounded-[20px] px-1 mt-2 w-[68px]">
        <button className="text-sm text-thirdgray font-bold flex items-center" onClick={onAdd}>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/plus_gray.svg`}
            alt="plus_icon"
            className="w-[18px] h-[18px] mr-1"
          />
          ADD
        </button>
      </div>
    </div>
  );
}

interface InsightsProps {
  customerId: string;
  enabled: boolean;
}

const fetchInsightsData = async (customerId: string) => {
  const response = await fetch('/insights-list/');
  const data: InsightsData[] = await response.json();
  const filteredData = data.find(d => d.customer_info.customer_id === customerId);
  return filteredData || null;
};

const createConfiguration = async (configuration: Configuration) => {

  try {
    const response = await fetch('/insights/create/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(configuration),
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`Add new Cluster failed: ${errorText}`);
    }

    return response.json();

  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

const updateConfiguration = async (configuration: Configuration) => {
  const response = await fetch(`/insights/${configuration.config_id}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(configuration),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const deleteConfiguration = async (configId: number) => {
  const response = await fetch(`/insights/${configId}/delete/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const uploadFile = async ({ InsightsCFG, customerId }: { InsightsCFG: File, customerId: string }) => {

  const formData = new FormData();
  formData.append('file', InsightsCFG);
  formData.append('product', 'insights');

  if (customerId) {
    formData.append('customer_id', customerId);
  }

  try {
    const response = await fetch('/upload/', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`File upload failed: ${errorText}`);
      return null;
    }

    return response.json();

  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

function Insights({ customerId, enabled }: InsightsProps) {
  const queryClient = useQueryClient();
  const { data, isLoading, isError, refetch } = useQuery(['InsightsData', customerId], () => fetchInsightsData(customerId), {});

  const [editableItem, setEditableItem] = useState<Configuration | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  const [InsightsCFG, setInsightsCFG] = useState<File | null>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [newConfiguration, setNewConfiguration] = useState<Configuration>({
    config_id: 0,
    customer_id: '',
    cluster_name: '',
    cluster_group: '',
    sort: '',
    definition: '',
    brand: '',
    category: '',
    product: '',
    active: true,
  });

  const mutation = useMutation(updateConfiguration, {
    onSuccess: () => {
      queryClient.invalidateQueries(['InsightsData', customerId]);
      setIsEditing(false);
    },
  });

  const deleteMutation = useMutation(deleteConfiguration, {
    onSuccess: () => {
      queryClient.invalidateQueries(['InsightsData', customerId]);
    },
  });

  const addMutation = useMutation(createConfiguration, {
    onSuccess: () => {
      queryClient.invalidateQueries(['InsightsData', customerId]);
      setIsAdding(false);
    },
  });

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      refetch();
    }
  }, [deleteMutation.isSuccess, refetch]);

  const handleEdit = (item: Configuration) => {
    setEditableItem(item);
    setIsEditing(true);
  };

  const handleSave = () => {
    if (editableItem) {
      mutation.mutate(editableItem);
    }
  };

  const handleDelete = (configId: number) => {
    const userConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (userConfirmed) {
      deleteMutation.mutate(configId);
    }
  };

  const toggleView = (configId: number) => {
    const itemToToggle = data?.configurations.find(item => item.config_id === configId);
    if (itemToToggle) {
      mutation.mutate({ ...itemToToggle, active: !itemToToggle.active });
    }
  };


  const uploadMutation = useMutation(uploadFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['InsightsData', customerId]);
      setInsightsCFG(null);
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setInsightsCFG(event.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (InsightsCFG) {
      const userConfirmed = window.confirm("Are you sure you want to upload this file?");
      
      if (userConfirmed) {
        await uploadMutation.mutateAsync({ InsightsCFG, customerId });
        setInsightsCFG(null);
        const fileInput = document.getElementById('InsightsCFG') as HTMLInputElement | null;
        if (fileInput) {
          fileInput.value = '';
        }
      }
    } else {
      alert("No file selected.");
    }
  };

  const handleAdd = () => {
    setNewConfiguration({
      config_id: 0,
      customer_id: customerId,
      cluster_name: '',
      cluster_group: '',
      sort: '',
      definition: '',
      brand: '',
      category: '',
      product: '',
      active: true,
    });
    setIsAdding(true);
  };

  const handleSaveNew = () => {
    addMutation.mutate(newConfiguration);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <div>Failed to fetch data</div>;
  }

  if (!data || !data.configurations) {
    return (
      enabled ? (
        <div className="bg-light border-t border-success">
          <h2 className="text-primarygray text-2xl font-bold mt-4">Insights</h2>
          <div className="flex items-center mt-6 grid-cols-2">
            <div className="border border-thirdgray rounded-md px-6">
              <label htmlFor="InsightsCFG" className="cursor-pointer text-sm font-bold flex items-center text-thirdgray">
                {InsightsCFG ? InsightsCFG.name : `insights_${customerId}`}
              </label>
              <input
                type="file"
                id="InsightsCFG"
                className="hidden"
                onChange={handleFileChange}
                accept=".xlsx"
              />
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/static/img/refresh.svg`}
              alt="refresh-icon"
              className="ml-2 cursor-pointer"
              onClick={handleFileUpload}
            />
          </div>
        </div>
      ) : null
    );
  }

  const handleExportInsights = (customerId: string) => {

    const url = `/export-insights/${customerId}/`;
  
    fetch(url, {
      method: 'GET',
    })
    .then(response => {
      if (response.ok) {
        return response.blob();
      } else {
        throw new Error('Failed to download the file');
      }
    })
    .then(blob => {
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `insights_data_${customerId}.xlsx`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <div className="bg-light border-t border-success">
      <h2 className="text-primarygray text-2xl font-bold mt-4">Insights</h2>
      <div className="flex items-center mt-6 grid-cols-2">
        <div className="border border-thirdgray rounded-md px-6">
        <label htmlFor="InsightsCFG" className="cursor-pointer text-sm font-bold flex items-center text-thirdgray">
          {InsightsCFG ? InsightsCFG.name : `insights_${customerId}`}
        </label>
          <input
            type="file"
            id="InsightsCFG"
            className="hidden"
            onChange={handleFileChange}
            accept=".xlsx"
          />
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/static/img/download_green.svg`}
          alt="download_green-icon"
          className="ml-2 cursor-pointer"
          onClick={() => handleExportInsights(customerId)}
        />
        <img
          src={`${process.env.PUBLIC_URL}/static/img/refresh.svg`}
          alt="refresh-icon"
          className="ml-2 cursor-pointer"
          onClick={handleFileUpload}
        />
      </div>

      <h2 className="text-primarygray text-sm font-bold mt-6">Search Terms Clusters</h2>
      <div className="overflow-x-auto mt-4">
        <Table<Configuration>
          data={data.configurations}
          columns={['cluster_name', 'sort', 'definition', 'cluster_group', 'brand', 'category', 'product', 'active']}
          toggleView={toggleView}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onAdd={handleAdd}
        />
      </div>

      {isEditing && editableItem && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-light p-4 rounded shadow-md w-1/2 overflow-auto max-h-96">
            <h2 className="text-primarygray text-2xl font-bold">Edit Configuration</h2>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Cluster Name</label>
              <input
                type="text"
                className="mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                value={editableItem.cluster_name}
                onChange={(e) => setEditableItem({ ...editableItem, cluster_name: e.target.value })}
              />
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Sort</label>
              <input
                type="text"
                className="mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                value={editableItem.sort}
                onChange={(e) => setEditableItem({ ...editableItem, sort: e.target.value })}
              />
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Definition</label>
              <input
                type="text"
                className="mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                value={editableItem.definition}
                onChange={(e) => setEditableItem({ ...editableItem, definition: e.target.value })}
              />
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Cluster Group</label>
              <input
                type="text"
                className="mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                value={editableItem.cluster_group}
                onChange={(e) => setEditableItem({ ...editableItem, cluster_group: e.target.value })}
              />
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Brand</label>
              <input
                type="text"
                className="mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                value={editableItem.brand}
                onChange={(e) => setEditableItem({ ...editableItem, brand: e.target.value })}
              />
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Category</label>
              <input
                type="text"
                className="mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                value={editableItem.category}
                onChange={(e) => setEditableItem({ ...editableItem, category: e.target.value })}
              />
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Product</label>
              <input
                type="text"
                className="mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                value={editableItem.product}
                onChange={(e) => setEditableItem({ ...editableItem, product: e.target.value })}
              />
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Active</label>
              <input
                type="checkbox"
                className="mt-1"
                checked={editableItem.active}
                onChange={(e) => setEditableItem({ ...editableItem, active: e.target.checked })}
              />
            </div>
            <div className="mt-4 flex justify-end">
              <button className="mr-2 bg-thirdgray text-light px-4 py-2 rounded" onClick={() => setIsEditing(false)}>
                Cancel
              </button>
              <button className="bg-success text-light px-4 py-2 rounded" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {isAdding && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-light p-4 rounded shadow-md w-1/2 overflow-auto max-h-96">
            <h2 className="text-primarygray text-2xl font-bold">Add New Configuration</h2>
            <div className="mt-4 grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-primarygray">Cluster Name</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newConfiguration.cluster_name}
                  onChange={(e) => setNewConfiguration({ ...newConfiguration, cluster_name: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Sort</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newConfiguration.sort}
                  onChange={(e) => setNewConfiguration({ ...newConfiguration, sort: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Definition</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newConfiguration.definition}
                  onChange={(e) => setNewConfiguration({ ...newConfiguration, definition: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Cluster Group</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newConfiguration.cluster_group}
                  onChange={(e) => setNewConfiguration({ ...newConfiguration, cluster_group: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Brand</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newConfiguration.brand}
                  onChange={(e) => setNewConfiguration({ ...newConfiguration, brand: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Category</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newConfiguration.category}
                  onChange={(e) => setNewConfiguration({ ...newConfiguration, category: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Product</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newConfiguration.product}
                  onChange={(e) => setNewConfiguration({ ...newConfiguration, product: e.target.value })}
                />
              </div>
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Active</label>
              <input
                type="checkbox"
                className="mt-1"
                checked={newConfiguration.active}
                onChange={(e) => setNewConfiguration({ ...newConfiguration, active: e.target.checked })}
              />
            </div>
            <div className="mt-4 flex justify-end">
              <button className="mr-2 bg-thirdgray text-light px-4 py-2 rounded" onClick={() => setIsAdding(false)}>
                Cancel
              </button>
              <button className="bg-success text-light px-4 py-2 rounded" onClick={handleSaveNew}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Insights;
