interface ToggleSwitchProps {
  labels?: [string | null, string | null];
  checked: boolean;
  onChange: (
    isChecked: boolean,
    evt?: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

function ToggleSwitch({
  checked,
  onChange,
  labels = [null, null],
}: ToggleSwitchProps) {
  const [labelA, labelB] = labels;

  // JSX
  return (
    <div className="flex items-center space-x-2 text-sm">
      {labelA ? <span className="text-primarygray">{labelA}</span> : null}
      <label className="inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          name="toggle-switch"
          value=""
          checked={checked}
          onChange={(evt) => {
            onChange(evt.currentTarget.checked, evt);
          }}
          className="sr-only peer"
        />
        <div className="relative w-10 h-5 bg-lightblue rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
      </label>
      {labelB ? <span className="text-primarygray">{labelB}</span> : null}
    </div>
  );
}

export default ToggleSwitch;
