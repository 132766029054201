type TooltipPosistion =
  | 'top'
  | 'top-left'
  | 'top-right'
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right';

interface TooltipProps {
  content: React.ReactNode;
  className?: string;
  posistion?: TooltipPosistion;
}

function Tooltip({ content, className = '', posistion }: TooltipProps) {
  const bubblePosistion = () => {
    switch (posistion) {
      case 'top':
        return '-top-5 -translate-y-full left-1/2 -translate-x-1/2';

      case 'top-left':
        return '-top-5 left-0 -translate-y-full -translate-x-5';

      case 'top-right':
        return '-top-5 -translate-y-full right-0 translate-x-5';

      case 'bottom-left':
        return 'top-full left-0 -translate-x-4';

      case 'bottom-right':
        return 'top-full right-0 translate-x-4';

      // bottom
      default:
        return 'top-full left-1/2 -translate-x-1/2';
    }
  };

  const arrowPosistion = () => {
    switch (posistion) {
      case 'top':
        return 'left-1/2 -translate-x-1/2 -bottom-[7px]';

      case 'top-left':
        return 'left-5 -translate-x-1/2 -bottom-[7px]';

      case 'top-right':
        return 'right-0 translate-x-6 -bottom-[7px]';

      case 'bottom-left':
        return 'left-0 -translate-x-1/3 -top-[6px] rotate-180';

      case 'bottom-right':
        return 'right-0 translate-x-1/3 -top-[6px] rotate-180';

      // bottom
      default:
        return 'left-1/2 -translate-x-1/2 -top-[6px] rotate-180';
    }
  };

  // JSX
  return (
    <div className="relative ml-1 mb-2 group inline-block">
      <img
        src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
        alt="tooltip"
      />
      <span
        className={`absolute ${bubblePosistion()} mt-2 ml-1 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-10 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap`}
      >
        <div className={`px-4 py-2 ${className}`.trim()}>{content}</div>
        <img
          src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
          alt="arrow"
          className={`absolute ${arrowPosistion()}`}
        />
      </span>
    </div>
  );
}

export default Tooltip;
