import React, { FC } from 'react';

interface BrandData {
  label: string;
  color: string;
}

interface CustomLegendProps {
  brandsData: BrandData[];
  onLegendClick?: (label: string) => void;
  visibility?: Record<string, boolean>;
}

// Utility function to fade RGBA color
const fadeRgbaColor = (color: string, alpha: number) => {
  const rgba = color.replace(/[^\d,]/g, '').split(',');
  return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${alpha})`;
};

// Utility function to fade HEX color
const fadeHexColor = (color: string, alpha: number) => {
  const num = parseInt(color.replace("#", ""), 16);
  const r = (num >> 16) & 255;
  const g = (num >> 8) & 255;
  const b = num & 255;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// Main utility function to handle both HEX and RGBA
const fadeColor = (color: string, alpha: number) => {
  if (color.startsWith('#')) {
    return fadeHexColor(color, alpha);
  } else if (color.startsWith('rgba')) {
    return fadeRgbaColor(color, alpha);
  }
  return color;
};

const CustomLegend2: FC<CustomLegendProps> = ({ brandsData, onLegendClick, visibility }) => {
  return (
    <div className="flex flex-col items-start pl-2">
      {brandsData.slice().reverse().map((brand, index) => ( 
        <div key={index} className="flex items-center mb-2">
          <label className="flex items-center">
            <input
              type="checkbox"
              onChange={() => onLegendClick && onLegendClick(brand.label.replace(' ', ''))}
              checked={visibility ? visibility[brand.label.replace(' ', '')] : true}
              className="hidden"
              id={`checkbox-${index}`}
            />
            <span
              className="custom-checkbox min-w-4"
              style={{
                borderColor: brand.color,
                backgroundColor: visibility && visibility[brand.label.replace(' ', '')] 
                  ? brand.color 
                  : fadeColor(brand.color, 0.2)
              }}
            >
              {visibility && visibility[brand.label.replace(' ', '')] && (
                <img src={`${process.env.PUBLIC_URL}/static/img/checkbox.svg`} alt="Checked" className="checkmark" />
              )}
            </span>
            <span className="text-sm ml-2">{brand.label}</span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default CustomLegend2;
