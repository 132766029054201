import React, { useState, useEffect } from 'react';

interface RangeSliderProps {
  min: number;
  max: number;
  step: number;
  defaultValue: number;
  onChange: (value: number) => void;
  unit: string;
  defaultText: string;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  step,
  defaultValue,
  onChange,
  unit,
  defaultText,
}) => {
  const [rightValue, setRightValue] = useState<number>(defaultValue);

  const handleRightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setRightValue(value);
    onChange(value);
  };

  return (
    <div className='w-[90%] mb-2 flex items-center gap-3'>
        <div className="flex-grow flex items-center">
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={rightValue}
                onChange={handleRightChange}
                className='w-full custom-slider'
            />
        </div>
        <div className="flex-shrink-0 text-xs text-gray-500">
            <span>{rightValue}{unit} (Default: {defaultText})</span>
        </div>
    </div>
  );
};

export default RangeSlider;
