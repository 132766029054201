import React, { useState, useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import 'chart.js/auto';
import CustomLegend2 from './CustomLegend2';
import { downloadImage, downloadXLS } from 'utils/download';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface BrandData {
  label: string;
  data: number[];
  color: string;
}

interface BCStackedBarProps {
  product_association_with_brands_chart_data: {
    [key: string]: { Brand: string; total_demand: number }[];
  };
}

const colors = [
  'rgba(34, 34, 34, 0.80)',
  'rgba(89, 89, 89, 0.8)',
  'rgba(144, 144, 144, 0.8)',
  'rgba(50, 215, 111, 0.8)',
  'rgba(3, 197, 255, 0.8)',
  'rgba(84, 79, 197, 0.8)',
  'rgba(254, 106, 53, 0.8)',
  'rgba(107, 138, 188, 0.8)',
  'rgba(213, 104, 251, 0.8)',
  'rgba(47, 224, 202, 0.8)',
  'rgba(250, 75, 66, 0.8)',
  'rgba(254, 181, 106, 0.8)',
  'rgba(254, 181, 106, 0.8)'
];

const BCStackedBar: React.FC<BCStackedBarProps> = ({ product_association_with_brands_chart_data }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLegendExpanded, setIsLegendExpanded] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [brandsData, setBrandsData] = useState<BrandData[]>([]);
  const [visibility, setVisibility] = useState<Record<string, boolean>>({});
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const data = product_association_with_brands_chart_data;
    const formattedData: BrandData[] = [];

    let colorIndex = 0;
    for (const product in data) {
      if (product.toLowerCase() === 'uncategorized') continue;

      data[product].forEach(brandInfo => {
        const existingBrand = formattedData.find(b => b.label === brandInfo.Brand);
        if (existingBrand) {
          existingBrand.data.push(brandInfo.total_demand);
        } else {
          formattedData.push({
            label: brandInfo.Brand,
            data: [brandInfo.total_demand],
            color: colors[colorIndex % colors.length],
          });
          colorIndex++;
        }
      });

      formattedData.forEach(brand => {
        if (brand.data.length < Object.keys(data).filter(key => key.toLowerCase() !== 'uncategorized').length) {
          brand.data.push(0);
        }
      });
    }

    setBrandsData(formattedData);

    const top10Brands = formattedData.slice(0, 10);
    const initialVisibility = formattedData.reduce((acc: Record<string, boolean>, brand) => {
      const normalizedLabel = brand.label.replace(/ /g, '');
      if (top10Brands.includes(brand) && normalizedLabel !== 'uncategorized') {
        acc[normalizedLabel] = true;
      }
      else{
        acc[normalizedLabel] = false;
      }

      return acc;
    }, {});
    setVisibility(initialVisibility);
  }, [product_association_with_brands_chart_data]);

  const calculateBarThickness = (chartWidth: number, numberOfLabels: number) => {
    return Math.max(10, chartWidth / (numberOfLabels * 2));
  };  

  const handleLegendClick = (label: string) => {
    setVisibility((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

const chartWidth = chartRef.current ? chartRef.current.offsetWidth : 800;
const barThickness = calculateBarThickness(chartWidth, Object.keys(product_association_with_brands_chart_data).filter(key => key.toLowerCase() !== 'uncategorized').length);

  const data: ChartData<'bar'> = {
    labels: Object.keys(product_association_with_brands_chart_data).filter(key => key.toLowerCase() !== 'uncategorized'),
    datasets: brandsData.map((brand) => ({
      label: brand.label,
      data: brand.data,
      backgroundColor: brand.color,
      borderWidth: 0,
      barThickness: barThickness,
      borderRadius: 8,
      hidden: !visibility[brand.label.replace(' ', '')],
    })),
  };

  const formatYAxisTick = (tickValue: number) => {
    if (tickValue >= 1_000_000) {
      return `${(tickValue / 1_000_000).toFixed(1)}M`;
    } else if (tickValue >= 1_000) {
      return `${(tickValue / 1_000).toFixed(1)}K`;
    } else {
      return tickValue.toString();
    }
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Product',
          color: '#4D4D4D',
          font: {
            size: 14,
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        grid: {
          color: '#B3B3B3',
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return formatYAxisTick(tickValue);
            }
            return tickValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const visibleBrands = isLegendExpanded ? brandsData : brandsData.slice(0, 8);

  const downloadChartData = () => {
    const headers = ['Product', ...brandsData.map((brand) => brand.label)];
    const labels = Object.keys(product_association_with_brands_chart_data).filter(key => key.toLowerCase() !== 'uncategorized');

    const columns = brandsData.map((brand) => {
      return labels.map((label, index) => brand.data[index] || 0);
    });

    downloadXLS('Brand_Association_Per_Product', headers, labels, columns);
  };

  const downloadChartImage = () => {
    downloadImage('Brand_Association_per_product');
  };

  return (
    <div id="Brand_Association_per_product" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">Which brands are associated with each product?</h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">Understand how consumers associate products and brands</h3>
        </div>
        <div className='flex items-center' ref={dropdownRef} id='downloadDropdownMenu-Brand_Association_per_product'>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50" style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}>
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartData();
                      closeDropdown();
                    }}
                >
                    Download XLS
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex relative">
        <div ref={chartRef} className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]">
          <Bar data={data} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <CustomLegend2 brandsData={visibleBrands} onLegendClick={handleLegendClick} visibility={visibility} />
          <button
            onClick={() => setIsLegendExpanded(!isLegendExpanded)}
            className="mt-2 bg-light hover:underline"
          >
            {isLegendExpanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BCStackedBar;
