import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Plugin, Chart, ArcElement, ChartTypeRegistry, TooltipItem } from 'chart.js';
import CustomLegend2 from './CustomLegend2';
import { downloadImage, downloadXLS } from 'utils/download';

interface DoughnutChartPlugin extends Plugin<'doughnut'> {}

const ChartDataLabelsPlugin = ChartDataLabels as unknown as DoughnutChartPlugin;

const colors = [
  'rgba(34, 34, 34, 0.80)',
  'rgba(89, 89, 89, 0.8)',
  'rgba(144, 144, 144, 0.8)',
  'rgba(50, 215, 111, 0.8)',
  'rgba(3, 197, 255, 0.8)',
  'rgba(84, 79, 197, 0.8)',
  'rgba(254, 106, 53, 0.8)',
  'rgba(107, 138, 188, 0.8)',
  'rgba(213, 104, 251, 0.8)',
  'rgba(47, 224, 202, 0.8)',
  'rgba(250, 75, 66, 0.8)',
  'rgba(254, 181, 106, 0.8)',
  'rgba(254, 181, 106, 0.8)',
];

interface DemandFProps {
  data: {
    data: {
      Feature: string;
      feature_total_demand: number;
      demand_percentage: number;
    }[];
  };
}

const DemandF: React.FC<DemandFProps> = ({ data }) => {
  const labels = useMemo(
    () => data.data.map((item) => item.Feature),
    [data.data]
  );
  const chartData = useMemo(
    () => data.data.map((item) => item.demand_percentage),
    [data.data]
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [visibility, setVisibility] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const initialVisibility = labels.reduce(
      (acc: Record<string, boolean>, label) => {
        const normalizedLabel = label.replace(/ /g, '');
        acc[normalizedLabel] = normalizedLabel !== 'uncategorized';
        return acc;
      },
      {}
    );

    setVisibility(initialVisibility);
  }, [labels]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      extendedGridLinePlugin: false,
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function(context: TooltipItem<'doughnut'>) {
            let label = context.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed !== null) {
              label += context.parsed.toFixed(2) + '%';
            }
            return label;
          },
        },
      },
      datalabels: {
        anchor: 'end' as const,
        align: 'end' as const,
        color: '#1A1A1A',
        display: 'auto',
        font: {
          weight: 'normal' as const,
          size: 12,
        },
        formatter: (value: number) => {
          const places = value < 1 ? 1 : 0;
          return `${value.toFixed(places)}%`;
        },
        padding: {
          top: 2,
          bottom: 2,
        },
        offset: 16,
      },
    },
    cutout: '50%',
    layout: {
      padding: {
        top: 35,
        bottom: 35,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  const afterDraw = (chart: Chart<keyof ChartTypeRegistry>) => {
    const ctx = chart.ctx;
    const lineLength = 18.78; // Length of the lines

    if (chart.data.labels) {
      chart.data.datasets.forEach((dataset, datasetIndex) => {
        chart.getDatasetMeta(datasetIndex).data.forEach((element) => {
          const arcElement = element as unknown as ArcElement;
          const radius = arcElement.outerRadius;

          // Calculate the angle for the line
          const angle =
            arcElement.startAngle +
            (arcElement.endAngle - arcElement.startAngle) / 2;
          const startX = chart.width / 2 + radius * Math.cos(angle);
          const startY = chart.height / 2 + radius * Math.sin(angle);
          const endX = startX + lineLength * Math.cos(angle);
          const endY = startY + lineLength * Math.sin(angle);

          // Draw the line from the outer edge of the doughnut
          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.lineTo(endX, endY);
          ctx.strokeStyle = '#333';
          ctx.stroke();
        });
      });
    }
  };

  const afterDrawPlugin: Plugin<'doughnut'> = {
    id: 'afterDrawPlugin',
    afterDraw: (chart) => afterDraw(chart as Chart<keyof ChartTypeRegistry>),
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const DoughnutIcon = ({ color }: { color: string }) => (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ stroke: color }}
    >
      <path
        d="M6 6H10"
        strokeOpacity="0.8"
        strokeWidth="12"
        strokeLinecap="round"
      />
    </svg>
  );

  const legends = labels.map((label, index) => ({
    label,
    color: colors[index % colors.length],
    icon: <DoughnutIcon color={colors[index % colors.length]} />,
  }));

  const handleLegendClick = (label: string) => {
    setVisibility((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  const filteredData = chartData.map((value, index) =>
    visibility[labels[index].replace(/ /g, '')] ? value : 0
  );

  const downloadChartData = () => {
    const headers = ['Feature', 'Demand Percentage'];
    const fileName = 'demand_by_feature';
    const columns = [chartData];

    downloadXLS(fileName, headers, labels, columns);
  };

  const downloadChartImage = () => {
    downloadImage('Demand_by_Feature');
  };

  return (
    <div id="Demand_by_Feature" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">
            Demand by Feature
            <div className="relative ml-1 mb-2 group inline-block">
              <img
                src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
                alt="tooltip"
                className="tooltip-image"
              />
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-[242px] h-12 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300">
                Feature is a distinctive attribute or aspect for your category
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                  alt="arrow"
                  className="absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]"
                />
              </span>
            </div>
          </h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">
            How consumers search for feature of your products
          </h3>
        </div>
        <div
          className="flex items-center"
          ref={dropdownRef}
          id="downloadDropdownMenu-Demand_by_Feature"
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
              style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <ul>
                {/* <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={closeDropdown}
                >
                  View data table
                </li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartData();
                    closeDropdown();
                  }}
                >
                  Download XLS
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]">
          <Doughnut
            data={{
              labels: labels,
              datasets: [
                {
                  data: filteredData,
                  backgroundColor: colors.slice(0, chartData.length),
                  hoverBackgroundColor: colors.slice(0, chartData.length),
                  borderWidth: 1,
                },
              ],
            }}
            options={options}
            plugins={[ChartDataLabelsPlugin, afterDrawPlugin]}
          />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <CustomLegend2
            brandsData={legends}
            onLegendClick={handleLegendClick}
            visibility={visibility}
          />
        </div>
      </div>
    </div>
  );
};

export default DemandF;
