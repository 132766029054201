import { atom, useAtom } from 'jotai';

export type CurrentClient = {
  current_client: string; // "American Girl - US",
  all_combinations: [string, string][];
  cleared_options: string[];
  platforms: string; // "0, 3, 1, 2, 4"
  current_client_market: string;
};

const DEFAULT_CLIENT: CurrentClient = {
  current_client: '',
  all_combinations: [],
  cleared_options: [],
  platforms: '',
  current_client_market: '',
};

const currentClientAtom = atom(DEFAULT_CLIENT);

export const useCurrentClientAtom = () => useAtom(currentClientAtom);
