import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

const TIME_FRAME_OPTIONS = ['49M', '12M', '1M', '7D', '24H'] as const;

// TYPES
export type TimeFrameOption = (typeof TIME_FRAME_OPTIONS)[number];
export type TimeFrameDateRange = {
  startDate: Date | null;
  endDate: Date | null;
};
interface TimeFramePickerProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value?: TimeFrameOption | null;
  defaultValue?: TimeFrameOption;
  onChange: (newDates: TimeFrameDateRange, timeFrame?: TimeFrameOption) => void;
  timeframeOptions?: TimeFrameOption[] | readonly TimeFrameOption[];
}

// SET TIME FRAME BUTTON CLASS
const buttonClasses = (isSelected: boolean) =>
  `px-4 py-[9px] rounded text-sm ${
    isSelected ? 'bg-light text-primarygray' : 'text-gray400'
  }`;

// CONVERT SELECTED TIME FRAME INTO DATE RANGE (START & END DATES)
const getDateRangeForTimeframe = (timeframe: TimeFrameOption) => {
  const endDate = new Date();
  let startDate: Date | null = null;

  switch (timeframe) {
    case '49M':
      startDate = moment(endDate).subtract(49, 'months').toDate();
      break;

    case '12M':
      startDate = moment(endDate).subtract(12, 'months').toDate();
      break;

    case '1M':
      startDate = moment(endDate).subtract(1, 'months').toDate();
      break;

    case '7D':
      startDate = moment(endDate).subtract(6, 'days').toDate();
      break;

    case '24H':
      startDate = moment(endDate).subtract(24, 'hours').toDate();
      break;

    default:
      startDate = moment(endDate).subtract(6, 'days').toDate();
  }

  return {
    startDate,
    endDate,
  };
};

// TIME FRAME PICKER COMPONENT
function TimeFramePicker({
  value,
  onChange,
  defaultValue,
  timeframeOptions = ['12M', '1M', '7D', '24H'],
  ...props
}: TimeFramePickerProps) {
  const [selected, setSelected] = useState<TimeFrameOption | null>(
    timeframeOptions[0]
  );

  // SET DEFAULT VALUE
  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  // SET OPTIONAL VALUE FOR CONTROLLED COMPONENT
  useEffect(() => {
    if (value === undefined) return;
    setSelected(value);
  }, [value]);

  // HANDLE TIME FRAME SELECTION
  const onSelectTimeFrame = useCallback(
    (timeFrame: TimeFrameOption) => {
      setSelected(timeFrame);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelected]
  );

  // HANDLE TIMEFRAME CHANGES
  useEffect(() => {
    if (!selected) return;
    const newDates = getDateRangeForTimeframe(selected);

    onChange(newDates, selected);
  }, [selected, onSelectTimeFrame]);

  // RENDER TIME FRAME OPTIONS
  const renderedOptions = useMemo(
    () =>
      timeframeOptions.map((option) => (
        <li key={option}>
          <button
            className={buttonClasses(option === selected)}
            onClick={() => onSelectTimeFrame(option)}
          >
            {option}
          </button>
        </li>
      )),
    [selected, onSelectTimeFrame]
  );

  // JSX
  return (
    <div className="space-x-2 bg-fourthgray py-1 px-1 rounded-lg" {...props}>
      <ul className="flex flex-wrap">{renderedOptions}</ul>
    </div>
  );
}

export default TimeFramePicker;
