import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import LoadingSpinner from '../LoadingSpinner';

interface Vertical {
  id: number;
  config_id: number;
  dimension_name: string;
  name: string;
  search_terms: string;
}

interface CustomerInfo {
  customer_id: string;
  agency: string;
  client: string;
  line_of_business: string;
  market: string;
  region: string;
  platform: string[];
}

interface ConsumerData {
  customer_info: CustomerInfo;
  verticals: Vertical[];
}

interface TableProps<T> {
  data: T[];
  toggleView?: (id: number) => void;
  columns: (keyof T)[];
  onEdit?: (item: T) => void;
  onDelete?: (id: number) => void;
  onAdd?: () => void;
}

function formatHeader(header: string): string {
  // Replace underscores with spaces
  let formatted = header.replace(/_/g, ' ');

  // Add spaces before each capital letter
  formatted = formatted.replace(/([A-Z])/g, ' $1');

  // Convert the entire string to uppercase
  return formatted.toUpperCase();
}

function formatSearchTerms(terms: string): string {
  return terms.replace(/[[\]']/g, '');
}

function Table<T extends { id?: number; isActive?: boolean }>({
  data,
  toggleView,
  columns,
  onEdit,
  onDelete,
  onAdd,
  isTransposeTable,
}: TableProps<T> & { isTransposeTable?: boolean }) {
  return (
    <div className="overflow-auto max-h-96">
      <table className="min-w-full bg-light table-auto">
        <thead className="text-primarygray text-xs font-normal">
          <tr>
            {columns.map((column, index) => (
              <th key={index} className="py-2 px-4 text-left">
                <div className="flex items-center">
                  {isTransposeTable ? String(column).toUpperCase() : formatHeader(column as string)}
                  <img src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`} alt="sort-icon" className="ml-2" />
                </div>
              </th>
            ))}
            {!isTransposeTable && <th className="py-2 px-2 text-right">ACTIONS</th>}
          </tr>
        </thead>
        <tbody className="text-primarygray text-xs font-normal rounded-md">
          {data.map((item, index) => (
            <tr key={item.id || index} className={index % 2 === 0 ? 'bg-gray50' : 'bg-gray100'}>
              {columns.map((column, colIndex) => (
                <td key={colIndex} className="py-2 px-4">
                  {isTransposeTable ? (item[column] as string) : column === 'search_terms' ? formatSearchTerms(String(item[column])) : String(item[column])}
                </td>
              ))}
              {/* #!TODO:MISSING toggle view */}
              {!isTransposeTable && (
                <td className="py-2 px-2 flex justify-end">
                  {toggleView && item.id && (
                    <label className="inline-flex items-center cursor-pointer mr-2">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        checked={item.isActive}
                        onChange={() => toggleView(item.id!)}
                      />
                      <div className="relative w-10 h-5 bg-gray200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
                    </label>
                  )}
                  {onEdit && item.id && (
                    <button onClick={() => onEdit(item)}>
                      <img
                        src={`${process.env.PUBLIC_URL}/static/img/edit-03.svg`}
                        alt="edit"
                        className="h-4 w-4 mr-2"
                      />
                    </button>
                  )}
                  {onDelete && item.id && (
                    <button onClick={() => onDelete(item.id!)}>
                      <img
                        src={`${process.env.PUBLIC_URL}/static/img/delete_option.svg`}
                        alt="delete"
                        className="h-4 w-4"
                      />
                    </button>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {onAdd && !isTransposeTable && (
        <div className="border border-thirdgray rounded-[20px] px-1 mt-2 w-[68px]">
          <button className="text-sm text-thirdgray font-bold flex items-center" onClick={onAdd}>
            <img
              src={`${process.env.PUBLIC_URL}/static/img/plus_gray.svg`}
              alt="plus_icon"
              className="w-[18px] h-[18px] mr-1"
            />
            ADD
          </button>
        </div>
      )}
    </div>
  );
}

interface ConsumerIntProps {
  customerId: string;
  enabled: boolean;
}

const fetchConsumerData = async (customerId: string) => {
  const response = await fetch('/consumer-intent-verticals/');
  const data: ConsumerData[] = await response.json();
  const filteredData = data.find(d => d.customer_info.customer_id === customerId);
  return filteredData || null;
};

const updateVertical = async (vertical: Vertical) => {
  
  const response = await fetch(`/consumer-intent-verticals/${vertical.id}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(vertical),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const deleteVertical = async (verticalId: number) => {


  const response = await fetch(`/consumer-intent-verticals/${verticalId}/delete/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const createVertical = async (vertical: Vertical) => {

  try {
    const response = await fetch('/consumer-intent-verticals/create/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vertical),
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`Add new vertical failed: ${errorText}`);
    }

    return response.json();

  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

const uploadFile = async ({ ConsumerIntCFG, customerId }: { ConsumerIntCFG: File; customerId: string }) => {

  const formData = new FormData();
  formData.append('file', ConsumerIntCFG);
  formData.append('product', 'consumer_int');

  if (customerId) {
    formData.append('customer_id', customerId);
  }

  try {
    const response = await fetch('/upload/', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`File upload failed: ${errorText}`);
      return null;
    }

    return response.json();

  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

function ConsumerInt({ customerId, enabled }: ConsumerIntProps) {
  const queryClient = useQueryClient();
  const { data, isLoading, isError, refetch } = useQuery(['consumerData', customerId], () => fetchConsumerData(customerId), {
  });
  const [ConsumerIntCFG, setConsumerIntCFG] = useState<File | null>(null);

  const [editableItem, setEditableItem] = useState<Vertical | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [newVertical, setNewVertical] = useState<Vertical>({
    id: 0,
    config_id: 0,
    dimension_name: '',
    name: '',
    search_terms: '',
  });

  const mutation = useMutation(updateVertical, {
    onSuccess: () => {
      queryClient.invalidateQueries(['consumerData', customerId]);
      setIsEditing(false);
    },
  });


  const addMutation = useMutation(createVertical, {
    onSuccess: () => {
      queryClient.invalidateQueries(['consumerData', customerId]);
      setIsAdding(false);
    },
  });

  const deleteMutation = useMutation(deleteVertical, {
    onSuccess: () => {
      queryClient.invalidateQueries(['consumerData', customerId]);
    },
  });

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      refetch();
    }
  }, [deleteMutation.isSuccess, refetch]);

  const handleEdit = (item: Vertical) => {
    setEditableItem(item);
    setIsEditing(true);
  };

  const handleSave = () => {
    if (editableItem) {
      mutation.mutate(editableItem);
    }
  };

  const handleAdd = (configId: number, dimensionName: string) => {
    setNewVertical({
      id: 0,
      config_id: configId,
      dimension_name: dimensionName,
      name: '',
      search_terms: '',
    });
    setIsAdding(true);
  };

  const handleSaveNew = () => {
    addMutation.mutate(newVertical);
  };

  const handleDelete = (verticalId: number) => {
    const userConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (userConfirmed) {
      deleteMutation.mutate(verticalId);
    }
  };

  const uploadMutation = useMutation(uploadFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['consumerData', customerId]);
      setConsumerIntCFG(null);
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setConsumerIntCFG(event.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (ConsumerIntCFG) {
      const userConfirmed = window.confirm("Are you sure you want to upload this file?");
      
      if (userConfirmed) {
        await uploadMutation.mutateAsync({ ConsumerIntCFG, customerId });
        setConsumerIntCFG(null);
        const fileInput = document.getElementById('ConsumerIntCFG') as HTMLInputElement | null;
        if (fileInput) {
          fileInput.value = '';
        }
      }
    } else {
      alert("No file selected.");
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <div>Failed to fetch data</div>;
  }

  if (!data) {
    return (
      enabled ? (
        <div className="bg-light">
          <h2 className="text-primarygray text-2xl font-bold">Consumer Intent</h2>
          <div className="flex items-center mt-6 grid-cols-2">
            <div className="border border-thirdgray rounded-md px-6">
              <label htmlFor="ConsumerIntCFG" className="cursor-pointer text-sm font-bold flex items-center text-thirdgray">
                {ConsumerIntCFG ? ConsumerIntCFG.name : `consumer_intent_${customerId}`}
              </label>
              <input
                type="file"
                id="ConsumerIntCFG"
                className="hidden"
                onChange={handleFileChange}
                accept=".xlsx"
              />
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/static/img/refresh.svg`}
              alt="refresh-icon"
              className="ml-2 cursor-pointer"
              onClick={handleFileUpload}
            />
          </div>
        </div>
      ): null
    );
  }

    // Grouping by dimension_name #TODO Relationships table
  const dimensionGroups = data.verticals.reduce((acc, vertical) => {
    if (vertical.dimension_name !== 'Relationships' && vertical.dimension_name !== 'translation_list' && vertical.dimension_name !== 'Seed Keywords') {
      if (!acc[vertical.dimension_name]) {
        acc[vertical.dimension_name] = [];
      }
      acc[vertical.dimension_name].push(vertical);
    }
    return acc;
  }, {} as Record<string, Vertical[]>);

  function formatSearchTermsRE(terms: string): string[] {
    return terms
      .split(',')                              // Split by commas
      .map(term => term.replace(/[[\]']/g, '').trim())  // Remove [ ] and ' 
      .filter(term => term !== '');            // Remove any remaining empty strings
  }

  const relationshipsData = data.verticals.filter(vertical => vertical.dimension_name === 'Relationships');

  // Extract relationship names and corresponding search terms (split by commas)
  const relationshipNames = relationshipsData.map(vertical => vertical.name);
  const searchTermsList = relationshipsData.map(vertical => formatSearchTermsRE(vertical.search_terms));

  // Determine the maximum number of rows based on the longest search terms list
  const maxRows = Math.max(...searchTermsList.map(terms => terms.length));

  const translationListData = data.verticals.filter(vertical => vertical.dimension_name === 'translation_list');
  const seedKeywordsData = data.verticals.filter(vertical => vertical.dimension_name === 'Seed Keywords');

  const translationNames = translationListData.map(vertical => vertical.name);
  const translationSearchTermsList = translationListData.map(vertical => formatSearchTermsRE(vertical.search_terms));
  const maxTranslationRows = Math.max(...translationSearchTermsList.map(terms => terms.length));

  const seedKeywordNames = seedKeywordsData.map(vertical => vertical.name);
  const seedKeywordSearchTermsList = seedKeywordsData.map(vertical => formatSearchTermsRE(vertical.search_terms));
  const maxSeedKeywordRows = Math.max(...seedKeywordSearchTermsList.map(terms => terms.length));


  const handleExportConsumerIntent = (customerId: string) => {

    const url = `/export-consumer-intent/${customerId}/`;
  
    fetch(url, {
      method: 'GET',
    })
    .then(response => {
      if (response.ok) {
        return response.blob();
      } else {
        throw new Error('Failed to download the file');
      }
    })
    .then(blob => {
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `consumer_intent_verticals_${customerId}.xlsx`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };
  
  return (
    <div className="bg-light">
      <h2 className="text-primarygray text-2xl font-bold">Consumer Intent</h2>
      <div className="flex items-center mt-6 grid-cols-2">
        <div className="border border-thirdgray rounded-md px-6">
        <label htmlFor="ConsumerIntCFG" className="cursor-pointer text-sm font-bold flex items-center text-thirdgray">
          {ConsumerIntCFG ? ConsumerIntCFG.name : `consumer_intent_${customerId}`}
        </label>
          <input
            type="file"
            id="ConsumerIntCFG"
            className="hidden"
            onChange={handleFileChange}
            accept=".xlsx"
          />
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/static/img/download_green.svg`}
          alt="download_green-icon"
          className="ml-2 cursor-pointer"
          onClick={() => handleExportConsumerIntent(customerId)}
        />
        <img
          src={`${process.env.PUBLIC_URL}/static/img/refresh.svg`}
          alt="refresh-icon"
          className="ml-2 cursor-pointer"
          onClick={handleFileUpload}
        />
      </div>

      {Object.entries(dimensionGroups).map(([dimensionName, items]) => (
        <div key={dimensionName}>
          <h2 className="text-primarygray text-sm font-bold mt-6">{dimensionName}</h2>
          <div className="overflow-x-auto mt-4">
            <Table data={items} columns={['name', 'search_terms']} onEdit={handleEdit} onDelete={handleDelete} onAdd={() => handleAdd(items[0].config_id, dimensionName)} />
          </div>
        </div>
      ))}

      {relationshipsData.length > 0 && maxRows > 0 && (
        <>
          <h2 className="text-primarygray text-sm font-bold mt-6 mb-4">Relationships</h2>
          <Table
            data={Array.from({ length: maxRows }, (_, rowIndex) =>
              relationshipNames.reduce((acc, name, colIndex) => {
                acc[name] = searchTermsList[colIndex][rowIndex] || '';
                return acc;
              }, {} as Record<string, string>)
            )}
            columns={relationshipNames}
            isTransposeTable
          />
        </>
      )}

      {translationListData.length > 0 &&  maxTranslationRows > 0 && (
        <>
          <h2 className="text-primarygray text-sm font-bold mt-6 mb-4">Translation List</h2>
          <Table
            data={Array.from({ length: maxTranslationRows }, (_, rowIndex) =>
              translationNames.reduce((acc, name, colIndex) => {
                acc[name] = translationSearchTermsList[colIndex][rowIndex] || '';
                return acc;
              }, {} as Record<string, string>)
            )}
            columns={translationNames}
            isTransposeTable
          />
        </>
      )}

      {seedKeywordsData.length > 0 && maxSeedKeywordRows > 0 && (
        <>
          <h2 className="text-primarygray text-sm font-bold mt-6 mb-4">Seed Keywords</h2>
          <Table
            data={Array.from({ length: maxSeedKeywordRows }, (_, rowIndex) =>
              seedKeywordNames.reduce((acc, name, colIndex) => {
                acc[name] = seedKeywordSearchTermsList[colIndex][rowIndex] || '';
                return acc;
              }, {} as Record<string, string>)
            )}
            columns={seedKeywordNames}
            isTransposeTable
          />
        </>
      )}


      {isEditing && editableItem && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-light p-4 rounded shadow-md w-1/2 overflow-auto max-h-96">
            <h2 className="text-primarygray text-2xl font-bold">Edit Vertical</h2>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Name</label>
              <input
                type="text"
                className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                value={editableItem.name}
                onChange={(e) => setEditableItem({ ...editableItem, name: e.target.value })}
              />
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Search Terms</label>
              <textarea
                rows={5}
                className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                value={editableItem.search_terms}
                onChange={(e) => setEditableItem({ ...editableItem, search_terms: e.target.value })}
              />
            </div>
            <div className="mt-4 flex justify-end">
              <button className="mr-2 bg-thirdgray text-light px-4 py-2 rounded" onClick={() => setIsEditing(false)}>
                Cancel
              </button>
              <button className="bg-success text-light px-4 py-2 rounded" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {isAdding && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-light p-4 rounded shadow-md w-1/2 overflow-auto max-h-96">
            <h2 className="text-primarygray text-2xl font-bold">Add New Vertical</h2>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Name</label>
              <input
                type="text"
                className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                value={newVertical.name}
                onChange={(e) => setNewVertical({ ...newVertical, name: e.target.value })}
              />
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-primarygray">Search Terms</label>
              <textarea
                rows={5}
                className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                value={newVertical.search_terms}
                onChange={(e) => setNewVertical({ ...newVertical, search_terms: e.target.value })}
              />
            </div>
            <div className="mt-4 flex justify-end">
              <button className="mr-2 bg-thirdgray text-light px-4 py-2 rounded" onClick={() => setIsAdding(false)}>
                Cancel
              </button>
              <button className="bg-success text-light px-4 py-2 rounded" onClick={handleSaveNew}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConsumerInt;
