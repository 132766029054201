import axios from 'axios';
import moment from 'moment';

// TYPES
import {
  POSTQueryViewData,
  POSTQueryViewReqBody,
  QueryViewData,
  QueryViewDataFilters,
} from '../../types';
import { ColumnSort, PaginationState } from '../../components/DataTable';
import { TimeFrameDateRange } from '../../components/TimeFramePicker';

type FilterValues = {
  unique_verticals: string[];
  unique_products: string[];
};

// UTILs
const replaceSlashes = (items: string[]): string[] => {
  return items.map((item) => item.replace(/\//g, '%'));
};

const getFormattedDates = (dateRange: TimeFrameDateRange | null) => {
  if (!dateRange || !dateRange.endDate || !dateRange.startDate) {
    throw new Error('Start and end dates are required.');
  }

  const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');

  return { endDate, startDate };
};

// FETCH FILTERS
export const fetchFilterValues = async (
  startDate: string,
  endDate: string
): Promise<FilterValues> => {
  const response = await fetch(`/get_filter_values/${startDate}/${endDate}/`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
};

// FETCH OVERVIEW DATA
export const fetchConsumerIntData = async (
  dateRange: TimeFrameDateRange | null,
  verticals: string[],
  products: string[]
) => {
  const { startDate, endDate } = getFormattedDates(dateRange);

  const response = await fetch(
    `/get_consumer_int_data/${startDate}/${endDate}/${replaceSlashes(
      verticals
    ).join(',')}/${replaceSlashes(products).join(',')}/`
  );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
};

// FETCH PRODUCTS & FEATURE ANALYSIS DATA
export const fetchConsumerIntPFAData = async (
  dateRange: TimeFrameDateRange | null,
  verticals: string[],
  products: string[]
) => {
  const { startDate, endDate } = getFormattedDates(dateRange);

  const response = await fetch(
    `/get_consumer_int_pfa_data/${startDate}/${endDate}/${replaceSlashes(
      verticals
    ).join(',')}/${replaceSlashes(products).join(',')}/`
  );

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
};

// FETCH RETAILER COMPARISON DATA
export const fetchConsumerIntRCData = async (
  dateRange: TimeFrameDateRange | null,
  verticals: string[],
  products: string[]
) => {
  const { startDate, endDate } = getFormattedDates(dateRange);

  const response = await fetch(
    `/get_consumer_int_ro_data/${startDate}/${endDate}/${replaceSlashes(
      verticals
    ).join(',')}/${replaceSlashes(products).join(',')}/`
  );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
};

// FETCH BRAND COMPARISON DATA
export const fetchConsumerIntBCData = async (
  dateRange: TimeFrameDateRange | null,
  verticals: string[],
  products: string[]
) => {
  const { startDate, endDate } = getFormattedDates(dateRange);

  const response = await fetch(
    `/get_consumer_int_bo_data/${startDate}/${endDate}/${replaceSlashes(
      verticals
    ).join(',')}/${replaceSlashes(products).join(',')}/`
  );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
};

const getQueryViewReqBody = (
  dateRange: TimeFrameDateRange,
  filters: QueryViewDataFilters,
  tableState: {
    pagination: PaginationState;
    columnSort: ColumnSort<QueryViewData>;
  }
) => {
  const { startDate, endDate } = getFormattedDates(dateRange);

  const body: POSTQueryViewReqBody = {
    date_from: startDate,
    date_to: endDate,
    verticals: 'all',
    products: 'all',
    queries: 'all',
    brand: 'all',
    feature_group: 'all',
    mindset: 'all',
    page: tableState.pagination.page || 1,
    page_size: tableState.pagination.page_size || 50,
    sort_column: tableState.columnSort.sort_column,
    sort_direction: tableState.columnSort.sort_direction?.toUpperCase(),
  };

  for (const [key, value] of Object.entries(filters)) {
    if (value?.length) {
      body[key as keyof QueryViewDataFilters] = value.join(',');
    }
  }

  return body;
};

// FETCH QUERY VIEW DATA
export const fetchIntentQueryViewData = async (
  dateRange: TimeFrameDateRange,
  filters: QueryViewDataFilters,
  tableState: {
    pagination: PaginationState;
    columnSort: ColumnSort<QueryViewData>;
  }
) => {
  const body = getQueryViewReqBody(dateRange, filters, tableState);

  const { data } = await axios.post<POSTQueryViewData>(
    '/get_consumer_int_qv_data/',
    body
  );

  return data;
};
