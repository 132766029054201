import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// API REQUESTS
import { useQuery } from 'react-query';
import { fetchFilterValues } from 'api';

// CONSTANTS
import { APP_ROUTES } from '../constants';

// COMPONENTS
import NavTabs from './NavTabs';
import ActionBar from './FilterBarWrapper';
import MultiDropdown from './MultiDropdown';
import OutletSpinner from './OutletSpinner';
import DatepickerComponent from './DatepickerComponent';
import TimeFramePicker, { TimeFrameDateRange } from './TimeFramePicker';

// TYPES
import { DateValueType } from 'react-tailwindcss-datepicker';

// CONSUMER INTENT PAGE TAB OPTIONS
const INTENT_TABS = [
  { label: 'Overview', value: APP_ROUTES.CON_INTENT_OVERVIEW },
  { label: 'Product & Feature Analysis', value: APP_ROUTES.CON_INTENT_PFA },
  { label: 'Retailer Comparison', value: APP_ROUTES.CON_INTENT_RETAILER_COMP },
  { label: 'Brand Comparison', value: APP_ROUTES.CON_INTENT_BRAND_COMP },
  { label: 'Query View', value: APP_ROUTES.CON_INTENT_QUERY_VIEW },
] as const;

// TIME FRAME SELECTOR OPTIONS
const TIME_FRAME_OPTIONS = ['49M', '12M'] as const;
type TimeFrame = (typeof TIME_FRAME_OPTIONS)[number] | null;

// CONSUMER INTENT (MAIN PAGE)
function ConsumerIntPage() {
  const { pathname } = useLocation();
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedVerticals, setSelectedVerticals] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<TimeFrameDateRange | null>(null);
  const [timeFrame, setTimeFrame] = useState<TimeFrame>(TIME_FRAME_OPTIONS[0]);

  const [options, setOptions] = useState<{
    verticals: string[];
    products: string[];
  }>({
    verticals: [],
    products: [],
  });

  // FETCH FILTER VALUES
  const { isLoading } = useQuery({
    queryKey: [
      'filterValues',
      dateRange?.endDate?.toDateString(),
      dateRange?.endDate?.toDateString(),
    ],
    queryFn: () =>
      fetchFilterValues(
        moment(dateRange?.startDate).format('YYYY-MM-DD'),
        moment(dateRange?.endDate).format('YYYY-MM-DD')
      ),
    enabled: !!dateRange?.startDate && !!dateRange.endDate,
    onSuccess: ({ unique_products = [], unique_verticals = [] }) => {
      setOptions({
        verticals: [...unique_verticals],
        products: [...unique_products],
      });

      setSelectedProducts((prev) =>
        prev.length ? prev : [...unique_products]
      );
      setSelectedVerticals((prev) =>
        prev.length ? prev : [...unique_verticals]
      );
    },
  });

  // HANDLE TIME FRAME SELECTION
  const onChangeTimeFrame = (timeFrame: TimeFrameDateRange | null) => {
    setDateRange(timeFrame);
  };

  // HANDLE DATE PICKER SELECTIONS
  const onChangeDatePicker = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      setDateRange({
        startDate: new Date(newValue.startDate),
        endDate: new Date(newValue.endDate),
      });
      setTimeFrame(null);
    }
  };

  const context = useMemo(
    () => ({
      dateRange,
      selectedProducts,
      selectedVerticals,
    }),
    [selectedProducts, selectedVerticals, dateRange]
  );

  const onChangeTab = () => {
    setSelectedProducts([...options.products]);
    setSelectedVerticals([...options.verticals]);
  };

  useEffect(() => {
    setTimeFrame(TIME_FRAME_OPTIONS[0]);
  }, [pathname]);

  const placeHolders = useMemo(
    () => ({
      products:
        pathname === APP_ROUTES.CON_INTENT_QUERY_VIEW
          ? 'Verticals'
          : 'Products',
      features:
        pathname === APP_ROUTES.CON_INTENT_QUERY_VIEW ? 'Products' : 'Features',
    }),
    [pathname]
  );

  // JSX
  return (
    <NavTabs options={INTENT_TABS} onChange={onChangeTab}>
      <div className="py-12">
        {/* --- ACTION BAR --- */}
        <ActionBar subText="Data updates monthly, around the 20th">
          <TimeFramePicker
            onChange={onChangeTimeFrame}
            defaultValue="49M"
            value={timeFrame}
            timeframeOptions={TIME_FRAME_OPTIONS}
          />
          <div className="flex-grow"></div>

          <MultiDropdown
            placeholder={placeHolders.products}
            options={options.verticals}
            preselectedOptions={selectedVerticals}
            onSelectionChange={setSelectedVerticals}
            dropdownLength="max-h-60"
          />

          <MultiDropdown
            placeholder={placeHolders.features}
            options={options.products}
            preselectedOptions={selectedProducts}
            onSelectionChange={setSelectedProducts}
            dropdownLength="max-h-60"
          />

          <DatepickerComponent
            value={dateRange}
            onChange={onChangeDatePicker}
            product="consumer_intent"
          />
        </ActionBar>

        {/* ---- CONTENT OR SPINNER ---- */}
        {isLoading ? <OutletSpinner /> : <Outlet context={context} />}
      </div>
    </NavTabs>
  );
}

export default ConsumerIntPage;
