import moment from 'moment';
import { useQuery } from 'react-query';
import { addDays, subDays } from 'date-fns';
import { downloadImage } from 'utils/download';
import React, { useMemo, useState, useRef, useEffect } from 'react';

// COMPONENTS
import DetailsSection from './DetailsSection';
import LoadingSpinner from '../LoadingSpinner';
import FilterBarWrapper from '../FilterBarWrapper';
import DatepickerComponent from '../DatepickerComponent';

// TYPES
import { DateValueType } from 'react-tailwindcss-datepicker';

// UTILS
import { toFixedNum } from 'utils';

interface MetricCardProps {
  title: string;
  value: number | string;
  className: string;
}

interface APIResponse {
  recommendations: {
    [category: string]: Array<{
      cluster_category: string;
      recommendations: string;
      organic_impressions: number;
      organic_clicks: number;
      organic_transactions: number;
      organic_revenue: number;
      paid_impressions: number;
      paid_clicks: number;
      paid_transactions: number;
      paid_revenue: number;
      top_search_terms: string[];
    }>;
  };
}

const visibleCategoriesCount = 3;

function MetricCard({ title, value, className }: MetricCardProps) {
  const formatTitle = (title: string) => {
    return title
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div className={`p-6 ${className}`}>
      <h4 className="font-bold text-center">{formatTitle(title)}</h4>
      <p className="text-center">
        {typeof value === 'number' ? toFixedNum(value) : value}
      </p>
    </div>
  );
}

const fetchRecommendations = async (
  startDate: string,
  endDate: string
): Promise<APIResponse> => {
  const response = await fetch(
    `/get_insights_rec_data/${startDate}/${endDate}`
  );
  if (!response.ok) {
    const errorData = await response.json();
    if (errorData.error === "'json_data'") {
      throw new Error(
        'No data for this time period, please change the date range'
      );
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

function Recommendations() {
  const formatCategory = (category: string): string => {
    // Check if the category is already in title case and contains no hyphens
    if (/^[A-Z][a-z]*$/.test(category)) {
      return category;
    }

    // Split the category by hyphen and capitalize each word
    return category
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  const [activeCategory, setActiveCategory] = useState<string>('');
  const [activeRecommendationIndex, setActiveRecommendationIndex] =
    useState<number>(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [value, setValue] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: addDays(new Date(), -90), // 90 days before today
    endDate: subDays(new Date(), 3),
  });

  const { startDate, endDate } = value;
  const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
  const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { data, isLoading, error } = useQuery(
    ['recommendations', formattedStartDate, formattedEndDate],
    () => fetchRecommendations(formattedStartDate, formattedEndDate),
    {
      enabled: !!startDate && !!endDate,
      onSuccess: (data) => {
        //active category and recommendation index states are set accordingly.
        if (Object.keys(data.recommendations).length > 0) {
          const firstCategory = Object.keys(data.recommendations)[0];
          setActiveCategory(firstCategory);
          setActiveRecommendationIndex(0);
        } else {
          setActiveCategory('');
        }
      },
    }
  );

  const handleValueChange = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      setValue({
        startDate: new Date(newValue.startDate),
        endDate: new Date(newValue.endDate),
      });
    }
  };

  const handlePrevClick = () => {
    const categories = Object.keys(data?.recommendations || {});
    const currentIndex = categories.indexOf(activeCategory);
    if (currentIndex > 0) {
      const previousCategory = categories[currentIndex - 1];
      setActiveCategory(previousCategory);
      setActiveRecommendationIndex(0);
    }
  };

  const handleNextClick = () => {
    const categories = Object.keys(data?.recommendations || {});
    const currentIndex = categories.indexOf(activeCategory);
    if (currentIndex < categories.length - 1) {
      const nextCategory = categories[currentIndex + 1];
      setActiveCategory(nextCategory);
      setActiveRecommendationIndex(0);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handlePrevRecommendation = () => {
    if (activeRecommendationIndex > 0) {
      setActiveRecommendationIndex(activeRecommendationIndex - 1);
    }
  };

  const handleNextRecommendation = () => {
    if (
      activeRecommendationIndex <
      (data?.recommendations[activeCategory]?.length || 0) - 1
    ) {
      setActiveRecommendationIndex(activeRecommendationIndex + 1);
    }
  };

  const categories = useMemo(
    () => Object.keys(data?.recommendations || {}).map((category) => category),
    [data?.recommendations]
  );

  const activeIndex = useMemo(
    () => categories.indexOf(activeCategory),
    [categories, activeCategory]
  );

  const visibleCategories = useMemo(() => {
    const startIndex = activeIndex > 0 ? activeIndex - 1 : 0;
    return categories
      .slice(startIndex, startIndex + visibleCategoriesCount)
      .map((category) => (
        <button
          key={category}
          onClick={() => {
            setActiveCategory(category);
            setActiveRecommendationIndex(0);
          }}
          className={`px-4 py-2 rounded-lg ${
            category === activeCategory
              ? 'bg-light text-primarygray font-bold'
              : 'text-thirdgray'
          }`}
        >
          {formatCategory(category)}
        </button>
      ));
  }, [categories, activeIndex, activeCategory]);

  const overflowCategories = useMemo(() => {
    const startIndex = activeIndex > 0 ? activeIndex - 1 : 0;
    return categories
      .filter(
        (_, index) =>
          index < startIndex || index > startIndex + visibleCategoriesCount - 1
      )
      .map((category) => (
        <div
          key={category}
          onClick={() => {
            setActiveCategory(category);
            setActiveRecommendationIndex(0);
            setShowDropdown(false);
          }}
          className="px-4 py-2 hover:bg-light cursor-pointer"
        >
          {category}
        </div>
      ));
  }, [categories, activeIndex]);

  const activeRecommendations = data?.recommendations[activeCategory] || [];
  const activeRecommendation =
    activeRecommendations[activeRecommendationIndex] || {};
  const top_search_termsArray = activeRecommendation.top_search_terms || [];
  const uniquetop_search_terms = new Set(top_search_termsArray);
  const uniquetop_search_termsCount = uniquetop_search_terms.size;
  const metrics = {
    organic_impressions: activeRecommendation.organic_impressions || 0,
    paid_impressions: activeRecommendation.paid_impressions || 0,
    organic_clicks: activeRecommendation.organic_clicks || 0,
    paid_clicks: activeRecommendation.paid_clicks || 0,
    organic_transactions: activeRecommendation.organic_transactions || 0,
    paid_transactions: activeRecommendation.paid_transactions || 0,
    organic_revenue: activeRecommendation.organic_revenue || 0,
    paid_revenue: activeRecommendation.paid_revenue || 0,
  };

  const specialIndexes = [2, 3, 6, 7];

  const togglemenuDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const downloadMetricsImage = () => {
    downloadImage('recommendations');
  };

  return (
    <div className="pt-8">
      <FilterBarWrapper>
        <div className="flex-1" />
        <DatepickerComponent
          value={value}
          onChange={handleValueChange}
          product="insights"
        />
      </FilterBarWrapper>

      {!isLoading && data && Object.keys(data.recommendations).length ? (
        <>
          <div className="flex justify-center my-8">
            <div className="relative overflow-y-visible">
              <div className=" flex bg-gray100 rounded-lg p-1 items-center whitespace-nowrap overflow-auto">
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
                  alt="Previous"
                  className={`h-4 w-4 mr-2 cursor-pointer rotate-180 ${
                    categories.indexOf(activeCategory) === 0
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                  onClick={handlePrevClick}
                />
                {visibleCategories}
                <button
                  onClick={toggleDropdown}
                  className="px-4 py-2 rounded-lg text-thirdgray flex items-center"
                >
                  ...
                  <img
                    src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
                    alt="Dropdown"
                    className={`h-4 w-4 ml-1 transform ${
                      showDropdown ? '-rotate-90' : 'rotate-90'
                    }`}
                  />
                </button>

                <img
                  src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
                  alt="Next"
                  className={`h-4 w-4 ml-2 cursor-pointer ${
                    categories.indexOf(activeCategory) === categories.length - 1
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                  onClick={handleNextClick}
                />
              </div>
              {showDropdown && (
                <div className="absolute top-12 right-10 bg-light shadow-lg rounded-lg mt-2 z-50 max-h-60 overflow-auto">
                  {overflowCategories}
                </div>
              )}
            </div>
          </div>
          <div id="recommendations">
            <div className="flex justify-between items-start">
              <div className="text-left">
                <h1 className="text-2xl font-bold">
                  Recommendations for {formatCategory(activeCategory)}
                </h1>
                <h2 className="text-sm font-bold text-gray800 mb-4">
                  Top 4 recommendation based on your recommendation indicators
                </h2>
              </div>
              <div
                className="relative"
                ref={dropdownRef}
                id="downloadDropdownMenu-recommendations"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
                  alt="dots"
                  className="h-6 w-6 mr-4 cursor-pointer"
                  onClick={togglemenuDropdown}
                />
                {isDropdownOpen && (
                  <div
                    className="absolute right-4 mt-2 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                    style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
                  >
                    <ul>
                      {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                      {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        closeDropdown();
                      }}
                  >
                      Download XLS
                  </li> */}
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          downloadMetricsImage();
                          closeDropdown();
                        }}
                      >
                        Download PNG image
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="border-t border-success mb-8"></div>
            <div className="flex flex-col lg:flex-row justify-between">
              <div className="flex flex-col w-full lg:w-1/2 mr-6">
                <div className="bg-secondarygray text-light p-4 rounded-2xl mb-8 flex-grow h-[148px] overflow-auto ">
                  <div className="flex justify-between items-center mb-2 border-b border-success">
                    <h3 className="font-bold text-base">Recommendation</h3>
                    <div className="flex items-center">
                      <img
                        src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
                        alt="left icon"
                        className={`inline mr-2 rotate-180 cursor-pointer ${
                          activeRecommendationIndex === 0
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                        onClick={handlePrevRecommendation}
                      />
                      <span className="text-primarygray font-normal text-base mb-1 px-2 bg-light rounded-[100px]">
                        {activeRecommendationIndex + 1} of{' '}
                        {activeRecommendations.length}
                      </span>
                      <img
                        src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
                        alt="right icon"
                        className={`inline ml-2 cursor-pointer ${
                          activeRecommendationIndex ===
                          activeRecommendations.length - 1
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                        onClick={handleNextRecommendation}
                      />
                    </div>
                  </div>
                  <div className="h-[calc(100%-46px)]">
                    <p>{activeRecommendation.recommendations}</p>
                  </div>
                </div>

                <div className="bg-light p-4 rounded-2xl flex-grow overflow-auto h-[148px]">
                  <div className="flex items-center mb-2 border-b border-success">
                    <span className="bg-fourthgray text-primarygray font-bold py-1 px-3 rounded-full mb-2 mr-2">
                      {uniquetop_search_termsCount}
                    </span>
                    <h4 className="text-primarygray text-base font-bold mb-2">
                      Search Terms
                    </h4>
                  </div>
                  <div className="h-[calc(100% - 46px)] overflow-auto">
                    <p>{top_search_termsArray.join(', ')}</p>
                  </div>
                </div>
              </div>

              <div className="w-full rounded-lg lg:w-1/2 mt-8 lg:mt-0">
                <div className="grid grid-cols-2 text-center text-sm">
                  {Object.keys(metrics).map((key, index) => {
                    const formattedKey = key
                      .replace(/_/g, ' ')
                      .split(' ')
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(' ');

                    const isFirstRow = index < 2;
                    const isFourthRow = index >= 6 && index < 8;

                    let borderRadiusClasses = '';
                    if (isFirstRow) {
                      if (index === 0) {
                        borderRadiusClasses = 'rounded-tl-2xl'; // Top-left corner
                      } else if (index === 1) {
                        borderRadiusClasses = 'rounded-tr-2xl'; // Top-right corner
                      }
                    } else if (isFourthRow) {
                      if (index === 6) {
                        borderRadiusClasses = 'rounded-bl-2xl'; // Bottom-left corner
                      } else if (index === 7) {
                        borderRadiusClasses = 'rounded-br-2xl'; // Bottom-right corner
                      }
                    }

                    return (
                      <MetricCard
                        key={index}
                        title={formattedKey}
                        value={
                          key.includes('revenue')
                            ? `$${toFixedNum(
                                metrics[key as keyof typeof metrics]
                              )}`
                            : toFixedNum(metrics[key as keyof typeof metrics])
                        }
                        className={`${
                          specialIndexes.includes(index)
                            ? 'bg-fourthgray'
                            : 'bg-light'
                        } ${
                          index % 2 === 0 ? '' : 'border-l border-primarygray'
                        } ${borderRadiusClasses}`}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <DetailsSection
            activeCategory={activeCategory}
            startDate={formattedStartDate}
            endDate={formattedEndDate}
          />
        </>
      ) : null}

      {isLoading ? <LoadingSpinner /> : null}
      {error ? <div>An error occurred while fetching data.</div> : null}
    </div>
  );
}

export default Recommendations;
