import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import ThreeStateCheckbox from './ThreeStateCheckbox';
import { downloadXLS } from 'utils/download';

export interface RecommendationConditionsData {
    [key: string] : {name: string;
            description: string;
            organicGoodPositionThreshold: string;
            organicHighClicksRatio: string;
            organicHighCtrThreshold: string;
            organicHighImpressionsRatio: string;
            organicTopPositionThreshold: string;
            paidHasCost: string;
            paidHighCompetitionThreshold: string;
            paidHighCpcThreshold: string;
            paidHighCtrThreshold: string;
            paidLowCompetitionThreshold: string;
            paidLowCvrThreshold: string;
            paidLowQualityThreshold: string;
            paidNoCompetitionThreshold: string;
        };
}

interface RecommendationConditionsProps {
    data: RecommendationConditionsData;
    onClose: () => void;
}

const RecommendationConditions: React.FC<RecommendationConditionsProps> = ({ data, onClose }) => {
    const headers = [
        { key: 'remove', label: '' },
        { key: 'name', label: 'Short name' },
        { key: 'description', label: 'Description' },
        { key: 'organicTopPositionThreshold', label: 'Threshold organic top position' },
        { key: 'organicGoodPositionThreshold', label: 'Threshold organic good position' },
        { key: 'organicHighCtrThreshold', label: 'Threshold organic high CTR' },
        { key: 'paidNoCompetitionThreshold', label: 'Threshold paid no competition' },
        { key: 'paidLowCompetitionThreshold', label: 'Threshold paid low competition' },
        { key: 'paidHighCompetitionThreshold', label: 'Threshold paid high competition' },
        { key: 'paidHighCtrThreshold', label: 'Threshold paid high CTR' },
        { key: 'paidHighCpcThreshold', label: 'Threshold paid high CPC' },
        { key: 'paidLowCvrThreshold', label: 'Threshold paid low CVR' },
        { key: 'paidLowQualityThreshold', label: 'Threshold paid low quality' },
        { key: 'paidHasCost', label: 'Threshold has cost' },
        { key: 'organicHighClicksRatio', label: 'Threshold high organic click' },
        { key: 'organicHighImpressionsRatio', label: 'Threshold high organic impressions' },
    ];

    // const downloadRecommendationConditionsXLS = () => {
    //     const headersXLS = headers.map(header => header.label);
    //     const labels = data.map(item => item.name);
    //     const columns = headers.map(header => data.map(item => item[header.key]));

    //     downloadXLS('recommendation_conditions', headersXLS, labels, columns);
    // };

    const [newConditionData, setNewConditionData] = useState<RecommendationConditionsData>(data);
    const [saveStatus, setSaveStatus] = useState<'idle' | 'success' | 'error' | 'loading'>('idle');

    useEffect(() => {
        if (!data || Object.keys(newConditionData).length > 0) {
            return;
        }
        setNewConditionData(data);
    }, [data]);

    const handleInputChange = (dictKey: string, dictField: string, newState: string) => {
        if (newState === 'empty') {
            newState = '';
        }
        setNewConditionData(prevData => ({
            ...prevData,
            [dictKey]: {
                ...prevData[dictKey],
                [dictField]: newState,
            },
        }));
    };

    const sendRecommendationData = async () => {
        const requestObject = {...newConditionData};
        
        try {
            setSaveStatus('loading');
            const response = await fetch('/save_recommendation_conditions/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestObject),
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Post request failed:', errorText);
                throw new Error(`Post request failed: ${errorText}`);
            }
    
            const responseData = await response.json();
            console.log('Post request successful:', responseData);
            setSaveStatus('success');

            setTimeout(() => setSaveStatus('idle'), 3000);
        } catch (error) {
            console.error('Error during post request:', error);
            setSaveStatus('error');

            setTimeout(() => setSaveStatus('idle'), 3000);
        }
    };

    const checkboxCreation = (dictKey: string, dictField: string, preselectedState?: string) => {
        const options = ['positive', 'negative']
        if (preselectedState && options.includes(preselectedState)) {
            return <ThreeStateCheckbox preselectedState={preselectedState} onChange={(newState) => handleInputChange(dictKey, dictField, newState)}/>;
        }
        return <ThreeStateCheckbox preselectedState={'empty'} onChange={(newState) => handleInputChange(dictKey, dictField, newState)}/>;
    };

    const handleAddMore = () => {
        const allKeys = Object.keys(newConditionData).map(key => {
            if (key.startsWith('new_object_')) {
                return Number(key.replace('new_object_', ''));
            } else {
                return Number(key.replace('object_', ''));
            }
        })

        let maxKey : number
        if (allKeys.length > 0) {
            maxKey = Math.max(...allKeys) + 1
        } else {
            maxKey = 1
        }

        setNewConditionData(prevData => ({
            ...prevData,
            [`new_object_${maxKey}`]: {
                name: `default_name_${maxKey}`,
                description: `default_desc_${maxKey}`,
                organicGoodPositionThreshold: '',
                organicHighClicksRatio: '',
                organicHighCtrThreshold: '',
                organicHighImpressionsRatio: '',
                organicTopPositionThreshold: '',
                paidHasCost: '',
                paidHighCompetitionThreshold: '',
                paidHighCtrThreshold: '',
                paidHighCpcThreshold: '',
                paidLowCompetitionThreshold: '',
                paidLowCvrThreshold: '',
                paidLowQualityThreshold: '',
                paidNoCompetitionThreshold: '',
            },
        }));
    };

    const handleRemove = (key: string) => {
        setNewConditionData(prevData => {
            const modifiedConditionData = { ...prevData };
            delete modifiedConditionData[key];
            return modifiedConditionData;
        });
    };
    const handleNameChange = (key: string, newName: string) => {
        setNewConditionData(prevData => {
            const modifiedConditionData = { ...prevData };
            modifiedConditionData[key].name = newName;
            return modifiedConditionData;
        });
    };

    const handleDescChange = (key: string, newDescription: string) => {
        setNewConditionData(prevData => {
            const modifiedConditionData = { ...prevData };
            modifiedConditionData[key].description = newDescription;
            return modifiedConditionData;
        });
    };

    if (!data) {
        return <LoadingSpinner />;
    }

    return (
        <div className="bg-light rounded-2xl shadow-sm mt-8">
            <div className="flex justify-between items-center px-4 pt-2 border-b border-success pb-2">
                <div>
                    <h2 className="text-primarygray text-2xl font-bold">Recommendation Conditions</h2>
                    <h5 className="text-primarygray font-medium">Set conditions based on your thresholds for recommendations in your cluster views.</h5>
                </div>
                {/* <button onClick={downloadRecommendationConditionsXLS} className="text-sm text-light bg-primarygray px-3 py-2 rounded-lg">
                    Download XLS
                </button> */}
            </div>

            <div className="overflow-x-auto mx-4 px-1">
                <table className="min-w-full bg-light mt-2">
                    <thead className="uppercase">
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index} className={`py-2 px-3 text-primarygray whitespace-normal max-w-24 text-xs font-normal 
                                ${header.key == 'name' || header.key == "description" ? 'text-left' : 'text-center'} align-bottom`}>
                                    {header.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(newConditionData).length > 0 ? (
                            Object.entries(newConditionData).map(([key, row], rowIndex) => (
                                <tr key={key} className={rowIndex % 2 === 0 ? "bg-fourthgray" : "bg-gray100"}>
                                    <td className="pl-4 py-2 text-thirdgray text-xs font-normal text-left flex gap-2 items-center">
                                        <button onClick={() => handleRemove(key)}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/static/img/delete_option.svg`}
                                                alt="delete"
                                                className="h-5 w-5 min-w-5 min-h-5"
                                            />
                                        </button>
                                    </td>
                                    <td className="px-3 py-2 text-thirdgray text-xs font-normal text-left">
                                        <input type="text" value={row.name} className='max-w-24 bg-transparent' onChange={(e) => handleNameChange(key, e.target.value)}/>
                                    </td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">
                                        <input type="text" value={row.description} className='max-w-24 bg-transparent' onChange={(e) => handleDescChange(key, e.target.value)}/>
                                    </td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'organicTopPositionThreshold', row.organicTopPositionThreshold)}</td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'organicGoodPositionThreshold', row.organicGoodPositionThreshold)}</td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'organicHighCtrThreshold', row.organicHighCtrThreshold)}</td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'paidNoCompetitionThreshold', row.paidNoCompetitionThreshold)}</td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'paidLowCompetitionThreshold', row.paidLowCompetitionThreshold)}</td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'paidHighCompetitionThreshold', row.paidHighCompetitionThreshold)}</td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'paidHighCtrThreshold', row.paidHighCtrThreshold)}</td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'paidHighCpcThreshold', row.paidHighCpcThreshold)}</td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'paidLowCvrThreshold', row.paidLowCvrThreshold)}</td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'paidLowQualityThreshold', row.paidLowQualityThreshold)}</td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'paidHasCost', row.paidHasCost)}</td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'organicHighClicksRatio', row.organicHighClicksRatio)}</td>
                                    <td className="px-3 py-2 text-primarygray text-xs font-normal text-left">{checkboxCreation(key, 'organicHighImpressionsRatio', row.organicHighImpressionsRatio)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={headers.length} className="text-center py-10 text-secondarygray text-3xl font-bold bg-fourthgray rounded mx-4">
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="border border-thirdgray rounded-[20px] px-2 w-fit ml-6 mt-4">
                <button onClick={() => handleAddMore()} className="text-sm text-thirdgray font-bold flex items-center">
                    <img
                        src={`${process.env.PUBLIC_URL}/static/img/plus_gray.svg`}
                        alt="plus_icon"
                        className="w-[18px] h-[18px] mr-1"
                    />
                    ADD MORE
                </button>
            </div>

            <div className="flex justify-end mr-4 py-4">
                <button onClick={onClose} className="text-success text-sm font-bold px-4 py-2 mt-2 w-[150px] mr-3">
                    CANCEL
                </button>
                <button
                    onClick={() => sendRecommendationData()}
                    className={
                        saveStatus === 'success'
                            ? 'bg-green-700 text-light text-sm font-bold px-6 py-2 mt-2 w-[150px] rounded-[20px] pointer-events-none'
                            : saveStatus === 'error'
                            ? 'bg-error text-light text-sm font-bold px-6 py-2 mt-2 w-[150px] rounded-[20px] pointer-events-none'
                            : saveStatus === 'loading'
                            ? 'bg-success text-light text-sm font-bold px-6 py-2 mt-2 w-[150px] rounded-[20px] animate-pulse pointer-events-none'
                            : 'bg-success text-light text-sm font-bold px-6 py-2 mt-2 w-[150px] rounded-[20px]'
                    }
                >
                    {saveStatus === 'success' ? 'SAVED' : saveStatus === 'error' ? 'ERROR' : 'SAVE'}
                </button>
            </div>
        </div>
    );
};

export default RecommendationConditions;
