import Tooltip from './Tooltip';

const getArrowIconSRC = (isPositive: string | boolean, invert?: boolean) => {
  const baseURL = `${process.env.PUBLIC_URL}/static/img`;
  if (typeof isPositive === 'string') return;
  if (isPositive && !invert) {
    return baseURL + '/Arrow_positive.svg';
  }
  if (!isPositive && invert) {
    return baseURL + '/Arrow_down_green.svg';
  }
  if (isPositive && invert) {
    return baseURL + '/Arrow_up_red.svg';
  }
  if (!isPositive) {
    return baseURL + '/Arrow_red.svg';
  }
  return;
};

export interface CardProps {
  title: string;
  value: string;
  change: string;
  isPositive: boolean | 'Invalid';
  styleType: 'light' | 'dark';
  tooltipText?: string | string[];
  tooltipPosition?: 'above' | 'below';
  invert?: boolean; // negative value === positive
}

function Card({
  title,
  value,
  change,
  isPositive,
  styleType,
  tooltipText,
  tooltipPosition,
  invert = false,
}: CardProps) {
  const isPos = (isPositive && !invert) || (!isPositive && invert);
  const bgColor = styleType === 'light' ? 'bg-light' : 'bg-secondarygray';
  const textColor = styleType === 'light' ? 'text-primarygray' : 'text-light';
  const arrowSRC = getArrowIconSRC(isPositive, invert);
  const changeColor =
    isPositive === 'Invalid'
      ? 'text-black'
      : isPos
      ? 'text-success'
      : 'text-error';

  return (
    <div
      className={`${bgColor} p-4 rounded-2xl text-center ${
        styleType === 'light' && 'shadow'
      }`}
    >
      <p
        className={`${textColor} text-sm font-medium mb-2 flex justify-center items-center`}
      >
        {title}
        {tooltipText && (
          <Tooltip text={tooltipText} position={tooltipPosition} />
        )}
      </p>
      <h3 className={`${textColor} text-2xl font-bold mb-2`}>{value}</h3>
      <div
        className={`${changeColor} flex items-center justify-center text-sm font-medium`}
      >
        {isPositive !== 'Invalid' && (
          <img
            src={arrowSRC}
            alt={isPos ? 'Positive Change' : 'Negative Change'}
            className="w-4 h-4 mr-1"
          />
        )}
        {change}
      </div>
    </div>
  );
}

export default Card;
