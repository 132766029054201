import React, { useState } from 'react';
import Cluster1 from './cluster_components/Cluster1';
import Cluster2 from './cluster_components/Cluster2';
import Cluster3 from './cluster_components/Cluster3';
import Cluster4 from './cluster_components/Cluster4';
import Cluster5 from './cluster_components/Cluster5';
import Cluster5_1 from './cluster_components/Cluster5_1';
import Cluster6 from './cluster_components/Cluster6';
import DatepickerComponent from '../DatepickerComponent';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { addDays, subDays } from 'date-fns';
import moment from 'moment';
import FilterBarWrapper from '../FilterBarWrapper';

const Cluster: React.FC = () => {
  const [value, setValue] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: addDays(new Date(), -90), // 90 days before today
    endDate: subDays(new Date(), 3),
    // startDate: new Date("2024-04-07"),
    // endDate: new Date("2024-04-15"),
  });

  const handleValueChange = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      setValue({
        startDate: new Date(newValue.startDate),
        endDate: new Date(newValue.endDate),
      });
    }
  };

  return (
    <div className="pt-8">
      <FilterBarWrapper>
        <div className="flex-1" />
        <DatepickerComponent
          value={value}
          onChange={handleValueChange}
          product="insights"
        />
      </FilterBarWrapper>
      <Cluster1
        startDate={moment(value.startDate).format('YYYY-MM-DD')}
        endDate={moment(value.endDate).format('YYYY-MM-DD')}
      />
      <Cluster5
        startDate={moment(value.startDate).format('YYYY-MM-DD')}
        endDate={moment(value.endDate).format('YYYY-MM-DD')}
      />
      <Cluster2
        startDate={moment(value.startDate).format('YYYY-MM-DD')}
        endDate={moment(value.endDate).format('YYYY-MM-DD')}
      />
      <Cluster3
        startDate={moment(value.startDate).format('YYYY-MM-DD')}
        endDate={moment(value.endDate).format('YYYY-MM-DD')}
      />
      <Cluster4
        startDate={moment(value.startDate).format('YYYY-MM-DD')}
        endDate={moment(value.endDate).format('YYYY-MM-DD')}
      />
      <Cluster5_1
        startDate={moment(value.startDate).format('YYYY-MM-DD')}
        endDate={moment(value.endDate).format('YYYY-MM-DD')}
      />
      <Cluster6
        startDate={moment(value.startDate).format('YYYY-MM-DD')}
        endDate={moment(value.endDate).format('YYYY-MM-DD')}
      />
    </div>
  );
};

export default Cluster;
