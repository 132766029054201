import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import 'chart.js/auto';
import CustomLegend2 from './CustomLegend2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface RetailerDemand {
  Retailer: string;
  total_retailer_demand: number;
}

interface Props {
  data: {
    total_demand_by_product: {
      [product: string]: RetailerDemand[];
    };
  };
}

const colors = [
  'rgba(34, 34, 34, 0.80)',
  'rgba(89, 89, 89, 0.8)',
  'rgba(144, 144, 144, 0.8)',
  'rgba(50, 215, 111, 0.8)',
  'rgba(3, 197, 255, 0.8)',
  'rgba(84, 79, 197, 0.8)',
  'rgba(254, 106, 53, 0.8)',
  'rgba(107, 138, 188, 0.8)',
  'rgba(213, 104, 251, 0.8)',
  'rgba(47, 224, 202, 0.8)',
  'rgba(250, 75, 66, 0.8)',
  'rgba(254, 181, 106, 0.8)',
  'rgba(254, 181, 106, 0.8)',
];

const normalizeLabel = (label: string) => label.replace(/\s+/g, '_');

const RCStackedBar: React.FC<Props> = ({ data }) => {
  const [visibility, setVisibility] = useState<Record<string, boolean>>({});
  const [isLegendExpanded, setIsLegendExpanded] = useState(false);
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initialVisibility = Object.keys(data.total_demand_by_product).reduce((acc, product) => {
      const productData = data.total_demand_by_product[product];
      if (Array.isArray(productData)) {
        productData.forEach(item => {
          const normalizedLabel = normalizeLabel(item.Retailer);
          if (!(normalizedLabel in acc)) {
            acc[normalizedLabel] = normalizedLabel !== 'uncategorized';
          }
        });
      }
      return acc;
    }, {} as Record<string, boolean>);
    setVisibility(initialVisibility);
  }, [data]);

  const calculateBarThickness = (chartWidth: number, numberOfLabels: number) => {
    return Math.max(10, chartWidth / (numberOfLabels * 2));
  };

  const handleLegendClick = (label: string) => {
    const normalizedLabel = normalizeLabel(label);
    setVisibility((prevState) => ({
      ...prevState,
      [normalizedLabel]: !prevState[normalizedLabel],
    }));
  };

  const toggleLegend = () => {
    setIsLegendExpanded(!isLegendExpanded);
  };

  const products = Object.keys(data.total_demand_by_product).filter(product => product !== 'uncategorized');
  const retailers = Array.from(new Set(products.flatMap(product => {
    const productData = data.total_demand_by_product[product];
    return Array.isArray(productData) ? productData.map(item => item.Retailer) : [];
  })));

  if (products.length === 0 || retailers.length === 0) {
    return null;
  }
  const chartWidth = chartRef.current ? chartRef.current.offsetWidth : 800;
  const barThickness = calculateBarThickness(chartWidth, products.length);

  const chartData: ChartData<'bar'> = {
    labels: products,
    datasets: retailers.map((retailer, index) => {
      const normalizedLabel = normalizeLabel(retailer);
      return {
        label: retailer,
        data: products.map((product) => {
          const productData = data.total_demand_by_product[product];
          if (Array.isArray(productData)) {
            const retailerData = productData.find((item) => item.Retailer === retailer);
            return retailerData ? retailerData.total_retailer_demand : 0;
          }
          return 0;
        }),
        backgroundColor: colors[index % colors.length],
        borderWidth: 0,
        barThickness: barThickness,
        borderRadius: 8,
        hidden: !visibility[normalizedLabel],
      };
    }),
  };

  const formatYAxisTick = (tickValue: number) => {
    if (tickValue >= 1_000_000) {
      return `$${(tickValue / 1_000_000).toFixed(1)}M`;
    } else if (tickValue >= 1_000) {
      return `$${(tickValue / 1_000).toFixed(1)}K`;
    } else {
      return tickValue.toString();
    }
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Product',
          color: '#4D4D4D',
          font: {
            size: 14,
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        grid: {
          color: '#B3B3B3',
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Retail Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return formatYAxisTick(tickValue);
            }
            return tickValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
      },
    },
  };

  const visibleRetailers = isLegendExpanded ? retailers : retailers.slice(0, 8);

  return (
    <div className="bg-light rounded-2xl">
      <div className="flex relative">
        <div ref={chartRef} className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]">
          <Bar data={chartData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <CustomLegend2
            brandsData={visibleRetailers.map((retailer, index) => ({
              label: retailer,
              color: colors[index % colors.length],
            }))}
            onLegendClick={handleLegendClick}
            visibility={visibility}
          />
          <button
            onClick={toggleLegend}
            className="mt-2 bg-light hover:underline"
          >
            {isLegendExpanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RCStackedBar;
