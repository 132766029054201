import React, { useEffect, useState } from 'react';
import RangeSlider from './CustomSlider';
import LoadingSpinner from '../LoadingSpinner';
import { downloadXLS } from 'utils/download'

export interface RecommendationIndicatorsData {
    organicGoodPositionThreshold : number;
    organicHighClicksRatio : number;
    organicHighCtrThreshold : number;
    organicHighImpressionsRatio : number;
    organicTopPositionThreshold : number;
    paidHasCost : number;
    paidHighCompetitionThreshold : number;
    paidHighCpcThreshold : number;
    paidHighCtrThreshold : number;
    paidLowCompetitionThreshold : number;
    paidLowCvrThreshold : number;
    paidLowQualityThreshold : number;
    paidNoCompetitionThreshold : number;
}

interface RecommendationIndicatorProps {
    data: RecommendationIndicatorsData;
    onClose: () => void;
}

const renderSlider = (
    title: string, 
    description: string, 
    defaultValue: number, 
    unit: string, 
    defaultText: string, 
    minN: number, 
    maxN: number,
    step: number,
    onChange: (value: number) => void
) => {
    return (
        <div className="p-4">
            <h3 className="text-sm font-bold mb-2">{title}</h3>
            <p className="text-xs text-gray-500 mb-2">{description}</p>
            <div className="flex items-center">
                <RangeSlider min={minN} max={maxN} step={step} defaultValue={defaultValue} onChange={onChange} unit={unit} defaultText={defaultText}/>
            </div>
        </div>
    );
};  

const RecommendationIndicator: React.FC<RecommendationIndicatorProps> = ({ data, onClose }) => {
    const [newIndicatorData, setNewIndicatorData] = useState<RecommendationIndicatorsData>(data);
    const [saveStatus, setSaveStatus] = useState<'idle' | 'success' | 'error' | 'loading'>('idle');

    useEffect(() => {
        if (!data) {
            return;
        }
        setNewIndicatorData(data);
    }, [data]);

    const handleSliderChange = (key: keyof RecommendationIndicatorsData, value: number) => {
        setNewIndicatorData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const sendRecommendationData = async () => {
        const requestObject = {...newIndicatorData};
        
        try {
            setSaveStatus('loading');
            const response = await fetch('/save_recommendation_indicators/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestObject),
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Post request failed:', errorText);
                throw new Error(`Post request failed: ${errorText}`);
            }
    
            const responseData = await response.json();
            console.log('Post request successful:', responseData);
            setSaveStatus('success');

            setTimeout(() => setSaveStatus('idle'), 3000);
        } catch (error) {
            console.error('Error during post request:', error);
            setSaveStatus('error');

            setTimeout(() => setSaveStatus('idle'), 3000);
        }
    };

    if (!data) {
        return <LoadingSpinner />
    }

    return (
    <div className="bg-light rounded-2xl shadow-sm mt-8">
        <div className="flex justify-between items-center border-b border-success mb-2">
            <div>
            <h2 className="text-primarygray text-2xl font-bold mb-2 pl-4 pt-2">Recommendation indicators</h2>
            <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">Define the relevant threshold for brand/client and industry</h3>
            </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 border-b border-success">
            {renderSlider("Threshold paid no competition", "The highest paid competition that should be considered that should be considered as no competition. Should be less than the thresholds for low and high competition.", data.paidNoCompetitionThreshold, "%", "10%", 0, 100, 1, (value) => handleSliderChange('paidNoCompetitionThreshold', value))}
            {renderSlider("Threshold paid low competition", "The smallest paid competition that should be considered that should be considered as high competition. Should be more than the thresholds for low and no competition.", data.paidLowCompetitionThreshold, "%", "35%", 0, 100, 1, (value) => handleSliderChange('paidLowCompetitionThreshold', value))}
            {renderSlider("Threshold paid high competition", "The smallest paid competition that should be considered that should be considered as high competition. Should be more than the thresholds for low and no competition.", data.paidHighCompetitionThreshold, "%", "70%", 0, 100, 1, (value) => handleSliderChange('paidHighCompetitionThreshold', value))}
        </div>

        <div className="border-b border-success">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 border-b">
            {renderSlider("Threshold top organic position", "The largest organic position that is still considered the best position.", data.organicTopPositionThreshold, "", "2", 0, 10, 1, (value) => handleSliderChange('organicTopPositionThreshold', value))}
            {renderSlider("Threshold good organic position", "The largest organic position that is still considered a good position.", data.organicGoodPositionThreshold, "", "6", 0, 10, 1, (value) => handleSliderChange('organicGoodPositionThreshold', value))}
            </div>
        </div>

        <div className="border-b border-success">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            {renderSlider("Threshold organic high CTR", "The smallest organic CTR that should be considered as high.", data.organicHighCtrThreshold, "%", "50%", 0, 100, 1, (value) => handleSliderChange('organicHighCtrThreshold', value))}
            {renderSlider("Threshold paid high CTR", "The smallest paid CTR that should be considered as high.", data.paidHighCtrThreshold, "%", "50%", 0, 100, 1, (value) => handleSliderChange('paidHighCtrThreshold', value))}
            </div>
        </div>

        <div className="border-b border-success">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            {renderSlider("Threshold paid high CPC", "The smallest paid CPC that should be considered as high. Use the automatic mode to take 80% of the average CPC as the value.", data.paidHighCpcThreshold, "$", "*auto", 0, 25, 0.1, (value) => handleSliderChange('paidHighCpcThreshold', value))}
            {renderSlider("Threshold paid low CVR", "The lowest paid CVR that should be considered as high. Use the automatic mode to take 20% of the average CVR as the value.", data.paidLowCvrThreshold, "%", "*auto", 0, 100, 1, (value) => handleSliderChange('paidLowCvrThreshold', value))}
            </div>
        </div>

        <div className="border-b border-success">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            {renderSlider("Threshold paid low quality", "The highest quality score that should be considered as low.", data.paidLowQualityThreshold, "", "*auto", 0, 10, 1, (value) => handleSliderChange('paidLowQualityThreshold', value))}
            {renderSlider("Threshold has cost", "The lowest cost that should be considered as 'has cost'.", data.paidHasCost, "", "1.0E-5", 0, 0.99, 0.01, (value) => handleSliderChange('paidHasCost', value))}
            </div>
        </div>

        <div className="border-b border-success">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 border-b">
            {renderSlider("Threshold high organic click ration", "The percentage value of organic clicks compared to paid clicks.", data.organicHighClicksRatio, "%", "*auto", 0, 100, 1, (value) => handleSliderChange('organicHighClicksRatio', value))}
            {renderSlider("Threshold high organic impressions ration", "The percentage value of organic impressions compared to paid impressions.", data.organicHighImpressionsRatio, "%", "*auto", 0, 100, 1, (value) => handleSliderChange('organicHighImpressionsRatio', value))}
            </div>
        </div>

            <div className="flex justify-end mr-4 py-4">
                <button onClick={onClose} className="text-success text-sm font-bold px-4 py-2 mt-2 w-[150px] mr-3">
                    CANCEL
                </button>
                <button
                    onClick={() => sendRecommendationData()}
                    className={
                        saveStatus === 'success'
                            ? 'bg-green-700 text-light text-sm font-bold px-6 py-2 mt-2 w-[150px] rounded-[20px] pointer-events-none'
                            : saveStatus === 'error'
                            ? 'bg-error text-light text-sm font-bold px-6 py-2 mt-2 w-[150px] rounded-[20px] pointer-events-none'
                            : saveStatus === 'loading'
                            ? 'bg-success text-light text-sm font-bold px-6 py-2 mt-2 w-[150px] rounded-[20px] animate-pulse pointer-events-none'
                            : 'bg-success text-light text-sm font-bold px-6 py-2 mt-2 w-[150px] rounded-[20px]'
                    }
                >
                    {saveStatus === 'success' ? 'SAVED' : saveStatus === 'error' ? 'ERROR' : 'SAVE'}
                </button>
            </div>
        </div>
    );
};

export default RecommendationIndicator;
