import React from 'react';

interface LegendItem {
  label: string;
  icon: string;
}

interface LegendsData {
  [key: string]: LegendItem[];
}

const legendsData: LegendsData = {
  set1: [
    { label: 'Organic revenue Brand Generic', icon: `${process.env.PUBLIC_URL}/static/img/organic_brand_generic.svg` },
    { label: 'Paid revenue Brand Generic', icon: `${process.env.PUBLIC_URL}/static/img/paid_brand_generic.svg` },
    { label: 'Organic revenue Product Line', icon: `${process.env.PUBLIC_URL}/static/img/organic_product_line.svg` },
    { label: 'Paid revenue Product Line', icon: `${process.env.PUBLIC_URL}/static/img/paid_product_line.svg` },
    { label: 'Organic revenue Other', icon: `${process.env.PUBLIC_URL}/static/img/organic_other.svg` },
    { label: 'Paid revenue Other', icon: `${process.env.PUBLIC_URL}/static/img/paid_other.svg` },
  ],
  set1_1: [
    { label: 'Organic Transactions Brand Generic', icon: `${process.env.PUBLIC_URL}/static/img/organic_brand_generic.svg` },
    { label: 'Paid Transactions Brand Generic', icon: `${process.env.PUBLIC_URL}/static/img/paid_brand_generic.svg` },
    { label: 'Organic Transactions Product Line', icon: `${process.env.PUBLIC_URL}/static/img/organic_product_line.svg` },
    { label: 'Paid Transactions Product Line', icon: `${process.env.PUBLIC_URL}/static/img/paid_product_line.svg` },
    { label: 'Organic Transactions Other', icon: `${process.env.PUBLIC_URL}/static/img/organic_other.svg` },
    { label: 'Paid Transactions Other', icon: `${process.env.PUBLIC_URL}/static/img/paid_other.svg` },
  ]
};

interface CustomLegendProps {
  legendSet: keyof LegendsData;
  onLegendClick: (label: string) => void;
  visibility: Record<string, boolean>;
}

const CustomLegend: React.FC<CustomLegendProps> = ({ legendSet, onLegendClick, visibility }) => {
  const legends = legendsData[legendSet];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '10px' }}>
      {legends.map((legend, index) => (
        <div
          key={index}
          style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', cursor: 'pointer', opacity: visibility[legend.label] ? 1 : 0.5 }}
          onClick={() => onLegendClick(legend.label)}
        >
          <img
            src={legend.icon}
            alt={legend.label}
            style={{ width: '16px', height: '16px', marginRight: '10px' }}
          />
          <span>{legend.label}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomLegend;
