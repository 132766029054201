import React, { useState, useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartOptions, ChartData } from 'chart.js';
import 'chart.js/auto';
import CustomLegend from './CustomLegend';
import Dropdown from '../../Dropdown';
import { downloadImage, downloadXLS } from 'utils/download'

const BattlefieldIcon = ({ color }: { color: string }) => (
  <svg
    width="16"
    height="8"
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ stroke: color }}
  >
    <path
      d="M0 4H16"
      strokeOpacity="0.8"
      strokeWidth="8"
      strokeLinecap="round"
    />
  </svg>
);

interface BattlefieldData {
  data: {
    [device: string]: {
      [ad_type: string]: {
        [site: string]: {
          [date: string]: number[];
        };
      };
    };
  };
  clientDomain: string;
}

type LegendItem = {
  label: string;
  color: string;
  icon: JSX.Element;
};

interface Output {
  label: string[];
  columns: (string[] | number[])[];
}

const BattleField: React.FC<BattlefieldData> = ({ data, clientDomain }) => {
  const [view, setView] = useState<'desktop' | 'mobile'>('desktop');
  const [selectedFilter, setSelectedFilter] = useState<
    'PAID' | 'PLA' | 'ORGANIC'
  >('PAID');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const defaultStructure = {
    labels: [
      'No data',
      'No data',
      'No data',
      'No data',
      'No data',
      'No data',
      'No data',
    ],
    datasets: [
      {
        label: 'Position 1',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderRadius: 8,
      },
      {
        label: 'Position 2',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(144, 144, 144, 0.80)',
        borderRadius: 8,
      },
      {
        label: 'Position 3',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(217, 217, 217, 0.8)',
        borderRadius: 8,
      },
    ],
  };

  const desktopKeys = Object.keys(data?.[view]?.[selectedFilter] || {});
  const mobileKeys = Object.keys(data?.[view]?.[selectedFilter] || {});
  const combinedSet = new Set<string>();

  desktopKeys.forEach((key) => combinedSet.add(key));
  mobileKeys.forEach((key) => combinedSet.add(key));
  const competitorOptions: string[] = Array.from(combinedSet);

  const [selectedCompetitor, setSelectedCompetitor] = useState<string>(
    clientDomain ? clientDomain : competitorOptions[0]
  );

  const [desktopData, setDesktopData] = useState(defaultStructure);
  const [mobileData, setMobileData] = useState(defaultStructure);

  const getFilteredData = (device: 'desktop' | 'mobile') => {
    const adSeenPctData1: number[] = [];
    const adSeenPctData2: number[] = [];
    const adSeenPctData3: number[] = [];

    if (!data?.[device]?.[selectedFilter]?.[selectedCompetitor]) {
      return defaultStructure;
    }

    Object.keys(data?.[device]?.[selectedFilter]?.[selectedCompetitor]).forEach(
      (date) => {
        adSeenPctData1.push(
          parseFloat(
            (
              data?.[device]?.[selectedFilter]?.[selectedCompetitor]?.[
                date
              ][0] || 0
            ).toFixed(2)
          )
        );
        adSeenPctData2.push(
          parseFloat(
            (
              data?.[device]?.[selectedFilter]?.[selectedCompetitor]?.[
                date
              ][1] || 0
            ).toFixed(2)
          )
        );
        adSeenPctData3.push(
          parseFloat(
            (
              data?.[device]?.[selectedFilter]?.[selectedCompetitor]?.[
                date
              ][2] || 0
            ).toFixed(2)
          )
        );
      }
    );

    const labels: string[] = Object.keys(
      data?.[device]?.[selectedFilter]?.[selectedCompetitor]
    );
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Position 1',
          data: adSeenPctData1,
          backgroundColor: 'rgba(34, 34, 34, 0.80)',
          borderRadius: 8,
        },
        {
          label: 'Position 2',
          data: adSeenPctData2,
          backgroundColor: 'rgba(144, 144, 144, 0.80)',
          borderRadius: 8,
        },
        {
          label: 'Position 3',
          data: adSeenPctData3,
          backgroundColor: 'rgba(217, 217, 217, 0.8)',
          borderRadius: 8,
        },
      ],
    };

    return chartData;
  };

  useEffect(() => {
    setDesktopData(getFilteredData('desktop'));
    setMobileData(getFilteredData('mobile'));
  }, [data, view, selectedFilter, selectedCompetitor]);

  let legends: LegendItem[] = [];
  useEffect(() => {
    if (view === 'desktop') {
      legends = desktopData.datasets.map((dataset) => ({
        label: dataset.label,
        color: dataset.backgroundColor,
        icon: <BattlefieldIcon color={dataset.backgroundColor} />,
      }));
    } else if (view === 'mobile') {
      legends = mobileData.datasets.map((dataset) => ({
        label: dataset.label,
        color: dataset.backgroundColor,
        icon: <BattlefieldIcon color={dataset.backgroundColor} />,
      }));
    }
  }, [data, view, selectedFilter, selectedCompetitor]);

  useEffect(() => {
    setSelectedCompetitor(clientDomain ? clientDomain : competitorOptions[0]);
  }, [data]);

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Percentage (%)',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          callback: (value) => `${value}%`,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
        min: 0,
        max: 100,
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFilterClick = (filter: 'PAID' | 'PLA' | 'ORGANIC') => {
    setSelectedFilter(filter);
  };

  const downloadChartImage = () => {
    downloadImage('BattleField');
  };
  
  const downloadXLSData = (device: 'desktop' | 'mobile') => {
    const competitorData = data[device][selectedFilter][selectedCompetitor];
    const labels = Object.keys(competitorData);

    const position1Values = labels.map((date) => competitorData[date][0] || 0);
    const position2Values = labels.map((date) => competitorData[date][1] || 0);
    const position3Values = labels.map((date) => competitorData[date][2] || 0);

    const output: Output = {
      label: labels,
      columns: [
        position1Values.map((v) => v.toString()), // Position 1 data as strings
        position2Values.map((v) => v.toString()), // Position 2 data as strings
        position3Values.map((v) => v.toString()), // Position 3 data as strings
      ],
    };

    downloadXLS(
      `Battlefield_${device}_${selectedCompetitor}_${selectedFilter}`,
      ['Date', 'Position 1 (%)', 'Position 2 (%)', 'Position 3 (%)'],
      output.label,
      output.columns
    );
  };

  return (
    <div id="BattleField" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold pl-4 pt-2">
            BattleField
          </h2>
          <h3 className="text-gray800 text-sm font-bold mb-2 pl-4">
            Which brands are winning the top 3 positions most frequently
          </h3>
        </div>
        <div className="flex items-center">
          <div className="flex items-center mr-4 text-xs">
            <span
              className={
                view === 'desktop'
                  ? 'text-primarygray mr-2'
                  : 'text-gray400 mr-2'
              }
            >
              Desktop
            </span>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={view === 'mobile'}
                onChange={() =>
                  setView(view === 'desktop' ? 'mobile' : 'desktop')
                }
              />
              <div className="relative w-10 h-5 bg-lightblue rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
            </label>
            <span
              className={
                view === 'mobile'
                  ? 'text-primarygray ml-2'
                  : 'text-gray400 ml-2'
              }
            >
              Mobile
            </span>
          </div>
          <div className="flex items-center mr-4" ref={dropdownRef} id='downloadDropdownMenu-BattleField'>
            <img
              src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
              alt="dots"
              className="h-6 w-6 cursor-pointer"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div
                className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
              >
                <ul>
                  {/* <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={closeDropdown}
                  >
                    View data table
                  </li> */}
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadXLSData(view);
                      closeDropdown();
                    }}
                  >
                    Download XLS
                  </li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartImage();
                      closeDropdown();
                    }}
                  >
                    Download PNG image
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex relative">
        <div className="flex relative px-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[calc(92%-200px)] md:w-[65%] w-[60%] h-[295px]">
          <Bar
            data={view === 'desktop' ? desktopData : mobileData}
            options={options}
          />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <CustomLegend legends={legends} />
          <div className="mt-4 text-sm font-bold flex flex-col gap-3 items-start">
            <div className="text-sm font-bold">Filter:</div>
            <Dropdown
              title={`${selectedCompetitor}`}
              options={competitorOptions}
              onOptionClick={setSelectedCompetitor}
              dropdownLength='max-h-44'
            />
            <div className="flex gap-2">
              <div className="flex gap-2 whitespace-nowrap">
                {(['PAID', 'PLA'] as const).map((filter) => (
                  <span
                    key={filter}
                    className={`text-thirdgray rounded px-3 py-1 cursor-pointer ${
                      selectedFilter === filter
                        ? 'border border-success rounded-lg'
                        : 'border border-disgreen100 rounded'
                    }`}
                    onClick={() => handleFilterClick(filter)}
                  >
                    {filter === 'PLA' ? 'SHOPPING' : filter}{' '}
                    {selectedFilter === filter && (
                      <span className="ml-1 ">✕</span>
                    )}
                  </span>
                ))}
              </div>
              <div className="flex gap-2 w-full">
                {(['ORGANIC'] as const).map((filter) => (
                  <span
                    key={filter}
                    className={`text-thirdgray rounded px-3 py-1 cursor-pointer ${
                      selectedFilter === filter
                        ? 'border border-success rounded-lg'
                        : 'border border-disgreen100 rounded'
                    }`}
                    onClick={() => handleFilterClick(filter)}
                  >
                    {filter}{' '}
                    {selectedFilter === filter && (
                      <span className="ml-1 ">✕</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BattleField;
