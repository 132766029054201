export type SnippetTagsParams = {
  id?: string;
  events?: object;
  dataLayer?: unknown[];
  dataLayerName?: string;
  preview?: string;
  auth?: string;
  gtmCookiesWin?: string;
};

const Snippets = {
  tags: function ({
    id,
    events,
    dataLayer,
    dataLayerName,
    preview,
    auth,
    gtmCookiesWin,
  }: SnippetTagsParams) {
    const gtm_auth = auth ? `&gtm_auth=${auth}` : '';
    const gtm_preview = preview ? `&gtm_preview=${preview}` : '';
    const gtm_cookies_win = gtmCookiesWin
      ? `&gtm_cookies_win=${gtmCookiesWin}`
      : '';

    if (!id) console.warn('GTM Id is required');

    const script = `
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js', ${
          events ? JSON.stringify(events).slice(1, -1) : ''
        }});
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', '${dataLayerName}', '${id}');
      `;

    const iframe = `<iframe
      src="https://www.googletagmanager.com/ns.html?id=${id}${gtm_auth}${gtm_preview}${gtm_cookies_win}"
      height="0"
      width="0"
      style="display: none; visibility: hidden"
      id="tag-manager"
      ></iframe>`;

    const dataLayerVar = this.dataLayer(dataLayer, dataLayerName);

    return {
      iframe,
      script,
      dataLayerVar,
    };
  },

  dataLayer: function (dataLayer: unknown = '', dataLayerName: string = '') {
    return `
      window.${dataLayerName} = window.${dataLayerName} || [];
      window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`;
  },
};

export default Snippets;
