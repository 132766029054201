import React, { useState, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartOptions } from 'chart.js';
import { downloadImage,downloadXLS } from 'utils/download'

interface SearchTermsProps {
  data: {
    data: {
      period: string;
      total_demand: number;
    }[];
  } | null;
  uniqueQueryCount: {
    total_unique_query_count: number;
  } | null;
  yoyGrowthDemand: {
    total_demand_previous_year: number;
    total_demand_current_year: number;
    demand_growth_ratio: number;
  } | null;
  grandTotalDemand: number | null;
}

const formatNumber = (num: number): string => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + 'B';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
};

const SearchTerms: React.FC<SearchTermsProps> = ({ data, uniqueQueryCount, yoyGrowthDemand, grandTotalDemand }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const demandData = data ? data.data : [];
  const labels = demandData.map(item => item.period);
  const chartData = demandData.map(item => item.total_demand);

  const revenueOverviewData = {
    labels: labels,
    datasets: [
      {
        label: 'Trends',
        data: chartData,
        borderColor: '#222222',
        borderWidth: 1.5,
        fill: false,
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: '#222222',
        pointRadius: 1.5,
        tension: 0.2,
      },
    ],
  };

  const formatYAxisTick = (tickValue: number) => {
    if (tickValue >= 1_000_000) {
      return `${(tickValue / 1_000_000).toFixed(1)}M`;
    } else if (tickValue >= 1_000) {
      return `${(tickValue / 1_000).toFixed(1)}K`;
    } else {
      return tickValue.toString();
    }
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return formatYAxisTick(tickValue);
            }
            return tickValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    // import { downloadImage,downloadXLS } from 'utils/download'
    <div className="bg-light rounded-2xl shadow-sm mt-8" id='searchTrendsOverTime'>
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">Search Trends Over Time</h2>
          <h3 className="text-gray800 text-sm font-bold mb-2 pl-4">Evolution of your category</h3>
        </div>
        <div className="relative" ref={dropdownRef} id='downloadDropdownMenu-searchTrendsOverTime'>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-4 mt-2 w-48 bg-light text-sm text-gray700 rounded-md z-50" style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}>
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => { closeDropdown(); downloadXLS('searchTrendsOverTime',['date','demand'],revenueOverviewData.labels, revenueOverviewData.datasets.map(item => item.data) ) }}>Download XLS</li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => { closeDropdown(); downloadImage('searchTrendsOverTime'); }}>Download PNG image</li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]">
          <Line data={revenueOverviewData} options={options} />
        </div>
        <div className="flex flex-col justify-center items-center pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <div className="mb-4 text-center">
            <h4 className="text-thirdgray text-sm">Demand</h4>
            <p className="text-[32px] text-primarygray">{grandTotalDemand ? formatNumber(grandTotalDemand)  : 'N/A'}</p>
          </div>
          <div className="mb-4 text-center">
            <h4 className="text-thirdgray text-sm">Unique Query Count</h4>
            <p className="text-[32px] text-primarygray">{uniqueQueryCount ? formatNumber(uniqueQueryCount.total_unique_query_count) : 'N/A'}</p>
          </div>
          <div className="mb-4 text-center">
            <h4 className="text-thirdgray text-sm">YoY Growth</h4>
            <p className="text-[32px] text-primarygray">{yoyGrowthDemand ? (yoyGrowthDemand.demand_growth_ratio * 100).toFixed(2) + '%' : 'N/A'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchTerms;