import React from 'react';
import Consumer_int from './Configuration_comp/Consumer_int';
import Competitive_int from './Configuration_comp/Competitive_int';
import AutoOptimize from './Configuration_comp/AutoOptimize';
import Insights from './Configuration_comp/Insights'

interface ConfigurationProps {
  activeModules: boolean[];
  customerId: string | null;
  setTriggerConsumerUpload: React.Dispatch<React.SetStateAction<() => Promise<void>>>;
  setTriggerCompetitiveUpload: React.Dispatch<React.SetStateAction<() => Promise<void>>>;
  setTriggerAutoOptimizeUpload: React.Dispatch<React.SetStateAction<() => Promise<void>>>;
  setTriggerInsightsUpload: React.Dispatch<React.SetStateAction<() => Promise<void>>>;
  setHasUploadedFileConInt: React.Dispatch<React.SetStateAction<boolean>>;
  setHasUploadedFileComInt: React.Dispatch<React.SetStateAction<boolean>>;
  setHasUploadedFileOSAB: React.Dispatch<React.SetStateAction<boolean>>;
  setHasUploadedFileInsights: React.Dispatch<React.SetStateAction<boolean>>;
}

const Configuration: React.FC<ConfigurationProps> = ({ activeModules, customerId, setTriggerConsumerUpload, setTriggerCompetitiveUpload, setTriggerAutoOptimizeUpload, setTriggerInsightsUpload, setHasUploadedFileConInt, setHasUploadedFileComInt, setHasUploadedFileOSAB, setHasUploadedFileInsights }) => {
  return (
    <>
      <div>
        <h2 className="text-[32px] font-bold">Configuration</h2>
        <h3 className="font-bold text-grayscale70 mt-2">General settings for each module’s essential parameters.</h3>
        {customerId && <p>Customer ID: {customerId}</p>}
      </div>
      {activeModules[2] && <Consumer_int customerId={customerId} setTriggerConsumerUpload={setTriggerConsumerUpload} setHasUploadedFileConInt={setHasUploadedFileConInt} />}
      {activeModules[1] && <Competitive_int customerId={customerId} setTriggerCompetitiveUpload={setTriggerCompetitiveUpload} setHasUploadedFileComInt={setHasUploadedFileComInt} />}
      {activeModules[3] && <AutoOptimize customerId={customerId} setTriggerAutoOptimizeUpload={setTriggerAutoOptimizeUpload} setHasUploadedFileOSAB={setHasUploadedFileOSAB} />}
      {activeModules[0] && <Insights customerId={customerId} setTriggerInsightsUpload={setTriggerInsightsUpload} setHasUploadedFileInsights={setHasUploadedFileInsights} />}
    </>
  );
};

export default Configuration;
