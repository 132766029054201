import { AxiosError } from 'axios';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { fetchStudyList, downloadCompIntelReport } from '../../api';

// COMPONENTS
import FilterBarWrapper from '../FilterBarWrapper';
import Dropdown, { DropdownOption } from '../Dropdown';
import DatepickerComponent from '../DatepickerComponent';

// TYPES
import { DateValueType } from 'react-tailwindcss-datepicker';
import { CompIntelReportParams, CompIntelReportType } from '../../types';

// UTILS
import { formatDateRange } from 'utils';

const REPORT_OPTIONS: DropdownOption<CompIntelReportType>[] = [
  { label: 'Battlefield', value: 'battlefield' },
  { label: 'Brands', value: 'brands' },
  { label: 'Content', value: 'content' },
  { label: 'Search Term', value: 'search_term' },
  { label: 'SERP Visibility', value: 'serp_visibility' },
];

const maxDate = new Date();
maxDate.setDate(maxDate.getDate() - 1);

const startDate = new Date();
startDate.setDate(maxDate.getDate() - 6);

const idPrefix = 'comp_intel-adhoc-report';

const defaultDateRange: DateValueType = {
  startDate,
  endDate: maxDate,
};

// COMPETITIVE INTEL REPORTS TAB/PAGE
function CompIntelReports() {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [study, setStudy] = useState<DropdownOption | null>(null);
  const [reportType, setReportType] = useState<
    DropdownOption<CompIntelReportType>
  >(REPORT_OPTIONS[0]);
  const [dateRange, setDateRange] = useState<DateValueType | null>(
    defaultDateRange
  );

  const { data: studyList, isLoading: isLoadingStudies } = useQuery(
    ['studyList'],
    () => fetchStudyList(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading: isLoadingReport } = useMutation({
    mutationFn: (params: CompIntelReportParams) =>
      downloadCompIntelReport(params),
    onError: async (error: unknown) => {
      let errorMessage = 'Unable to download report.';
      if (error instanceof Error) {
        errorMessage = error.message || errorMessage;
      }
      if (error instanceof AxiosError) {
        const jsonStr = await error.response?.data.text();
        errorMessage = JSON.parse(jsonStr).message || errorMessage;
      }
      setErrorMsg(errorMessage);
    },
  });

  const isLoading = isLoadingReport || isLoadingStudies;
  const isContentReport = reportType.value === 'content';

  const studyOptions = useMemo(() => {
    const options: DropdownOption[] = [];

    for (const study of studyList || []) {
      options.push({
        label: study.study_name,
        value: study.study_id.toString(),
      });
    }
    if (!study) {
      setStudy(options[0] || null);
    }
    return options;
  }, [studyList, study, setStudy]);

  // HANDLE REPORT SELECTION
  const onChangeReport = (report: DropdownOption<CompIntelReportType>) => {
    if (errorMsg) {
      setErrorMsg(null);
    }
    setReportType(report);
  };

  // HANDLE DATE PICKER SELECTIONS
  const onChangeDatePicker = (newValue: DateValueType) => {
    const formattedDateRange = formatDateRange(newValue);

    setDateRange(formattedDateRange);
  };

  const onSubmitForm = (evt: React.FormEvent) => {
    evt.preventDefault();
    if (errorMsg) {
      setErrorMsg(null);
    }
    if (!study?.label) {
      throw new Error('Study selection required.');
    }
    mutate({
      studyName: study.label,
      reportType: reportType.value,
      dateRange,
    });
  };

  // JSX
  return (
    <form
      id={idPrefix + '__form'}
      className="flex flex-col gap-8 pt-12"
      onSubmit={onSubmitForm}
    >
      {errorMsg ? (
        <div className="text-center text-error text-sm -mb-8">{errorMsg}</div>
      ) : null}
      <div>
        <FilterBarWrapper
          subText={isContentReport ? '* max date range of 7 days' : undefined}
        >
          <div className="flex justify-between w-full">
            <Dropdown
              title={`Report: ${reportType.label}`}
              onOptionClick={onChangeReport}
              options={REPORT_OPTIONS}
              dropdownLength="h-42"
            />
            <Dropdown
              title={`Study: ${study?.label}`}
              onOptionClick={(selected) => setStudy(selected)}
              options={studyOptions}
              dropdownWidth={'min-w-96'}
              dropdownOuterWidth={'max-w-96'}
            />
            <div className="flex gap-2">
              <DatepickerComponent
                onChange={onChangeDatePicker}
                value={dateRange}
                product="comp_int"
                maxDate={maxDate}
              />
              {isContentReport ? <span className="text-gray300">*</span> : null}
            </div>
          </div>
        </FilterBarWrapper>
      </div>
      <div className="flex justify-center">
        <button
          id={idPrefix + '__btn-submit'}
          type="submit"
          className="text-sm font-bold py-2 px-4 rounded-full items-center lg:flex bg-success text-light w-24 h-10 block"
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="flex justify-center w-full">
              <div className="animate-spin rounded-full h-5 w-5 border-t-4 border-b-4 border-light"></div>
            </div>
          ) : (
            'Download'
          )}
        </button>
      </div>
    </form>
  );
}

export default CompIntelReports;
