import React, { useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import CustomLegend from './CustomLegend';
import { ChartOptions } from 'chart.js';
import { useQuery } from 'react-query';
import LoadingSpinner from './LoadingSpinner';
import { downloadImage,downloadXLS } from 'utils/download'

interface Cluster2Props {
  startDate: string;
  endDate: string;
}

interface APIResponse {
  per_brand: {
    brand_type: string;
    organic_revenue: number;
    paid_revenue: number;
    organic_transactions: number;
    paid_transactions: number;
    organic_revenue_perc: number;
    paid_revenue_perc: number;
    organic_transactions_perc: number;
    paid_transactions_perc: number;
  }[];
}

const Cluster2: React.FC<Cluster2Props> = ({ startDate, endDate }) => {
  const [chartData, setChartData] = useState({
    labels: [] as string[],
    datasets: [
      {
        label: 'Paid Revenue',
        data: [] as number[],
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderWidth: 1,
        borderRadius: 8,
        barThickness: 24,
      },
      {
        label: 'Organic Revenue',
        data: [] as number[],
        backgroundColor: 'rgba(144, 144, 144, 0.80)',
        borderWidth: 1,
        borderRadius: 8,
        barThickness: 24,
      },
    ],
  });
  const [fullLabels, setFullLabels] = useState<string[]>([]);
  const [shortLabels, setShortLabels] = useState<string[]>([]);
  const [showFullLabels, setShowFullLabels] = useState(false);
  const chartRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const fetchRevenueData = async (): Promise<APIResponse> => {
    const response = await fetch(`/get_cluster_data/per_brand/${startDate}/${endDate}`);
    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.error === "'json_data'") {
        throw new Error('No data for this time period, please change the date range');
      }
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  };

  const { data, isLoading, isError } = useQuery(['revenueSummaryData', startDate, endDate], fetchRevenueData);

  const isAllNumbersZero = (data: APIResponse): boolean => {
    return data.per_brand.every(item =>
      item.organic_revenue_perc === 0 &&
      item.paid_revenue_perc === 0
    );
  };

  useEffect(() => {
    if (data) {
      if (isAllNumbersZero(data)) {
        return;
      }

      const labels = data.per_brand.map(item => item.brand_type);
      const shortLabels = labels.map(label => label.length > 15 ? label.substring(0, 15) + '...' : label);
      const paidRevenueData = data.per_brand.map(item => item.paid_revenue_perc);
      const organicRevenueData = data.per_brand.map(item => item.organic_revenue_perc);
      const chartWidth = (chartRef.current as HTMLElement | null)?.offsetWidth || 800;
      const calculatedBarThickness = Math.max(10, chartWidth / (labels.length * 2));

      setFullLabels(labels);
      setShortLabels(shortLabels);
      setChartData({
        labels: shortLabels,
        datasets: [
          {
            label: 'Paid Revenue',
            data: paidRevenueData,
            backgroundColor: 'rgba(34, 34, 34, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThickness,
          },
          {
            label: 'Organic Revenue',
            data: organicRevenueData,
            backgroundColor: 'rgba(144, 144, 144, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThickness,
          },
        ],
      });
    }
  }, [data]);

  const calculateChartHeight = (labels: string[]) => {
    const labelLength = labels.reduce((max, label) => Math.max(max, label.length), 0);
    const baseHeight = 295;
    const extraHeight = labelLength * 5;
    return baseHeight + extraHeight;
  };

  const toggleLabels = () => {
    setShowFullLabels(!showFullLabels);
  };

  const labelsToShow = showFullLabels ? fullLabels : shortLabels;
  const chartHeight = calculateChartHeight(labelsToShow);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [])

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            const index = context[0].dataIndex;
            return fullLabels[index];
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Brand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 90,
          minRotation: 45,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Percentage (%)',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: function (value) {
            return value + '%';
          },
          stepSize: 25,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <div>No data for this time period, please change the date range</div>;
  }

  if (data && isAllNumbersZero(data)) {
    return null;
  }

  const downloadChartData = () => {
    const headers = ['Brand', ...chartData.datasets.map(dataset => dataset.label as string)];
    const labels = fullLabels as string[];
    const columns = chartData.datasets.map(dataset => dataset.data as number[]);
  
    downloadXLS('revenue_per_brand', headers, labels, columns);
  };

  const downloadChartImage = () => {
    downloadImage('revenue_per_brand');
  };

  return (
    <div id="revenue_per_brand" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-2 pl-4 pt-2">Revenue per Brand</h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">Understand evolution of holistic search revenue per brand</h3>
        </div>
        <div className="flex items-center mr-4 text-xs">
          <span className={showFullLabels ? 'text-primarygray mr-2' : 'text-gray400 mr-2'}>Short</span>
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              checked={showFullLabels}
              onChange={toggleLabels}
            />
            <div className="relative w-10 h-5 bg-lightblue rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
          </label>
          <span className={showFullLabels ? 'text-primarygray ml-2' : 'text-gray400 ml-2'}>Full</span>
          <div className='flex items-center' ref={dropdownRef} id='downloadDropdownMenu-revenue_per_brand'>
            <img
              src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
              alt="dots"
              className="h-6 w-6 ml-2 cursor-pointer"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50" style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}>
                <ul>
                  {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                  <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        downloadChartData();
                        closeDropdown();
                      }}
                  >
                      Download XLS
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartImage();
                      closeDropdown();
                    }}
                  >
                    Download PNG image
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[90%] xl:w-[90%] md:w-[90%] w-[90%]" style={{ height: chartHeight }}>
          <Bar data={{ ...chartData, labels: labelsToShow }} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <CustomLegend legendSet="set4" />
        </div>
      </div>
    </div>
  );
};

export default Cluster2;
