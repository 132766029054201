import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

let baseURL: string = '';
export const redirectURI: string = '/lion_home';

const isIframe = window.self !== window.top;

export const currentENV = () => {
  switch (process.env.REACT_APP_API_LOCAL_BASE_URL) {
    case 'https://onesearch.performics.com':
      return 'prod';

    case 'https://qa.onesearch.performics.com':
      return 'qa';

    default:
      return 'local';
  }
};

export const isLocalEnv =
  process.env.REACT_APP_API_LOCAL_BASE_URL?.includes('localhost') || isIframe;

if (process.env.REACT_APP_API_LOCAL_BASE_URL) {
  baseURL = process.env.REACT_APP_API_LOCAL_BASE_URL;
}

if (!baseURL) {
  console.log(`ERROR: no baseURL set, exiting`);
  process.exit(1);
}

export const client = axios.create({
  baseURL: baseURL,
});
