import { useState, useEffect, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// COPONENTS
import UsersManagement from './SettingsComp/UsersManagement';
import AccountsManagement from './SettingsComp/AccountsManagement';

// TYPES
import { User } from 'protectedRoute';

interface SettingsPageProps {
  setTopBarButton: (button: ReactNode) => void;
  loggedInUser: User | null;
}

function SettingsPage({ setTopBarButton, loggedInUser }: SettingsPageProps) {
  const [activeTab, setActiveTab] = useState<string>('AccountsManagement');
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnboardNewClick = () => {
    setTopBarButton(null);
    navigate('/onboard');
  };

  const handleAddNewUserClick = () => {
    const addUserEvent = new CustomEvent('addNewUser');
    window.dispatchEvent(addUserEvent);
  };

  useEffect(() => {
    if (location.pathname === '/settings') {
      const button =
        activeTab === 'AccountsManagement' ? (
          <button
            className="text-sm font-bold py-2 px-4 rounded-full items-center hidden lg:flex bg-success text-light"
            onClick={handleOnboardNewClick}
          >
            <>
              <img
                src={`${process.env.PUBLIC_URL}/static/img/plus_icon.svg`}
                alt="plus_icon"
                className="w-[18px] h-[18px] mr-2"
              />
              ONBOARD NEW
            </>
          </button>
        ) : (
          <button
            className="text-sm font-bold py-2 px-4 rounded-full items-center hidden lg:flex bg-success text-light"
            onClick={handleAddNewUserClick}
          >
            <>
              <img
                src={`${process.env.PUBLIC_URL}/static/img/plus_icon.svg`}
                alt="plus_icon"
                className="w-[18px] h-[18px] mr-2"
              />
              ADD NEW USER
            </>
          </button>
        );

      setTopBarButton(button);
    } else {
      setTopBarButton(null);
    }

    // Cleanup function to clear the button when unmounting
    return () => {
      setTopBarButton(null);
    };
  }, [location.pathname, setTopBarButton, activeTab]);

  const renderContent = () => {
    return activeTab === 'AccountsManagement' ? (
      <AccountsManagement
        setTopBarButton={setTopBarButton}
        loggedInUser={loggedInUser}
      />
    ) : (
      <UsersManagement loggedInUser={loggedInUser} />
    );
  };

  const renderMenu = () => (
    <div className="sticky top-[94px] z-10 flex bg-body border-b border-gray300 whitespace-nowrap">
      <div
        className={`text-primarygray font-medium cursor-pointer py-4 px-8 ${
          activeTab === 'AccountsManagement'
            ? 'border-b-2 border-primarygray'
            : ''
        }`}
        onClick={() => {
          setActiveTab('AccountsManagement');
        }}
      >
        Accounts Management
      </div>
      <div
        className={`text-primarygray font-medium cursor-pointer py-4 px-8 ${
          activeTab === 'UsersManagement' ? 'border-b-2 border-primarygray' : ''
        }`}
        onClick={() => {
          setActiveTab('UsersManagement');
        }}
      >
        Users Management
      </div>
    </div>
  );

  return (
    <div className="flex flex-col">
      {renderMenu()}
      <div className="flex-1">{renderContent()}</div>
    </div>
  );
}

export default SettingsPage;
