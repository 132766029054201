import * as XLSX from 'xlsx';
import { AxiosResponse } from 'axios';
import html2canvas from 'html2canvas';

export const downloadImage = (divIdToDownload: string) => {
  const elementToCapture = document.getElementById(divIdToDownload);
  const dropdownMenu = document.getElementById(
    `downloadDropdownMenu-${divIdToDownload}`
  );

  // Temporarily hide the dropdown menu
  if (dropdownMenu) {
    dropdownMenu.style.display = 'none';
  }

  if (elementToCapture) {
    html2canvas(elementToCapture)
      .then((canvas) => {
        const image = canvas.toDataURL('image/png');

        const link = document.createElement('a');
        link.download = `${divIdToDownload}.png`;
        link.href = image;
        link.click();

        // Re-display the dropdown menu after capture
        if (dropdownMenu) {
          dropdownMenu.style.display = '';
        }
      })
      .catch((error) => {
        console.error('Error capturing the div:', error);

        if (dropdownMenu) {
          dropdownMenu.style.display = '';
        }
      });
  } else {
    console.error(
      'Element with the specified ID was not found:',
      divIdToDownload
    );
  }
};

export type DownloadXLSParams = {
  filename: string;
  headers: Array<string>;
  labels: Array<string>;
  columns: Array<Array<number | string>>;
};

export const downloadXLS = (
  fileName: string,
  headers: Array<string>,
  labels: Array<string>,
  columns: Array<Array<number | string>>
) => {
  // console.log(columns)
  // const workbook = XLSX.utils.book_new();
  // const worksheet = XLSX.utils.aoa_to_sheet([headers, ...columns]);

  // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // XLSX.writeFile(workbook, `${fileName}.xlsx`);

  const workbook = XLSX.utils.book_new();

  const rows = labels.map((label, index) => {
    const rowData = columns.map((column) => column[index] ?? '');
    return [label, ...rowData]; // Combine label with the row data
  });

  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export const saveDownloadFile = (response: AxiosResponse, fileName: string) => {
  const href = URL.createObjectURL(response.data);
  const link = document.createElement('a');

  link.href = href;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
