import React, { useState, useEffect, useRef } from 'react';
import Dropdown from '../../Dropdown';
import { AdsData, Ad } from '../ContentAnalysis';
import { downloadImage, downloadXLS } from 'utils/download';

const TopAds: React.FC<AdsData> = ({ data }) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Ad | null;
    direction: 'ascending' | 'descending';
  }>({ key: null, direction: 'ascending' });
  const [selectedFilter, setSelectedFilter] = useState<
    'PAID' | 'PLA' | 'ORGANIC'
  >('ORGANIC');
  const [selectedBrand, setSelectedBrand] = useState<string>('All Competitors');
  const [topAdsData, setTopAdsData] = useState<Ad[]>(data[selectedFilter]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const headers: { key: keyof Ad; label: string }[] = [
    { key: 'ad_type', label: 'AD TYPE' },
    { key: 'title', label: 'AD' },
    { key: 'avg_impression_share', label: 'IMPRESSION %' },
    { key: 'avg_position', label: 'POSITION (RANK)' },
    { key: 'search_terms', label: 'KEYWORDS' },
    { key: 'times_seen', label: 'SEEN' },
  ];

    // Update topAdsData when data or selectedFilter changes
    useEffect(() => {
      const filteredData =
        selectedBrand === 'All Competitors'
          ? data[selectedFilter]
          : data[selectedFilter].filter((ad) => ad.brand === selectedBrand);
      setTopAdsData(filteredData);
    }, [data, selectedFilter, selectedBrand]);

  const brands = Array.from(
    new Set(data[selectedFilter].map((ad) => ad.brand))
  );
  brands.unshift('All Competitors');

  const handleSort = (key: keyof Ad) => {
    let direction: 'ascending' | 'descending' = 'ascending';

    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = [...topAdsData].sort((a, b) => {
      const aValue = a[key] ?? '';
      const bValue = b[key] ?? '';

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return direction === 'ascending' ? aValue - bValue : bValue - aValue;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return direction === 'ascending'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return 0;
    });

    setSortConfig({ key, direction });
    setTopAdsData(sortedData);
  };

  const handleFilterClick = (filter: 'PAID' | 'PLA' | 'ORGANIC') => {
    setSelectedFilter(filter);
    const filteredData =
      selectedBrand === 'All Competitors'
        ? data[filter]
        : data[filter].filter((ad) => ad.brand === selectedBrand);
    setTopAdsData(filteredData);
  };

  const handleBrandChange = (brand: string) => {
    setSelectedBrand(brand);
    const filteredData =
      brand === 'All Competitors'
        ? data[selectedFilter]
        : data[selectedFilter].filter((ad) => ad.brand === brand);
    setTopAdsData(filteredData);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const downloadChartImage = () => {
    downloadImage('TopAds');
  };

  const downloadData = () => {
    const headers = [
      'AD TITLE',
      'AD TYPE',
      'BRAND',
      'URL',
      'DESCRIPTION',
      'AV IMPRESSION %',
      'AV POSITION (RANK) (%) ',
      'KEYWORDS',
      'SEEN',
      'MAX IMPRESSION SHARE (%)',
      'MAX POSITION (%)',
      'MIN IMPRESSION SHARE (%)',
      'MIN POSITION (%)',
    ];
    const labels = topAdsData.map((ad) => ad.title);
    const columns = [
      topAdsData.map((ad) => ad.ad_type),
      topAdsData.map((ad) => ad.brand),
      topAdsData.map((ad) => ad.url),
      topAdsData.map((ad) => ad.description?.toString() ?? ''),
      topAdsData.map((ad) => `${(ad.avg_impression_share * 100)}%`),
      topAdsData.map((ad) => `${(ad.avg_position * 100)}%`),
      topAdsData.map((ad) => `${ad.search_terms} keyword(s)`),
      topAdsData.map((ad) => ad.times_seen),
      topAdsData.map((ad) => `${(ad.max_impression_share * 100)}%`),
      topAdsData.map((ad) => `${(ad.max_position * 100)}%`),
      topAdsData.map((ad) => `${(ad.min_impression_share * 100)}%`),
      topAdsData.map((ad) => `${(ad.min_position * 100)}%`),
    ];

    downloadXLS(`TopAds_${selectedBrand}_${selectedFilter}`, headers, labels, columns);
  };

  return (
    <div id="TopAds" className="bg-light rounded-2xl shadow-sm mt-8 pb-6">
      <div className="flex flex-col lg:flex-row justify-between px-4 pt-2 border-b border-success pb-2">
        <div className="mb-4 lg:mb-0">
          <h2 className="text-primarygray text-2xl font-bold mb-2">Top Ads</h2>
          <h3 className="text-primarygray text-sm font-bold">
            Monitor top ads of chosen competitors
          </h3>
        </div>
        <div className="flex flex-col lg:flex-row items-center w-full lg:w-auto" ref={dropdownRef} id='downloadDropdownMenu-TopAds'>
          <Dropdown
            title={selectedBrand}
            options={brands}
            onOptionClick={handleBrandChange}
            dropdownLength='max-h-44'
          />
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 lg:ml-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50" style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}>
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadData();
                    closeDropdown();
                  }}
                >
                  Download XLS
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="overflow-x-auto mx-4 px-1">
        <div className="flex flex-wrap gap-2 mt-6">
          {(['PAID', 'PLA', 'ORGANIC'] as const).map((filter) => (
            <span
              key={filter}
              className={`text-thirdgray text-sm rounded px-3 py-1 cursor-pointer ${
                selectedFilter === filter
                  ? 'border border-success rounded-lg'
                  : 'border border-disgreen100 rounded'
              }`}
              onClick={() => handleFilterClick(filter)}
            >
              {filter === 'PLA' ? 'SHOPPING' : filter}{' '}
              {selectedFilter === filter && <span className="ml-1 ">✕</span>}
            </span>
          ))}
        </div>
        <table className="min-w-full bg-light mt-4">
          <thead className="uppercase">
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  className="py-2 px-1 text-primarygray text-xs font-normal text-left whitespace-nowrap"
                >
                  {header.label}
                  <img
                    src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`}
                    alt="sort icon"
                    className="inline ml-1 cursor-pointer"
                    onClick={() => handleSort(header.key)}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {topAdsData.length > 0 ? (
              topAdsData.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={
                    rowIndex % 2 === 0 ? 'bg-fourthgray' : 'bg-gray100'
                  }
                >
                  <td className="py-2 px-4 md:px-6 text-gray800 text-xs font-normal text-left">
                    {row.ad_type}
                  </td>
                  <td className="p-2 text-gray800 text-xs font-normal text-left max-w-52">
                    <div className="font-medium text-sm">{row.title}</div>
                    <div className="font-medium truncate underline">
                      <a href={row.url} className="text-gray800">
                        {row.url}
                      </a>
                    </div>
                    <div className="max-w-[320px] break-words 2xl:max-w-none 2xl:break-normal">
                      {row.description}
                    </div>
                  </td>
                  <td className="p-2 text-gray800 text-xs font-normal text-left">
                    <div>
                      <div>
                        Highest: {(row.max_impression_share * 100).toFixed(1)}%
                      </div>
                      <div>
                        Lowest: {(row.min_impression_share * 100).toFixed(1)}%
                      </div>
                      <div className="font-bold">
                        Average: {(row.avg_impression_share * 100).toFixed(1)}%
                      </div>
                    </div>
                  </td>
                  <td className="p-2 text-primarygray text-xs font-normal text-left">
                    <div>
                      <div>Highest: {(row.max_position * 100).toFixed(1)}</div>
                      <div>Lowest: {(row.min_position * 100).toFixed(1)}</div>
                      <div className="font-bold">
                        Average: {(row.avg_position * 100).toFixed(1)}
                      </div>
                    </div>
                  </td>
                  <td className="p-2 text-gray800 text-xs font-bold text-left">
                    {row.search_terms} keyword(s)
                  </td>
                  <td className="p-2 text-gray800 text-xs font-normal text-left">
                    {row.times_seen}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={6}
                  className="text-center py-10 text-secondarygray text-3xl font-bold bg-fourthgray rounded mx-4"
                >
                  No ads found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopAds;
