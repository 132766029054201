import { useQuery } from 'react-query';
import { useCmpIntContext } from 'hooks';
import { useState, useRef, useEffect } from 'react';

// API REQUESTS
import { fetchCompBFData, fetchCompetitorGroups } from 'api';

// COMPONENTS
import MultiDropdown from '../MultiDropdown';
import OutletSpinner from '../OutletSpinner';
import LoadingSpinner from '../LoadingSpinner';
import FilterBarWrapper from '../FilterBarWrapper';
import BattleField from './BFComponents/BattleField';
import DatepickerComponent from '../DatepickerComponent';
import BrandFocusBarChart from './BFComponents/BrandFocusBarChart';
import BrandPresenceChart from './BFComponents/BrandPresenceChart';
import BFLocationAnalysis from './BFComponents/BFLocationAnalysis';
import SearchTermsOverlap from './BFComponents/SearchTermsOverlap';
import BFPaidVsOrganicVsPLA from './BFComponents/BFPaidVsOrganicVsPLA';
import TimeFramePicker, { TimeFrameDateRange } from '../TimeFramePicker';
import BrandFocusDoughnutChart from './BFComponents/BrandFocusDoughnutChart';

// TYPES
import {
  BFOutput,
  BDomainData,
  BFInputData,
  BPDomainData,
  CDDomainData,
  DomainDataAC,
  DomainDataAP,
  LADomainData,
  POPDomainData,
  CompetitorGroup,
  BrandFocusTabData,
  BrandFocusInnerDataBar,
  BrandFocusInnerDataDoughnut,
} from '../../types';
import { DateValueType } from 'react-tailwindcss-datepicker';

// UTILS
import { downloadImage, downloadXLS } from 'utils/download';

const xlsGetAverageCoverage = (data: BFInputData): BFOutput => {
  const websites: string[] = [];
  const devices: string[] = [];
  const types: string[] = [];
  const coverages: string[] = [];
  const percentageDiffs: string[] = [];

  const { average_coverage, calculated_diff } = data;

  Object.keys(average_coverage).forEach((type) => {
    Object.keys(average_coverage[type]).forEach((device) => {
      Object.keys(average_coverage[type][device]).forEach((website) => {
        const coverage = average_coverage[type][device][website]; // Correctly access the coverage
        websites.push(website);
        devices.push(device);
        types.push(type);
        coverages.push(String(coverage));

        const websiteDiff = calculated_diff?.[website];
        let percentage = '0';

        if (websiteDiff && typeof websiteDiff === 'object') {
          const deviceDiff = websiteDiff[device];
          if (deviceDiff && typeof deviceDiff === 'object') {
            percentage = String(deviceDiff[type] ?? '0');
          }
        }

        percentageDiffs.push(percentage);
      });
    });
  });

  const output: BFOutput = {
    label: websites,
    columns: [devices, types, coverages, percentageDiffs],
  };

  return output;
};

const xlsGetAveragePosition = (data: BFInputData): BFOutput => {
  const websites: string[] = [];
  const devices: string[] = [];
  const types: string[] = [];
  const position1: string[] = []; // For the first position
  const position2: string[] = []; // For the second position (if available)
  const position3: string[] = []; // For the third position (if available)

  const { average_position } = data;

  Object.keys(average_position).forEach((type) => {
    Object.keys(average_position[type]).forEach((device) => {
      Object.keys(average_position[type][device]).forEach((website) => {
        const positionData = average_position[type][device][website];

        const position1Value = positionData[0] ?? '0';
        const position2Value = positionData[1] ?? '0';
        const position3Value = positionData[2] ?? '0';

        websites.push(website);
        devices.push(device);
        types.push(type);

        position1.push(String(position1Value));
        position2.push(String(position2Value));
        position3.push(String(position3Value));
      });
    });
  });

  const output: BFOutput = {
    label: websites,
    columns: [devices, types, position1, position2, position3],
  };

  return output;
};

const DEFAULT_DATA: BrandFocusTabData = {
  battlefield: {
    desktop: {},
    mobile: {},
  },
  brand_focus: {
    average_coverage: {},
    average_position: {},
    calculated_diff: {},
  },
  brand_presence: {},
  location_analysis: {
    desktop: {},
    mobile: {},
  },
  paid_vs_organic_vs_pla: {
    desktop: {},
    mobile: {},
  },
};

// COMP. INTEL BRAND FOCUS TAB/PAGE
function CompIntelBrandFocus() {
  const {
    clientDomain,
    searchOptions,
    selectedSearchOptions,
    onSelectSearchTerms,
    onSelectSearchGroups,
  } = useCmpIntContext();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string>('PAID');
  const [dateRange, setDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({ startDate: null, endDate: null });

  const [competitorGroupOptions, setCompetitorGroupOptions] = useState<
    CompetitorGroup[]
  >([]);
  const [selectedGroupOptions, setSelectedGroupOptions] = useState<string[]>(
    []
  );
  const [competitorOptions, setCompetitorOptions] = useState<string[]>([]);
  const [preselectedCompetitors, setPreselectedCompetitors] = useState<
    string[]
  >([]);

  const [filteredData, setFilteredData] = useState<BrandFocusTabData>({
    ...DEFAULT_DATA,
  });

  const { data: compGroupsData, isLoading: isLoadingCompGroups } = useQuery(
    ['competitorGroupsData'],
    () => fetchCompetitorGroups(),
    {}
  );

  const { data: compData, isLoading: isLoadingCompData } = useQuery(
    [
      'compDataBrandFocus',
      dateRange?.startDate,
      dateRange?.endDate,
      selectedSearchOptions.terms,
    ],
    () =>
      fetchCompBFData(
        dateRange,
        preselectedCompetitors.join(','),
        selectedSearchOptions.terms
      ),
    { enabled: !!dateRange, staleTime: 1000 * 60 * 60 }
  );

  useEffect(() => {
    if (
      compGroupsData &&
      !isLoadingCompGroups &&
      compData &&
      !isLoadingCompData
    ) {
      const { competitorGroups, allDomains } = compGroupsData;

      setCompetitorGroupOptions(competitorGroups);
      setCompetitorOptions([...allDomains]);
      if (preselectedCompetitors.length === 0) {
        setPreselectedCompetitors([...allDomains]);
      } else if (!preselectedCompetitors.includes(clientDomain)) {
        setPreselectedCompetitors([clientDomain, ...allDomains]);
      } else if (
        preselectedCompetitors.includes(clientDomain) &&
        preselectedCompetitors.length === 1
      ) {
        setPreselectedCompetitors([...allDomains]);
      }

      if (clientDomain.length === 0 && compData) {
        const topCompetitors = getTopCompetitorsByCoverage(compData, 10);
        setPreselectedCompetitors(topCompetitors);
      }

      const initialSelectedGroups = competitorGroups.map(
        (group) => `${group.group_name}`
      );
      setSelectedGroupOptions(initialSelectedGroups);
    }
  }, [
    compGroupsData,
    isLoadingCompGroups,
    compData,
    isLoadingCompData,
    clientDomain,
  ]);

  // Function to get the top N competitors by coverage
  const getTopCompetitorsByCoverage = (
    data: BrandFocusTabData,
    limit: number
  ): string[] => {
    const allCompetitors = Object.keys(data.brand_presence);

    const sortedCompetitors = allCompetitors
      .map((competitor) => {
        const coverageData = data.brand_presence[competitor];
        return { competitor, coverage: coverageData.coverage || 0 };
      })
      .sort((a, b) => b.coverage - a.coverage)
      .slice(0, limit)
      .map(({ competitor }) => competitor);

    return sortedCompetitors;
  };

  useEffect(() => {
    if (clientDomain && !preselectedCompetitors.includes(clientDomain)) {
      setPreselectedCompetitors((prev) => [...prev, clientDomain]);
    }
  }, [clientDomain, preselectedCompetitors]);

  const handleCompetitorGroupApply = (groups: string[]) => {
    let selectedCompetitors: string[] = [];
    let selectedGroups: string[] = [];

    groups.forEach((group_name) => {
      const matchedGroup = competitorGroupOptions.find(
        (g) => g.group_name === group_name
      );
      if (matchedGroup) {
        selectedCompetitors = [
          ...selectedCompetitors,
          ...matchedGroup.domains.split(','),
        ];
        selectedGroups = [...selectedGroups, ...[group_name]];
      }
    });

    setPreselectedCompetitors(selectedCompetitors);
    setSelectedGroupOptions(selectedGroups);
  };

  const { data, error, isLoading } = useQuery(
    [
      'compDataBrandFocus',
      dateRange?.startDate,
      dateRange?.endDate,
      selectedSearchOptions.terms,
    ],
    () =>
      fetchCompBFData(
        dateRange,
        competitorOptions.slice(0, 3).join(','),
        selectedSearchOptions.terms
      )
  );

  useEffect(() => {
    setCompetitorOptions(
      Array.from(
        new Set([
          ...(data?.unique_brands || []),
          ...(compGroupsData?.allDomains || []),
        ])
      )
    );
  }, [data, compGroupsData]);

  useEffect(() => {
    if (preselectedCompetitors.length > 0) {
      if (data) {
        const newData = filterDataBasedOnDropdown(data, preselectedCompetitors);
        setFilteredData(newData);
      }
    } else {
      setFilteredData({ ...DEFAULT_DATA });
    }
  }, [data, preselectedCompetitors]);

  // HANDLE TIME FRAME SELECTION
  const onChangeTimeFrame = (timeFrame: TimeFrameDateRange) => {
    setDateRange(timeFrame);
  };

  // HANDLE DATE PICKER SELECTIONS
  const onChangeDatePicker = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      setDateRange({
        startDate: new Date(newValue.startDate),
        endDate: new Date(newValue.endDate),
      });
    }
  };

  const filterButtonClasses = (filter: string) =>
    `text-thirdgray rounded px-3 py-1 cursor-pointer ${
      selectedFilter === filter
        ? 'border border-success rounded-lg'
        : 'border border-disgreen100 rounded'
    }`;

  const toggleFilter = (filter: string) => {
    setSelectedFilter(filter);
  };

  const arrowSrc = (isPositive: boolean) =>
    isPositive
      ? `${process.env.PUBLIC_URL}/static/img/Arrow_positive.svg`
      : `${process.env.PUBLIC_URL}/static/img/Arrow_red.svg`;

  const changeColor = (isPositive: boolean) =>
    isPositive ? 'text-success' : 'text-error';

  // DROPDOWN (IS THIS NEEDED?)
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // -------------------- DATA FILTERING BASED ON DROPDOWN SECTION --------------------

  const filterDomainsBrandPresence = (
    data: BPDomainData,
    selectedDomains: string[]
  ) => {
    return Object.keys(data)
      .filter((domain) => selectedDomains.includes(domain))
      .reduce((acc, domain) => {
        acc[domain] = data[domain];
        return acc;
      }, {} as BPDomainData);
  };

  const filterDomainsLocationPaidVsOrganicVsPLA = (
    data: POPDomainData,
    selectedDomains: string[]
  ) => {
    return Object.keys(data)
      .filter((domain) => selectedDomains.includes(domain))
      .reduce((acc, domain) => {
        acc[domain] = data[domain];
        return acc;
      }, {} as POPDomainData);
  };

  const filterDomainsLA = (data: LADomainData, selectedDomains: string[]) => {
    return Object.keys(data)
      .filter((domain) => selectedDomains.includes(domain))
      .reduce((acc, domain) => {
        acc[domain] = data[domain];
        return acc;
      }, {} as LADomainData);
  };

  const filterDomainsCD = (data: CDDomainData, selectedDomains: string[]) => {
    return Object.keys(data)
      .filter((domain) => selectedDomains.includes(domain))
      .reduce((acc, domain) => {
        acc[domain] = data[domain];
        return acc;
      }, {} as CDDomainData);
  };

  const filterBattlefieldDomains = (
    data: { [adType: string]: BDomainData },
    selectedDomains: string[]
  ) => {
    const filteredData: { [adType: string]: BDomainData } = {};

    Object.keys(data).forEach((adType) => {
      const filteredDomains = Object.keys(data[adType])
        .filter((domain) => selectedDomains.includes(domain))
        .reduce((acc, domain) => {
          const domainData = data[adType][domain];

          if (domainData) {
            acc[domain] = domainData;
          }

          return acc;
        }, {} as BDomainData);

      filteredData[adType] = filteredDomains;
    });

    return filteredData;
  };

  const filterBrandFocusAC = (
    data: BrandFocusInnerDataDoughnut,
    selectedDomains: string[]
  ) => {
    const filteredData: BrandFocusInnerDataDoughnut = {};

    Object.keys(data).forEach((adType) => {
      filteredData[adType] = {
        desktop: Object.keys(data[adType].desktop)
          .filter((domain) => selectedDomains.includes(domain))
          .reduce((acc, domain) => {
            acc[domain] = data[adType].desktop[domain];
            return acc;
          }, {} as DomainDataAC),
        mobile: Object.keys(data[adType].mobile)
          .filter((domain) => selectedDomains.includes(domain))
          .reduce((acc, domain) => {
            acc[domain] = data[adType].mobile[domain];
            return acc;
          }, {} as DomainDataAC),
      };
    });

    return filteredData;
  };

  const filterBrandFocusAP = (
    data: BrandFocusInnerDataBar,
    selectedDomains: string[]
  ) => {
    const filteredData: BrandFocusInnerDataBar = {};

    Object.keys(data).forEach((adType) => {
      filteredData[adType] = {
        desktop: Object.keys(data[adType].desktop)
          .filter((domain) => selectedDomains.includes(domain))
          .reduce((acc, domain) => {
            acc[domain] = data[adType].desktop[domain];
            return acc;
          }, {} as DomainDataAP),
        mobile: Object.keys(data[adType].mobile)
          .filter((domain) => selectedDomains.includes(domain))
          .reduce((acc, domain) => {
            acc[domain] = data[adType].mobile[domain];
            return acc;
          }, {} as DomainDataAP),
      };
    });

    return filteredData;
  };

  const filterDataBasedOnDropdown = (
    data: BrandFocusTabData,
    selectedDomains: string[]
  ): BrandFocusTabData => {
    const newData: BrandFocusTabData = {
      battlefield: {
        desktop: {},
        mobile: {},
      },
      brand_focus: {
        average_coverage: {},
        average_position: {},
        calculated_diff: {},
      },
      brand_presence: {},
      location_analysis: {
        desktop: {},
        mobile: {},
      },
      paid_vs_organic_vs_pla: {
        desktop: {},
        mobile: {},
      },
    };

    if (selectedDomains.length === 0) return newData;

    // Filter brand focus data with nullish coalescing
    newData.brand_focus = {
      average_coverage: filterBrandFocusAC(
        data?.brand_focus?.average_coverage ?? {},
        selectedDomains
      ),
      average_position: filterBrandFocusAP(
        data?.brand_focus?.average_position ?? {},
        selectedDomains
      ),
      calculated_diff: filterDomainsCD(
        data?.brand_focus?.calculated_diff ?? {},
        selectedDomains
      ),
    };

    // Filter brand presence data with nullish coalescing
    newData.brand_presence = filterDomainsBrandPresence(
      data?.brand_presence ?? {},
      selectedDomains
    );

    // Filter paid vs organic vs pla data with nullish coalescing
    newData.paid_vs_organic_vs_pla = {
      desktop: filterDomainsLocationPaidVsOrganicVsPLA(
        data?.paid_vs_organic_vs_pla?.desktop ?? {},
        selectedDomains
      ),
      mobile: filterDomainsLocationPaidVsOrganicVsPLA(
        data?.paid_vs_organic_vs_pla?.mobile ?? {},
        selectedDomains
      ),
    };

    // Filter location analysis data with nullish coalescing
    newData.location_analysis = {
      desktop: filterDomainsLA(
        data?.location_analysis?.desktop ?? {},
        selectedDomains
      ),
      mobile: filterDomainsLA(
        data?.location_analysis?.mobile ?? {},
        selectedDomains
      ),
    };

    // Filter battlefield data with nullish coalescing
    newData.battlefield = {
      desktop: filterBattlefieldDomains(
        data?.battlefield?.desktop ?? {},
        selectedDomains
      ),
      mobile: filterBattlefieldDomains(
        data?.battlefield?.mobile ?? {},
        selectedDomains
      ),
    };

    return newData;
  };

  // -------------------- END OF: DATA FILTERING BASED ON DROPDOWN SECTION --------------------

  // JSX
  return (
    <div className="py-12">
      <FilterBarWrapper>
        <div className="w-full flex flex-col gap-2">
          <div className="flex justify-between w-full items-center">
            <div className="float-start">
              <TimeFramePicker onChange={onChangeTimeFrame} defaultValue="1M" />
            </div>

            <div className="float-end">
              <DatepickerComponent
                value={dateRange}
                onChange={onChangeDatePicker}
                product="comp_int"
              />
            </div>
          </div>
          <div className="flex justify-between md:mt-0 w-full">
            <MultiDropdown
              placeholder={'Search Term Groups'}
              options={searchOptions?.groups || []}
              preselectedOptions={selectedSearchOptions?.groups || []}
              onSelectionChange={onSelectSearchGroups}
              dropdownLength="max-h-60"
              dropdownOpenWidth="max-w-60"
            />
            <MultiDropdown
              placeholder={'Search Terms'}
              options={searchOptions?.terms || []}
              preselectedOptions={selectedSearchOptions?.terms || []}
              onSelectionChange={onSelectSearchTerms}
              dropdownLength="max-h-60"
              dropdownOpenWidth="max-w-60"
            />
            <MultiDropdown
              placeholder={'Competitor Groups'}
              options={competitorGroupOptions.map(
                (group) => `${group.group_name}`
              )}
              preselectedOptions={selectedGroupOptions}
              onSelectionChange={handleCompetitorGroupApply}
              dropdownLength="max-h-60"
              dropdownOpenWidth="max-w-60"
            />
            <MultiDropdown
              placeholder={'Domains'}
              options={competitorOptions}
              preselectedOptions={preselectedCompetitors}
              onSelectionChange={setPreselectedCompetitors}
              dropdownLength="max-h-60"
              dropdownOpenWidth="max-w-60"
            />
          </div>
        </div>
      </FilterBarWrapper>
      <div className="flex justify-end mb-4 mr-4 text-xs text-gray300 font-normal">
        Data updates daily
      </div>
      {isLoading ? (
        <OutletSpinner />
      ) : error ? (
        <div>Error fetching data</div>
      ) : data ? (
        <>
          <div className="pb-8">
            {/* <BrandPresenceChart data={filteredData.brand_presence} /> */}
            {filteredData && filteredData.brand_presence ? (
              <BrandPresenceChart data={filteredData.brand_presence} />
            ) : (
              <OutletSpinner />
            )}
          </div>

          <div
            className="pb-10 bg-light border rounded-2xl px-4 py-2"
            id="competitiveIntelligenceBrandFocus"
          >
            <div className="flex justify-between items-center mb-2">
              <div>
                {/* missing text subtitle */}
                <h2 className="text-2xl font-bold">Brand Focus</h2>
                {/* <p className="text-gray800 text-sm font-bold mt-[2px]">
                  Subtitle
                </p> */}
              </div>
              <div
                className="relative"
                ref={dropdownRef}
                id="downloadDropdownMenu-competitiveIntelligenceBrandFocus"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
                  alt="dots"
                  className="h-6 w-6 cursor-pointer"
                  onClick={toggleDropdown}
                />
                {isDropdownOpen && (
                  <div
                    className="absolute right-0 mt-2 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                    style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
                  >
                    <ul>
                      {/* <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={closeDropdown}
                      >
                        View data table
                      </li> */}
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          closeDropdown();
                          downloadXLS(
                            'competitiveIntelligenceBrandFocusAverageCoverage',
                            [
                              'website',
                              'device',
                              'type',
                              'coverage',
                              'percentage',
                            ],
                            xlsGetAverageCoverage(data.brand_focus)['label'],
                            xlsGetAverageCoverage(data.brand_focus)['columns']
                          );
                          downloadXLS(
                            'competitiveIntelligenceBrandFocusAveragePosition',
                            [
                              'website',
                              'device',
                              'type',
                              'position1',
                              'position2',
                              'position3',
                            ],
                            xlsGetAveragePosition(data.brand_focus)['label'],
                            xlsGetAveragePosition(data.brand_focus)['columns']
                          );
                        }}
                      >
                        Download XLS
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          closeDropdown();
                          downloadImage('competitiveIntelligenceBrandFocus');
                        }}
                      >
                        Download PNG image
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="mx-[-16px]">
              <hr className="border-success mb-4" />
            </div>
            <div className="flex justify-between items-center p-1">
              <div className="flex space-x-2">
                {/* <select className={selectClasses}>
                  <option>Time</option>
                </select> */}
              </div>
              <div className="flex space-x-2">
                {['PAID', 'ORGANIC', 'PLA'].map((filter) => (
                  <span
                    key={filter}
                    className={filterButtonClasses(filter)}
                    onClick={() => toggleFilter(filter)}
                  >
                    {filter === 'PLA' ? 'SHOPPING' : filter}{' '}
                    {selectedFilter === filter && (
                      <span className="ml-1">✕</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
            <div className="space-y-20">
              {/* <BrandFocusDoughnutChart
                data={data.brand_focus.average_coverage}
                percData={data.brand_focus.calculated_diff}
                selectedFilter={selectedFilter}
                arrowSrc={arrowSrc}
                changeColor={changeColor}
              /> */}

              {filteredData &&
              filteredData.brand_focus.average_coverage &&
              filteredData.brand_focus.calculated_diff ? (
                <BrandFocusDoughnutChart
                  data={filteredData.brand_focus.average_coverage}
                  percData={filteredData.brand_focus.calculated_diff}
                  selectedFilter={selectedFilter}
                  arrowSrc={arrowSrc}
                  changeColor={changeColor}
                  clientDomain={clientDomain}
                />
              ) : (
                <LoadingSpinner />
              )}

              {/* <BrandFocusBarChart
                data={data.brand_focus.average_position}
                selectedFilters={[selectedFilter]}
              /> */}

              {filteredData && filteredData.brand_focus.average_position ? (
                <BrandFocusBarChart
                  data={filteredData.brand_focus.average_position}
                  selectedFilters={[selectedFilter]}
                  clientDomain={clientDomain}
                />
              ) : (
                <LoadingSpinner />
              )}
            </div>
          </div>

          {/* <BFPaidVsOrganicVsPLA data={data.paid_vs_organic_vs_pla} /> */}
          {filteredData && filteredData.paid_vs_organic_vs_pla ? (
            <BFPaidVsOrganicVsPLA
              data={filteredData.paid_vs_organic_vs_pla}
              clientDomain={clientDomain}
            />
          ) : (
            <LoadingSpinner />
          )}

          {/* <BFLocationAnalysis data={data.location_analysis} /> */}
          {filteredData && filteredData.location_analysis ? (
            <BFLocationAnalysis
              data={filteredData.location_analysis}
              clientDomain={clientDomain}
            />
          ) : (
            <LoadingSpinner />
          )}

          {/* <BattleField data={data.battlefield} /> */}
          {filteredData && filteredData.battlefield ? (
            <BattleField
              data={filteredData.battlefield}
              clientDomain={clientDomain}
            />
          ) : (
            <LoadingSpinner />
          )}

          <SearchTermsOverlap
            data={data.search_term_overlaps}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            compBrands={preselectedCompetitors}
            searchTerms={selectedSearchOptions.terms}
          />
        </>
      ) : (
        <div>No data available.</div>
      )}
    </div>
  );
}

export default CompIntelBrandFocus;
