import React, { useState, useRef, useEffect } from 'react';
import { Bubble, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartOptions, ChartDataset, TooltipItem, Chart, ChartData, ChartType, Plugin } from 'chart.js';
import { downloadImage,downloadXLS } from 'utils/download'

interface MindsetData {
  Mindset: string;
  group_mindset_count: number;
  mindset_percentage: number;
}

interface CustomPluginOptions extends ChartOptions<'bubble'> {
  plugins: {
    extendedGridLinePlugin?: boolean;
    legend: {
      display: boolean;
    };
    tooltip: {
      enabled: boolean;
      callbacks: {
        labelColor?: (context: TooltipItem<'bubble'>) => { backgroundColor: string; borderColor: string; borderWidth: number; radius: number };
        labelTextColor?: (context: TooltipItem<'bubble'>) => string;
        title?: (tooltipItems: TooltipItem<'bubble'>[]) => string;
        label?: (tooltipItem: TooltipItem<'bubble'>) => string;
      };
      backgroundColor?: string;
      titleColor?: string;
      bodyColor?: string;
      borderWidth?: number;
      displayColors?: boolean;
      padding?: number;
    };
    nestedBubblePlugin?: boolean;
  };
}

interface CustomBarPluginOptions extends ChartOptions<'bar'> {
  plugins: {
    legend: {
      display: boolean;
    };
    title: {
      display: boolean;
    };
    tooltip: {
      enabled: boolean;
      callbacks: {
        title?: (tooltipItems: TooltipItem<'bar'>[]) => string;
        label?: (tooltipItem: TooltipItem<'bar'>) => string;
      };
    };
  };
}

interface BubbleDataset extends ChartDataset<'bubble'> {
  data: { x: number; y: number; r: number; label?: string; backgroundColor?: string; subBubbles?: { x: number; y: number; r: number; label: string; backgroundColor: string }[] }[];
}

interface MindsetBreakdownProps {
  data: {
    data: MindsetData[];
  };
}

const colors = [
  'rgba(34, 34, 34, 0.80)',
  'rgba(89, 89, 89, 0.8)',
  'rgba(144, 144, 144, 0.8)',
  'rgba(50, 215, 111, 0.8)',
  'rgba(3, 197, 255, 0.8)',
  'rgba(84, 79, 197, 0.8)',
  'rgba(254, 106, 53, 0.8)',
  'rgba(107, 138, 188, 0.8)',
  'rgba(213, 104, 251, 0.8)',
  'rgba(47, 224, 202, 0.8)',
  'rgba(250, 75, 66, 0.8)',
  'rgba(254, 181, 106, 0.8)',
  'rgba(254, 181, 106, 0.8)'
];

const nestedBubblePlugin: Plugin<'bubble'> = {
  id: 'nestedBubblePlugin',
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset) => {
      const bubbleDataset = dataset as BubbleDataset;
      bubbleDataset.data.forEach((bubble) => {
        if (bubble.label && bubble.label.includes('/')) {
          const labelText = bubble.label.split('/')[1] || bubble.label;
          ctx.font = '12px DM Sans';
          const textWidth = ctx.measureText(labelText).width;
          const bubbleDiameter = 2 * bubble.r;
          ctx.beginPath();
          ctx.arc(
            chart.scales.x.getPixelForValue(bubble.x),
            chart.scales.y.getPixelForValue(bubble.y),
            bubble.r,
            0,
            2 * Math.PI
          );
          ctx.fillStyle = (bubble.backgroundColor as string) || (dataset.backgroundColor as string);
          ctx.fill();
          if (textWidth <= bubbleDiameter) {
            ctx.fillStyle = '#1A1A1A';
            ctx.textAlign = 'center';
            ctx.fillText(labelText, chart.scales.x.getPixelForValue(bubble.x), chart.scales.y.getPixelForValue(bubble.y));
          }
        }
      });
    });
  },
};

Chart.register(nestedBubblePlugin);

const MindsetBreakdown: React.FC<MindsetBreakdownProps> = ({ data }) => {
  const [view, setView] = useState<'bars' | 'bubbles'>('bars');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getColor = (index: number) => colors[index % colors.length];

  // Avoid overlap by setting x and y based on index
  const getPosition = (index: number) => {
    const xInterval = 100; // Distance between bubbles on x-axis
    const yInterval = 100; // Distance between bubbles on y-axis
    const x = (index % 4) * xInterval; // Adjust the modulo value based on number of columns
    const y = Math.floor(index / 4) * yInterval; // Adjust the division value based on number of rows
    return { x, y };
  };

  const bubbleData = {
    datasets: data.data.map((item, index) => {
      const { x, y } = getPosition(index); // Get position based on index
      return {
        label: item.Mindset,
        data: [{
          x,
          y,
          r: parseFloat((item.mindset_percentage).toFixed(2)),
          label: item.Mindset,
          backgroundColor: getColor(index),
        }],
        backgroundColor: getColor(index),
        hoverBackgroundColor: getColor(index),
      };
    }) as BubbleDataset[],
  };

  const barData = {
    labels: data.data
      .filter((item) => item.Mindset !== "Uncategorized")
      .map((item) => item.Mindset.replace("Return", "Enjoy")),
    datasets: [
      {
        label: 'Demand',
        data: data.data
          .filter((item) => item.Mindset !== "Uncategorized")
          .map((item) => item.mindset_percentage.toFixed(2)),
        backgroundColor: '#222222CC',
        borderRadius: 8,
        barThickness: 96,
      },
    ],
  };  

  const bubbleOptions: CustomPluginOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (tooltipItems) {
            const dataset = tooltipItems[0].dataset as BubbleDataset;
            const dataItem = dataset.data[tooltipItems[0].dataIndex];
            if (dataItem.label) {
              return dataItem.label.split('/')[1];
            }
            return tooltipItems[0].label;
          },
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset as BubbleDataset;
            const dataItem = dataset.data[tooltipItem.dataIndex];
            const rValue = dataItem.r;
            if (dataItem.label) {
              return `${dataItem.label}\nDemand: ${rValue}%`;
            }
            return `${dataset.label}\nDemand: ${rValue}%`;
          },
          labelColor: function (context) {
            const dataset = context.dataset as BubbleDataset;
            const dataItem = dataset.data[context.dataIndex];
            return {
              backgroundColor: (dataItem.backgroundColor as string) || (dataset.backgroundColor as string),
              borderColor: '#333',
              borderWidth: 2,
              radius: 4,
            };
          },
          labelTextColor: function () {
            return '#333';
          },
        },
        backgroundColor: '#F2F2F2',
        titleColor: '#333',
        bodyColor: '#333',
        borderWidth: 8,
        displayColors: false,
        padding: 10,
      },
      extendedGridLinePlugin: false,
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  const barOptions: CustomBarPluginOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw as number;
            return `${tooltipItem.label}\nDemand: ${value}%`;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Mindset',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Percentage (%)',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: function (tickValue: string | number) {
            return tickValue + '%';
          },
          stepSize: 25,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const BubbleIcon = ({ color }: { color: string }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill={color} />
    </svg>
  );

  const legends = bubbleData.datasets.map((dataset, index) => ({
    icon: <BubbleIcon color={dataset.backgroundColor as string} key={index} />,
    label: dataset.label || '',
  }));

  return (
    <div className="bg-light rounded-2xl shadow-sm mt-8" id='mindsetBreakdown'>
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">Mindset Breakdown</h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">Segmentation of your category by search volume</h3>
        </div>
        <div className="flex items-center" ref={dropdownRef} id='downloadDropdownMenu-mindsetBreakdown'>
          {/* <div className="flex items-center mr-4 text-xs">
            <span className={view === 'bubbles' ? 'text-primarygray mr-2' : 'text-gray400 mr-2'}>Bubbles</span>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={view === 'bars'}
                onChange={() => setView(view === 'bubbles' ? 'bars' : 'bubbles')}
              />
              <div className="relative w-10 h-5 bg-lightblue rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
            </label>
            <span className={view === 'bars' ? 'text-primarygray ml-2' : 'text-gray400 ml-2'}>Columns</span>
          </div> */}
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50" style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}>
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => { closeDropdown(); downloadXLS('mindsetBreakdown',['date','mindset'],barData.labels, barData.datasets.map(item => item.data) ) }}>Download XLS</li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => { closeDropdown(); downloadImage('mindsetBreakdown'); }}>Download PNG image</li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex relative">
        <div className={view === 'bubbles' ? 'flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]' : 'flex pl-2 py-6 justify-center items-center w-full h-[295px] px-4'}>
          {view === 'bubbles' ? (
            <Bubble data={bubbleData} options={bubbleOptions} />
          ) : (
            <Bar data={barData} options={barOptions} />
          )}
        </div>
        {view === 'bubbles' && (
          <div className="flex-none text-xs flex flex-col items-start pl-8 pr-4 py-6">
            <div>
              {legends.map((legend, index) => (
                <div key={index} className="flex items-center mb-2">
                  {legend.icon}
                  <span className="ml-2">{legend.label}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MindsetBreakdown;