export const APP_ROUTES = Object.freeze({
  HOME: '/home',
  CONSUMER_INTENT: '/consumer_intent',
  COMPETITIVE_INTEL: '/competitive_intelligence',
  AUTO_OPTIMIZE: '/auto_optimize',
  INSIGHTS: '/insights',
  RESOURCES: '/resources',
  SETTINGS: '/settings',
  OVERVIEW: '/overview',
  NO_ACCESS: '/no-access',
  ON_BOARD: '/onboard',
  PLEASE_WAIT: '/please-wait',
  FINAL: '/final',

  // --- CONSUMER INTENT TABS ---
  get CON_INTENT_OVERVIEW() {
    return this.CONSUMER_INTENT + this.OVERVIEW;
  },
  get CON_INTENT_PFA() {
    return this.CONSUMER_INTENT + '/product_feature_analysis';
  },
  get CON_INTENT_RETAILER_COMP() {
    return this.CONSUMER_INTENT + '/retailer_comparision';
  },
  get CON_INTENT_BRAND_COMP() {
    return this.CONSUMER_INTENT + '/brand_comparision';
  },
  get CON_INTENT_QUERY_VIEW() {
    return this.CONSUMER_INTENT + '/query_view';
  },

  // --- COMPETITIVE INTELLIGENCE TABS ---
  get COMP_INTEL_OVERVIEW() {
    return this.COMPETITIVE_INTEL + this.OVERVIEW;
  },
  get COMP_INTEL_BRAND_FOCUS() {
    return this.COMPETITIVE_INTEL + '/brand_focus';
  },
  get COMP_INTEL_CONTENT_ANALYSIS() {
    return this.COMPETITIVE_INTEL + '/content_analysis';
  },
  get COMP_INTEL_REPORTS() {
    return this.COMPETITIVE_INTEL + '/reports';
  },

  // --- INSIGHTS TABS ---
  get INSIGHTS_OVERVIEW() {
    return this.INSIGHTS + this.OVERVIEW;
  },
  get INSIGHTS_CLUSTER() {
    return this.INSIGHTS + '/cluster';
  },
  get INSIGHTS_RECOMMENDATIONS() {
    return this.INSIGHTS + '/recommendations';
  },
  get INSIGHTS_CONFIGURATION() {
    return this.INSIGHTS_RECOMMENDATIONS + '/configuration';
  },

  // --- SETTINGS TABS ---
  get ACCOUNT_MANAGEMENT() {
    return this.SETTINGS + '/account_management';
  },
  get USER_MANAGEMENT() {
    return this.SETTINGS + '/user_management';
  },
});
