import { useOutletContext } from 'react-router-dom';
import { TimeFrameDateRange } from '../components/TimeFramePicker';

export type IntentContext = {
  selectedProducts: string[];
  selectedVerticals: string[];
  dateRange: TimeFrameDateRange | null;
};

export const useIntentContext = () => useOutletContext<IntentContext>();
