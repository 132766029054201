import React, { useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables, Plugin, LinearScale } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';

ChartJS.register(...registerables);

// Define the custom plugin
const customTickPlugin: Plugin<'bar'> = {
  id: 'customTickPlugin',
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x as LinearScale;
    const tickPadding = [17, 27]; // Alternating padding values

    ctx.save();
    xAxis.ticks.forEach((tick, index) => {
      const x = xAxis.getPixelForTick(index);
      const y =
        xAxis.bottom + (index % 2 === 0 ? tickPadding[0] : tickPadding[1]);

      // Ensure tick.label is a string
      const label = typeof tick.label === 'string' ? tick.label : '';

      // Draw tick labels
      ctx.font = '12px';
      ctx.fillStyle = '#333';
      ctx.textAlign = 'center';
      ctx.fillText(label, x, y);

      // Draw regular grid lines
      const gridOptions = xAxis.options.grid;
      ctx.strokeStyle = (gridOptions?.color as string) || '#000';
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(x, xAxis.top);
      ctx.lineTo(x, xAxis.bottom); // Stop at the bottom of the chart area
      ctx.stroke();

      // Draw extended part of the grid lines in black
      ctx.strokeStyle = '#333';
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(x, xAxis.bottom);
      ctx.lineTo(x, xAxis.bottom + 10); // Extend 10px beyond the chart area
      ctx.stroke();
    });
    ctx.restore();
  },
};

interface CompData {
  data: {
    label: string;
    current: number;
    prior: number;
    percentage: string;
  }[];
  compBlur: boolean;
}

const fetchCompetitiveData = async () => {
  const response = await fetch('/api/home/');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const CompetitiveIntelligence: React.FC = () => {
  const chartRef = useRef<ChartJS<'bar'>>(null);
  const [competitiveData, setCompetitiveData] = useState({
    labels: [] as string[],
    datasets: [
      {
        label: 'Current',
        data: [] as number[],
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderColor: 'rgba(34, 34, 34, 0.80)',
        borderWidth: 1,
        borderRadius: 8,
      },
      {
        label: 'Prior',
        data: [] as number[],
        backgroundColor: 'rgba(144, 144, 144, 0.80)',
        borderColor: 'rgba(144, 144, 144, 0.80)',
        borderWidth: 1,
        borderRadius: 8,
      },
    ],
  });
  const [compInfo, setCompInfo] = useState({
    labels: [] as string[],
    values_current: [] as number[],
    percentages: [] as string[],
  });

  const [compBlur, setBlur] = useState(false);

  const { data, error, isLoading } = useQuery(
    'competitiveData',
    fetchCompetitiveData,
    {
      onSuccess: (data) => {
        const compData: CompData = data.competitive_data;
        setBlur(compData.compBlur);

        if (compData.data.length === 0) {
          return <div></div>;
        }

        const labels = compData.data.map((item) => item.label);
        const valuesCurrent = compData.data.map((item) => item.current);
        const valuesPrior = compData.data.map((item) => item.prior);
        const percentages = compData.data.map((item) => item.percentage);

        setCompetitiveData({
          labels: labels,
          datasets: [
            {
              label: 'Current',
              data: valuesCurrent,
              backgroundColor: 'rgba(34, 34, 34, 0.80)',
              borderColor: 'rgba(34, 34, 34, 0.80)',
              borderWidth: 1,
              borderRadius: 8,
            },
            {
              label: 'Prior',
              data: valuesPrior,
              backgroundColor: 'rgba(144, 144, 144, 0.80)',
              borderColor: 'rgba(144, 144, 144, 0.80)',
              borderWidth: 1,
              borderRadius: 8,
            },
          ],
        });

        setCompInfo({
          labels: labels,
          values_current: valuesCurrent,
          percentages: percentages,
        });
      },
    }
  );

  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate('/competitive_intelligence');
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div>Error fetching competitive data: {(error as Error).message}</div>
    );
  }

  if (
    !data ||
    !data.competitive_data ||
    data.competitive_data.data.length === 0
  ) {
    return <div></div>;
  }

  return (
    <div className="bg-light rounded-lg shadow-md h-[413px] w-full lg:h-auto">
      {compBlur && (
        <span className="block bg-light text-primarygray p-4 mb-4 rounded">
          NO DATA FOR THIS PRODUCT
        </span>
      )}
      <div className={`relative ${compBlur ? 'filter blur-sm' : ''}`}>
        <div className="px-4 py-2 flex justify-between items-center border-b border-success">
          <div>
            <h3
              className="text-primarygray text-2xl font-bold cursor-pointer tracking-[0.48px]"
              onClick={handleRedirect}
            >
              Competitive Intelligence
              <div className="relative ml-1 mb-2 group inline-block">
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
                  alt="tooltip"
                  className="tooltip-image"
                />
                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-[205px] h-12 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300">
                  Leveraging SERP scraping data
                  <img
                    src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                    alt="arrow"
                    className="absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]"
                  />
                </span>
              </div>
            </h3>
            <h6 className="text-sm text-gray400 font-semibold flex items-center">
              Competitor and market insights
            </h6>
          </div>
          <h6 className="text-sm font-semibold text-gray400 mt-8">
            Past 7 days
          </h6>
        </div>
        <div className="flex">
          <div className="py-6 px-4 flex-1 min-w-[200px] flex flex-col justify-center">
          {compInfo.labels.map((label, index) => (
            <div className="mb-4" key={index}>
              <h6 className="text-sm font-medium text-thirdgray">{label}</h6>
              <div className="flex items-center mt-2">
                <h3 className="text-primarygray text-3xl font-medium">
                  {compInfo.values_current[index]}%
                </h3>
                {compInfo.percentages[index] !== 'N/A' && compInfo.percentages[index] !== '0%' ? (
                  <div
                    className={`flex justify-center items-center ml-1 ${
                      compInfo.percentages[index].startsWith('-')
                        ? 'text-error'
                        : 'text-success'
                    }`}
                  >
                    <img
                      src={
                        compInfo.percentages[index].startsWith('-')
                          ? `${process.env.PUBLIC_URL}/static/img/Arrow_negative.svg`
                          : `${process.env.PUBLIC_URL}/static/img/Arrow_positive.svg`
                      }
                      alt="Arrow Icon"
                    />
                    <h6 className="text-sm font-medium">
                      {compInfo.percentages[index].startsWith('-') ? '' : '+'}
                      {compInfo.percentages[index]}
                    </h6>
                  </div>
                ) : (
                  <span className="text-gray500 text-sm font-medium ml-1">N/A</span>
                )}
              </div>
            </div>
          ))}
          </div>
          <div className="flex-1 py-6 px-4">
            <div className="w-[307px] h-[251px] 2xl:w-[370px] 3xl:w-[500px]">
              <Bar
                ref={chartRef}
                data={competitiveData}
                options={{
                  indexAxis: 'x',
                  layout: {
                    padding: {
                      bottom: 30, // value to provide enough space for custom tick labels
                    },
                  },
                  scales: {
                    x: {
                      title: {
                        display: false,
                      },
                      grid: {
                        display: true,
                        drawTicks: false, // Disable default tick drawing
                        color: '#B3B3B3',
                        z: 1,
                        drawOnChartArea: true,
                      },
                      ticks: {
                        display: false, // Hide default ticks
                        maxRotation: 0,
                        minRotation: 0,
                      },
                      border: {
                        color: '#333333',
                        display: true,
                      },
                    },
                    y: {
                      title: {
                        display: true,
                        text: 'Coverage (%)',
                        font: {
                          size: 14,
                          style: 'normal',
                          weight: 700,
                          lineHeight: 1.28,
                        },
                        align: 'center',
                      },
                      ticks: {
                        display: true,
                        color: '#333',
                        stepSize: 50,
                        font: {
                          size: 12,
                          style: 'normal',
                          weight: 400,
                          lineHeight: 1.33,
                        },
                        callback: function (value) {
                          return value + '%';
                        },
                      },
                      grid: {
                        color: '#B3B3B3',
                      },
                      border: {
                        color: '#333333',
                        display: true,
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                    // Custom plugins need to be handled through plugin registration.
                  },
                  interaction: {
                    mode: 'index',
                    intersect: false,
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                }}
                plugins={[customTickPlugin]} // Pass custom plugin here
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between px-4 py-2">
          <div className="flex-wrap">
            <div className="flex items-center text-xs text-primarygray -mb-2 gap-1">
              <img
                src={`${process.env.PUBLIC_URL}/static/img/Vector_current.svg`}
                alt="Current icon"
                className=""
              />
              Current
            </div>
            <div className="flex items-center text-xs text-primarygray -mb-2 gap-1">
              <img
                src={`${process.env.PUBLIC_URL}/static/img/Vector_prior.svg`}
                alt="Prior icon"
                className=""
              />
              Prior
            </div>
          </div>
          <a
            className="inline-flex items-center w-[110px] h-[30px] justify-center text-thirdgray text-sm font-normal bg-fourthgray rounded-[8px] cursor-pointer"
            onClick={handleRedirect}
          >
            See more
            <img
              src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
              className="w-4 h-4 bg-contain bg-center ml-1"
              alt="right-arrow"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CompetitiveIntelligence;
