import React, { useState, useEffect } from 'react';

interface FAQItem {
  question: string;
  answer: string;
  categories: string;
}

const categoryColors: Record<string, string> = {
  'Consumer Intent': 'bg-thirdgray text-light',
  'Tech': 'bg-thirdgray text-light',
  'Functionality': 'bg-gray300 text-secondarygray',
  'Full Site': 'bg-gray100 text-secondarygray',
  'Operations': 'bg-gray100 text-secondarygray',
  'Auto Optimize': 'bg-blue500 text-light',
  'Insights': 'bg-yellow500 text-secondarygray',
  'Competitive Intel': 'bg-success text-light',
  'default': 'bg-gray100 text-secondarygray',
};

const FAQ: React.FC = () => {
  const [faqData, setFaqData] = useState<FAQItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/faqs/');
      const data = await response.json();
      setFaqData(data);
    };

    fetchData();
  }, []);

  const getFilteredData = () => {
    return faqData.filter((item) =>
      item.question.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const filteredData = getFilteredData();

  return (
    <div>
      {/* Search Bar */}
      <div className="flex flex-row gap-4 items-center justify-between bg-light border text-sm rounded-2xl px-4 py-2 mt-12">
        <div className="flex-1"></div>
        <div className="relative text-thirdgray text-sm font-bold border border-gray300 rounded-full w-full lg:w-[401px]">
          <input
            type="search"
            name="search"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="h-10 text-sm pl-10 pr-4 w-full rounded-full"
          />
          <img
            src={`${process.env.PUBLIC_URL}/static/img/Trailing_icon.svg`}
            alt="search_icon"
            className="absolute left-3 top-3 h-4 w-4 fill-current"
          />
        </div>
      </div>

      <div className="my-8 rounded-2xl bg-light shadow p-4">
      <div className="overflow-auto">
          {filteredData.length > 0 ? (
            filteredData.map((item: FAQItem, index: number) => (
              <div key={index} className="mb-6">
                <div className="flex space-x-2 items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/img/question-mark-circle.svg`}
                    alt="question_mark"
                    className="h-6 w-6"
                  />
                  <h3 className="font-bold text-primarygray">{item.question}</h3>
                  {/* Categories */}
                  <div className="flex flex-wrap gap-2 ml-4">
                    {item.categories.split(',').map((category, idx) => (
                      <span
                        key={idx}
                        className={`px-4 py-1 rounded-md text-sm ${
                          categoryColors[category.trim()] || categoryColors['default']
                        }`}
                      >
                        {category.trim()}
                      </span>
                    ))}
                  </div>
                </div>
                <p className="text-sm text-thirdgray mt-2">
                  {item.answer.split('\r\n\r\n').map((paragraph, i) => (
                    <React.Fragment key={i}>
                      {paragraph.split('\r\n').map((line, j) => (
                        <React.Fragment key={j}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </div>
            ))
          ) : (
            <p className="text-sm text-gray500 px-4 py-1">No results found for &quot;{searchTerm}&quot;.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQ;