import React, { useState, useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import 'chart.js/auto';
import CustomLegend_mindset_product from './CustomLegend_mindset_product';
import { downloadImage,downloadXLS } from 'utils/download'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface MindsetData {
  Product: string;
  mindset: string;
  mindset_count: number;
  mindset_percentage: number;
}

interface MindsetBreakdownperProductProps {
  data: {
    data: MindsetData[];
  };
}

interface BrandData {
  label: string;
  data: number[];
  color: string;
}

const colors = [
  'rgba(34, 34, 34, 0.80)',
  'rgba(89, 89, 89, 0.8)',
  'rgba(144, 144, 144, 0.8)',
  'rgba(50, 215, 111, 0.8)',
  'rgba(3, 197, 255, 0.8)',
  'rgba(84, 79, 197, 0.8)',
  'rgba(254, 106, 53, 0.8)',
  'rgba(107, 138, 188, 0.8)',
  'rgba(213, 104, 251, 0.8)',
  'rgba(47, 224, 202, 0.8)',
  'rgba(250, 75, 66, 0.8)',
  'rgba(254, 181, 106, 0.8)',
  'rgba(254, 181, 106, 0.8)'
];

const MindsetBreakdownperProduct: React.FC<MindsetBreakdownperProductProps> = ({ data }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [brandsData, setBrandsData] = useState<BrandData[]>([]);
  const [visibility, setVisibility] = useState<Record<string, boolean>>({});
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const transformedData = data.data
        // hide uncategorized mindset
        // .filter(item => item.mindset !== "uncategorized")
        .map(item => ({
            ...item,
            mindset: item.mindset === "return" ? "enjoy" : item.mindset,
        }));

    const products = Array.from(new Set(transformedData.map(item => item.Product)));
    const mindsets = Array.from(new Set(transformedData.map(item => item.mindset)));

    const groupedData: Record<string, Record<string, number>> = {};
    products.forEach(product => {
      groupedData[product] = {};
      mindsets.forEach(mindset => {
        groupedData[product][mindset] = 0;
      });
    });

    transformedData.forEach(item => {
      groupedData[item.Product][item.mindset] = item.mindset_percentage;
    });

    const formattedData: BrandData[] = mindsets.map((mindset, index) => ({
      label: mindset,
      data: products.map(product => groupedData[product][mindset]),
      color: colors[index % colors.length],
    }));

    setBrandsData(formattedData);

    const initialVisibility = formattedData.reduce((acc: Record<string, boolean>, brand) => {
      const normalizedLabel = brand.label.replace(/ /g, '');
      acc[normalizedLabel] = normalizedLabel !== 'uncategorized';
      return acc;
    }, {} as Record<string, boolean>);

    setVisibility(initialVisibility);
  }, [data]);

  const calculateBarThickness = (chartWidth: number, numberOfLabels: number) => {
    return Math.max(10, chartWidth / (numberOfLabels * 2));
  };

  const handleLegendClick = (label: string) => {
    setVisibility((prevState) => ({
      ...prevState,
      [label.replace(/ /g, '')]: !prevState[label.replace(/ /g, '')],
    }));
  };

  const downloadChartData = () => {

    const transformedData = data.data
        // hide uncategorized mindset
        // .filter(item => item.mindset !== "uncategorized")
        .map(item => ({
            ...item,
            mindset: item.mindset === "return" ? "enjoy" : item.mindset,
        }));

    const headers = ['Mindset', ...Array.from(new Set(transformedData.map(item => item.Product)))];
    const labels = Array.from(new Set(transformedData.map(item => item.mindset)));

    const products = Array.from(new Set(transformedData.map(item => item.Product)));
    const columns = products.map(product => {
        return labels.map(label => {
            const mindsetData = transformedData.find(item => item.Product === product && item.mindset === label);
            return mindsetData ? mindsetData.mindset_percentage : 0;
        });
    });

    downloadXLS('MindsetBreakdownPerProduct', headers, labels, columns);
};

  const downloadChartImage = () => {
    downloadImage('Mindset_Breakdown_per_product');
  };

  const chartWidth = chartRef.current ? chartRef.current.offsetWidth : 800;
  const barThickness = calculateBarThickness(chartWidth, Array.from(new Set(data.data.map(item => item.Product))).length);

  const chartData: ChartData<'bar'> = {
    labels: Array.from(new Set(data.data.map(item => item.Product))),
    datasets: brandsData.map((brand) => {
      const fadedColor = brand.color.replace(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/, (match, p1, p2, p3, p4) => {
        const alpha = parseFloat(p4) * 0.2;
        return `rgba(${p1}, ${p2}, ${p3}, ${alpha})`;
      });

      return {
        label: brand.label,
        data: brand.data,
        backgroundColor: visibility[brand.label.replace(/ /g, '')] ? brand.color : fadedColor,
        borderWidth: 0,
        barThickness: barThickness,
        borderRadius: 0,
      };
    }),
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y + '%';
            }
            return label;
          }
        },
        itemSort: function(a, b) {
          return b.datasetIndex - a.datasetIndex;
        }
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Product',
          color: '#4D4D4D',
          font: {
            size: 14,
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        grid: {
          color: '#B3B3B3',
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          callback: function (tickValue: string | number) {
            const value = Number(tickValue);
            return value + '%';
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        max: 100
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div id="Mindset_Breakdown_per_product" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">Mindset Breakdown per product</h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">How consumer searches differ from product to product</h3>
        </div>
        <div className="flex items-center" ref={dropdownRef} id='downloadDropdownMenu-Mindset_Breakdown_per_product'>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50" style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}>
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartData();
                      closeDropdown();
                    }}
                >
                    Download XLS
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex relative">
        <div ref={chartRef} className="flex pl-2 py-6 justify-center items-center relative 2xl:w-[85%] md:w-[75%] md:h-[295px] w-[370px] h-[295px]">
          <Bar data={chartData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <CustomLegend_mindset_product brandsData={brandsData} onLegendClick={handleLegendClick} visibility={visibility} />
        </div>
      </div>
    </div>
  );
};

export default MindsetBreakdownperProduct;
