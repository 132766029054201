import { FC } from 'react';


const NoAccessPage: FC = () => {
  return (
    <div >

    </div>
  );
};

export default NoAccessPage;
