import { useQuery } from 'react-query';
import React, { useEffect, useState, useRef } from 'react';

// COMPONENTS
import LoadingSpinner from '../LoadingSpinner';

// UTILS
import { toFixedNum } from 'utils';
import { downloadXLS } from 'utils/download';

interface CategoryDataItem {
  cluster_category: string;
  search_term: string;
  recommendations: string;
  organic_impressions: number;
  paid_impressions: number;
  total_impressions: number;
  organic_clicks: number;
  paid_clicks: number;
  total_clicks: number;
  organic_ctr: number;
  paid_ctr: number;
  total_ctr: number;
  organic_position: number;
  paid_position: number;
  organic_transactions: number;
  paid_transactions: number;
  total_transactions: number;
  organic_conversions: number;
  paid_conversions: number;
  total_conversions: number;
  organic_revenue: number;
  paid_revenue: number;
  total_revenue: number;
}

interface DetailsSectionProps {
  activeCategory: string;
  startDate: string;
  endDate: string;
}

const headers = [
  'Search Term',
  'Recommendations',
  'Organic Impressions',
  'Paid Impressions',
  'Holistic Impressions',
  'Organic Clicks',
  'Paid Clicks',
  'Holistic Clicks',
  'Organic CTR',
  'Paid CTR',
  'Holistic CTR',
  'Organic Position',
  'Paid Position',
  'Organic Transactions',
  'Paid Transactions',
  'Holistic Transactions',
  'Organic Conversions',
  'Paid Conversions',
  'Holistic Conversions',
  'Organic Revenue',
  'Paid Revenue',
  'Holistic Revenue',
];

const keyMapping: { [key: string]: keyof CategoryDataItem } = {
  'Search Term': 'search_term',
  Recommendations: 'recommendations',
  'Organic Impressions': 'organic_impressions',
  'Paid Impressions': 'paid_impressions',
  'Holistic Impressions': 'total_impressions',
  'Organic Clicks': 'organic_clicks',
  'Paid Clicks': 'paid_clicks',
  'Holistic Clicks': 'total_clicks',
  'Organic CTR': 'organic_ctr',
  'Paid CTR': 'paid_ctr',
  'Holistic CTR': 'total_ctr',
  'Organic Position': 'organic_position',
  'Paid Position': 'paid_position',
  'Organic Transactions': 'organic_transactions',
  'Paid Transactions': 'paid_transactions',
  'Holistic Transactions': 'total_transactions',
  'Organic Conversions': 'organic_conversions',
  'Paid Conversions': 'paid_conversions',
  'Holistic Conversions': 'total_conversions',
  'Organic Revenue': 'organic_revenue',
  'Paid Revenue': 'paid_revenue',
  'Holistic Revenue': 'total_revenue',
};

// Configuration object for default sorting
const defaultSortConfig = {
  key: 'paid_impressions' as keyof CategoryDataItem,
  direction: 'descending' as 'ascending' | 'descending',
};

const kpiOptions = [
  'Impressions',
  'Clicks',
  'CTR',
  'Position',
  'Transactions',
  'Conversions',
  'Revenue',
];

const typeOptions = ['Organic', 'Paid', 'Holistic'];

const fetchCategoryData = async (
  activeCategory: string,
  startDate: string,
  endDate: string
): Promise<CategoryDataItem[]> => {
  const response = await fetch(
    `/get_insights_category_keyword_data/${startDate}/${endDate}`
  );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();

  const categoryKey = activeCategory;

  const categoryData = data.search_terms[categoryKey];

  return categoryData;
};

const formatPercentage = (num: number) => toFixedNum(num) + '%';

function DetailsSection({
  activeCategory,
  startDate,
  endDate,
}: DetailsSectionProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [filteredData, setFilteredData] = useState<CategoryDataItem[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof CategoryDataItem | null;
    direction: 'ascending' | 'descending';
  }>(defaultSortConfig);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedKPIs, setSelectedKPIs] = useState<string[]>(kpiOptions);
  const [selectedTypes, setSelectedTypes] = useState<string[]>(typeOptions);
  const [isKPIDropdownOpen, setIsKPIDropdownOpen] = useState(false);
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);

  const kpiDropdownRef = useRef<HTMLDivElement>(null);
  const typeDropdownRef = useRef<HTMLDivElement>(null);

  const {
    data: categoryData,
    error,
    isLoading,
  } = useQuery<CategoryDataItem[], Error>(
    ['categoryData', activeCategory, startDate, endDate],
    () => fetchCategoryData(activeCategory, startDate, endDate),
    {
      onSuccess: (data) => {
        if (data && data.length > 0) {
          setTimeout(() => {
            const sortedData = sortData(
              data,
              defaultSortConfig.key,
              defaultSortConfig.direction
            );
            setFilteredData(sortedData);
          }, 100);
        } else {
          setFilteredData([]);
        }
      },
    }
  );

  useEffect(() => {
    if (categoryData) {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filteredData = categoryData.filter(
        (item) =>
          item.search_term &&
          item.search_term.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredData(filteredData);
    }
  }, [searchTerm, categoryData]);

  const sortData = (
    data: CategoryDataItem[],
    key: keyof CategoryDataItem,
    direction: 'ascending' | 'descending'
  ) => {
    if (!data || !Array.isArray(data)) return [];
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (columnIndex: number) => {
    const header = headers[columnIndex];
    const key = keyMapping[header];
    let direction: 'ascending' | 'descending' = 'ascending';

    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = sortData(filteredData, key, direction);
    setSortConfig({ key, direction });
    setFilteredData(sortedData);
  };

  const toggleKPISelection = (option: string) => {
    setSelectedKPIs((prev) =>
      prev.includes(option)
        ? prev.filter((kpi) => kpi !== option)
        : [...prev, option]
    );
  };

  const toggleTypeSelection = (option: string) => {
    setSelectedTypes((prev) =>
      prev.includes(option)
        ? prev.filter((type) => type !== option)
        : [...prev, option]
    );
  };

  const handleSelectAllKPIs = () => {
    setSelectedKPIs(kpiOptions);
  };

  const handleUnselectAllKPIs = () => {
    setSelectedKPIs([]);
  };

  const handleSelectAllTypes = () => {
    setSelectedTypes(typeOptions);
  };

  const handleUnselectAllTypes = () => {
    setSelectedTypes([]);
  };

  const filteredHeaders = headers.filter((header) => {
    if (header === 'Search Term' || header === 'Recommendations') return true;
    const [type, kpi] = header.split(' ');
    return selectedKPIs.includes(kpi) && selectedTypes.includes(type);
  });

  const handleClickOutside = (event: MouseEvent) => {
    if (
      kpiDropdownRef.current &&
      !kpiDropdownRef.current.contains(event.target as Node)
    ) {
      setIsKPIDropdownOpen(false);
    }
    if (
      typeDropdownRef.current &&
      !typeDropdownRef.current.contains(event.target as Node)
    ) {
      setIsTypeDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Error fetching data: {(error as Error).message}</div>;
  }

  if (!categoryData || categoryData.length === 0) {
    return <div>No data available</div>;
  }

  const downloadTableDataXLS = () => {
    const headers = filteredHeaders;

    const rows = filteredData.map((row) =>
      headers.map((header) => {
        const value = row[keyMapping[header]];
        if (typeof value === 'number') {
          return header.includes('CTR') || header.includes('Conversions')
            ? formatPercentage(value)
            : toFixedNum(value);
        }
        return value;
      })
    );

    const labels = rows.map((row) => row[0]);

    const columns = headers
      .slice(1)
      .map((_, colIndex) => rows.map((row) => row[colIndex + 1]));

    downloadXLS(`Details_${activeCategory}`, headers, labels, columns);
  };

  return (
    <div
      id="table_details"
      className="bg-light rounded-2xl shadow-sm mt-8 pb-6 relative"
    >
      <div className="flex justify-between items-center px-4 pt-2 border-b border-success pb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-2">Details</h2>
          {/* missing text subtitle */}
          {/* <h3 className="text-primarygray text-sm font-bold">Subtitle</h3> */}
        </div>
        <div className="flex items-center">
          <div className="relative text-thirdgray text-sm font-bold border mr-4 border-gray300 rounded-full w-[401px]">
            <input
              type="search"
              name="search"
              placeholder="Search"
              className="h-10 text-sm ml-8 pl-2"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <img
              src={`${process.env.PUBLIC_URL}/static/img/Trailing_icon.svg`}
              alt="search_ico"
              className="absolute left-0 top-0 mt-3 ml-3 mr-4 h-4 w-4 fill-current"
            />
          </div>
          <div
            className="relative"
            ref={dropdownRef}
            id="downloadDropdownMenu-table_details"
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
              alt="dots"
              className="h-6 w-6 cursor-pointer"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div
                className="absolute right-0 mt-2 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
              >
                <ul>
                  {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadTableDataXLS();
                      closeDropdown();
                    }}
                  >
                    Download XLS
                  </li>
                  {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      closeDropdown();
                    }}
                  >
                    Download PNG image
                  </li> */}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex space-x-4 px-4 pt-4 relative">
        <div
          className="relative border border-gray300 rounded-lg p-2 flex items-center cursor-pointer"
          ref={kpiDropdownRef}
          onClick={() => setIsKPIDropdownOpen(!isKPIDropdownOpen)}
        >
          <span className="text-thirdgray font-bold">KPI: </span>
          <span className="text-primarygray font-bold ml-1">
            {selectedKPIs.length}
          </span>{' '}
          <span className="text-thirdgray font-bold ml-1">Selected</span>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
            alt="dropdown icon"
            className={`inline ml-1 w-4 h-4 ${
              isKPIDropdownOpen ? 'rotate-90' : ''
            }`}
          />
          {isKPIDropdownOpen && (
            <div className="absolute bg-white border border-gray300 rounded-lg mt-2 p-2 z-30">
              <button
                onClick={handleSelectAllKPIs}
                className="block w-full text-left text-thirdgray"
              >
                Select All
              </button>
              <button
                onClick={handleUnselectAllKPIs}
                className="block w-full text-left text-thirdgray"
              >
                Unselect All
              </button>
              {kpiOptions.map((option) => (
                <label key={option} className="block text-thirdgray">
                  <input
                    type="checkbox"
                    checked={selectedKPIs.includes(option)}
                    onChange={() => toggleKPISelection(option)}
                  />
                  <span className="ml-2">{option}</span>
                </label>
              ))}
            </div>
          )}
        </div>
        <div
          className="relative border border-gray300 rounded-lg p-2 flex items-center cursor-pointer"
          ref={typeDropdownRef}
          onClick={() => setIsTypeDropdownOpen(!isTypeDropdownOpen)}
        >
          <span className="text-thirdgray font-bold">Type: </span>
          <span className="text-primarygray font-bold ml-1">
            {selectedTypes.length}
          </span>{' '}
          <span className="text-thirdgray font-bold ml-1">Selected</span>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
            alt="dropdown icon"
            className={`inline ml-1 w-4 h-4 ${
              isTypeDropdownOpen ? 'rotate-90' : ''
            }`}
          />
          {isTypeDropdownOpen && (
            <div className="absolute bg-white border border-gray300 rounded-lg mt-2 p-2 z-30">
              <button
                onClick={handleSelectAllTypes}
                className="block w-full text-left text-thirdgray"
              >
                Select All
              </button>
              <button
                onClick={handleUnselectAllTypes}
                className="block w-full text-left text-thirdgray"
              >
                Unselect All
              </button>
              {typeOptions.map((option) => (
                <label key={option} className="block text-thirdgray">
                  <input
                    type="checkbox"
                    checked={selectedTypes.includes(option)}
                    onChange={() => toggleTypeSelection(option)}
                  />
                  <span className="ml-2">{option}</span>
                </label>
              ))}
            </div>
          )}
        </div>
      </div>

      <div
        className="overflow-x-auto mx-4 px-1 relative"
        style={{ maxHeight: '400px', overflowY: 'auto' }}
      >
        <table className="min-w-full bg-light mt-4">
          <thead className="uppercase sticky top-0 bg-light z-10">
            <tr>
              {filteredHeaders.map((header, index) => (
                <th
                  key={index}
                  className="py-2 pr-4 text-primarygray text-s font-normal text-left whitespace-normal"
                >
                  <div className="flex items-center">
                    <span>{header}</span>
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`}
                      alt="sort icon"
                      className="pr-4 inline ml-2 cursor-pointer"
                      onClick={() => handleSort(headers.indexOf(header))}
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={rowIndex % 2 === 0 ? 'bg-fourthgray' : 'bg-gray100'}
              >
                {filteredHeaders.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    className={`pr-4 text-primarygray text-xs text-left whitespace-nowrap ${
                      header.toLowerCase().includes('holistic')
                        ? 'font-bold'
                        : ''
                    }`}
                  >
                    {typeof row[keyMapping[header]] === 'number'
                      ? header.includes('CTR') || header.includes('Conversions')
                        ? formatPercentage(row[keyMapping[header]] as number)
                        : toFixedNum(row[keyMapping[header]] as number)
                      : row[keyMapping[header]]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DetailsSection;
