import React, { useState, useEffect } from 'react';

interface InsightsProps {
  customerId: string | null;
  setTriggerInsightsUpload: React.Dispatch<React.SetStateAction<() => Promise<void>>>; // Prop to receive the file upload trigger
  setHasUploadedFileInsights: React.Dispatch<React.SetStateAction<boolean>>;
}

const Insights: React.FC<InsightsProps> = ({ customerId, setTriggerInsightsUpload, setHasUploadedFileInsights }) => {
  const [InsightsfileName, setInsightsFileName] = useState<File | null>(null); // Store the file object
  const [hasFileUploadedConInsights, setHasFileUploadedConInsights] = useState<boolean>(false); // Local state to manage file upload status
  const [uploadError, setUploadError] = useState<string | null>(null);

  const handleTemplateDownload = () => {  
    fetch('/download-template/Insights/', {
      method: 'GET',
    })
    .then(response => {
      if (response.ok) {
        return response.blob();
      } else {
        throw new Error('Failed to download the file');
      }
    })
    .then(blob => {
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `insights_template.xlsx`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const handleInsightsFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setInsightsFileName(event.target.files[0]); // Save the file object
      setHasUploadedFileInsights(true)
      setHasFileUploadedConInsights(true)
      setUploadError(null);
    } else {
      setInsightsFileName(null);
      setHasUploadedFileInsights(false)
      setHasFileUploadedConInsights(false)
    }
  };

  useEffect(() => {
    if (InsightsfileName === null) {
      setHasUploadedFileInsights(false);
      setHasFileUploadedConInsights(false);
    }
  }, [InsightsfileName]);

  const uploadFile = async () => {
    if (!InsightsfileName) {
      console.error("No file selected for upload.");
      throw new Error("No file selected.");
    }

    const formData = new FormData();
    formData.append('file', InsightsfileName);
    formData.append('product', 'insights');

    if (customerId) {
      formData.append('customer_id', customerId);
    }

    try {
      const response = await fetch('/upload/', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('File upload failed:', errorText);
        setUploadError(`File upload failed: ${errorText}`);
        throw new Error(`File upload failed: ${errorText}`);
      }

      const data = await response.json();
      console.log('File upload successful:', data.message);
    } catch (error) {
      console.error('Error during file upload:', error);
      throw error;
    }
  };

  useEffect(() => {
    // function that will be called to trigger the file upload
    setTriggerInsightsUpload(() => uploadFile);
  }, [InsightsfileName, customerId, setTriggerInsightsUpload]);

  return (
    <>
      <div className="bg-light rounded-2xl shadow-sm mt-8">
        <div className="flex justify-between items-center border-b border-success mb-2">
          <div>
            <h2 className="text-primarygray text-2xl font-bold pl-4 py-2">Insights</h2>
            <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">Holistic search data and recommendations.</h3>
          </div>
        </div>
        <div className="flex flex-col space-y-4 px-4 py-4">
          <div className="flex flex-row items-center space-x-4">
            <label className="font-bold text-sm">Data Import</label>
            <div className="border border-thirdgray rounded-[20px] px-8">
              <button onClick={() => handleTemplateDownload()} className="text-sm font-bold flex items-center text-thirdgray">
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/download.svg`}
                  alt="download"
                  className="mr-[6px]"
                />
                Download template
              </button>
            </div>
          </div>
          <div className="flex flex-row items-center space-x-4">
            <div className="flex flex-col w-1/2">
              <div className="border border-gray200 rounded p-2 flex items-center">
                <input
                  type="file"
                  id="InsightsfileInput"
                  className="hidden"
                  accept=".xlsx"
                  onChange={handleInsightsFileChange}
                />
                <label
                  htmlFor="InsightsfileInput"
                  className="cursor-pointer text-thirdgray text-sm font-bold border border-thirdgray rounded-md px-3"
                >
                  CHOOSE FILE
                </label>
                <span className="text-thirdgray text-sm px-2">{InsightsfileName?.name || "No file chosen"}</span>
              </div>
              {uploadError && (
                <span className="text-error text-sm mt-2">{uploadError}</span>
              )}
            </div>
            {/* <div className="flex flex-col w-1/2">
              <span><a href="#" className="text-gray600 text-xs font-medium underline">or click here to import using plain text</a></span>
            </div> */}
          </div>
          <p className="text-xs text-thirdgray">Note: Maximum import size is 1000 lines</p>
          {!hasFileUploadedConInsights && (
            <span className="text-error text-sm">
              Please upload the required file before proceeding.
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default Insights;