import React, { useState, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartOptions } from 'chart.js';
import YoYChangeBarChart from './YoYChangeBarChart';
import CustomLegendES from './CustomLegendES';
import { downloadXLS, downloadImage } from 'utils/download';

interface YoYChangeDemandFTRProps {
  data: {
    data: Array<{
      date_formatted: string;
      Feature: string;
      total_demand: number;
    }>;
    growth_percentage_per_feature: Array<{
      Feature: string;
      demand_previous_year: number;
      demand_current_year: number;
      growth_percentage: number;
    }>;
  };
}

const YoYChangeDemandFTR: React.FC<YoYChangeDemandFTRProps> = ({ data = { data: [], growth_percentage_per_feature: [] } }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [visibility, setVisibility] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const initialVisibility: Record<string, boolean> = {};
    data.data.forEach((item) => {
      const normalizedFeature = normalizeLabel(item.Feature);
      if (!initialVisibility[normalizedFeature]) {
        initialVisibility[normalizedFeature] = normalizedFeature !== 'uncategorized';
      }
    });
    setVisibility(initialVisibility);
  }, [data]);

  const handleLegendClick = (label: string) => {
    const normalizedLabel = normalizeLabel(label);
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [normalizedLabel]: !prevVisibility[normalizedLabel],
    }));
  };

  const colors = [
    'rgba(34, 34, 34, 0.80)',
    'rgba(89, 89, 89, 0.8)',
    'rgba(144, 144, 144, 0.8)',
    'rgba(50, 215, 111, 0.8)',
    'rgba(3, 197, 255, 0.8)',
    'rgba(84, 79, 197, 0.8)',
    'rgba(254, 106, 53, 0.8)',
    'rgba(107, 138, 188, 0.8)',
    'rgba(213, 104, 251, 0.8)',
    'rgba(47, 224, 202, 0.8)',
    'rgba(250, 75, 66, 0.8)',
    'rgba(254, 181, 106, 0.8)',
    'rgba(254, 181, 106, 0.8)'
  ];

  const normalizeLabel = (label: string) => label.replace(/ /g, '_');

  const formatChartData = (data: YoYChangeDemandFTRProps['data']['data']) => {
    const labels = Array.from(new Set(data.map(item => item.date_formatted)));
    const features = Array.from(new Set(data.map(item => item.Feature)));

    const datasets = features.map((feature, index) => {
      const normalizedFeature = normalizeLabel(feature);
      const featureData = data.filter(item => item.Feature === feature);
      const totalDemands = labels.map(label => {
        const item = featureData.find(dataItem => dataItem.date_formatted === label);
        return item ? item.total_demand : 0;
      });

      const color = colors[index % colors.length];
      const fadedColor = color.replace(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/, (match, p1, p2, p3, p4) => {
        const alpha = parseFloat(p4) * 0.2;
        return `rgba(${p1}, ${p2}, ${p3}, ${alpha})`;
      });

      return {
        label: feature,
        data: totalDemands,
        borderColor: visibility[normalizedFeature] ? color : fadedColor,
        borderWidth: 1.5,
        fill: false,
        pointRadius: 0,
        tension: 0.4,
        hidden: !visibility[normalizedFeature],
        color: color
      };
    });

    return { labels, datasets };
  };

  const chartData = formatChartData(data.data);

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: function (value) {
            const numericValue = Number(value);
            return numericValue >= 1000000
              ? numericValue / 1000000 + 'M'
              : numericValue >= 1000
                ? numericValue / 1000 + 'K'
                : numericValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const legends = chartData.datasets.map((dataset) => ({
    label: dataset.label,
    color: dataset.borderColor,
    visibility: visibility[normalizeLabel(dataset.label)]
  }));

    const downloadChartData = () => {
      const headers = ['Month', ...chartData.datasets.map(dataset => dataset.label)];
      const labels = chartData.labels;
      const columns = chartData.datasets.map(dataset => dataset.data);

      const growthHeaders = ['Feature', 'Growth Percentage'];
      const growthLabels = data.growth_percentage_per_feature.map(item => item.Feature);
      const growthColumns = data.growth_percentage_per_feature.map(item => item.growth_percentage);
  
      downloadXLS('YoY_Change_Feature_Data', headers, labels, columns);

      downloadXLS('YoY_Growth_Feature_Data', growthHeaders, growthLabels, [growthColumns]);
    };
  
    const downloadChartImage = () => {
      downloadImage('YoY_Change_Feature');
    };

  return (
    <div id="YoY_Change_Feature" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">YoY Change in Demand by Feature (%)</h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">How consumers searches evolve per feature</h3>
        </div>
        <div className='flex items-center' ref={dropdownRef} id='downloadDropdownMenu-YoY_Change_Feature'>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50" style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}>
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartData();
                      closeDropdown();
                    }}
                >
                    Download XLS
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]">
          <Line data={chartData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <CustomLegendES brandsData={legends} onLegendClick={handleLegendClick} visibility={visibility} />
        </div>
      </div>
      <YoYChangeBarChart growthPercentageData={data.growth_percentage_per_feature} />
    </div>
  );
};

export default YoYChangeDemandFTR;
