import React from 'react';

interface ConfirmationPopupProps {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray500 bg-opacity-75">
      <div className="bg-light p-4 rounded shadow-md text-center">
        <p className="mb-4">{message}</p>
        <div className="flex justify-around">
          <button className="bg-thirdgray text-light px-4 rounded-2xl" onClick={onCancel}>Cancel</button>
          <button className="bg-success text-light px-4 rounded-2xl" onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
