import { useQuery } from 'react-query';
import { useIntentContext } from '../../hooks';

// API REQUESTS
import { fetchConsumerIntBCData } from '../../api';

// COMPONENTS
import OutletSpinner from '../OutletSpinner';
import GrowingBrand from './BrandComparison_comp/GrowingBrand';
import BCStackedBar1 from './BrandComparison_comp/BCStackedBar1';
import BCStackedBar2 from './BrandComparison_comp/BCStackedBar2';
import BrandMarketOverview from './BrandComparison_comp/BrandMarketOverview';

// CONSUMER INTENT BRAND COMPARISON TAB
function BrandComparison() {
  const { selectedProducts, selectedVerticals, dateRange } = useIntentContext();

  const { data: consumerIntBCData, isLoading } = useQuery(
    ['consumerIntBCData', dateRange, selectedVerticals, selectedProducts],
    () =>
      fetchConsumerIntBCData(dateRange, selectedVerticals, selectedProducts),
    {
      enabled: !!dateRange?.startDate && !!dateRange?.endDate,
    }
  );

  return isLoading ? (
    <OutletSpinner />
  ) : consumerIntBCData ? (
    <>
      <BrandMarketOverview data={consumerIntBCData} />
      <GrowingBrand
        brands_growth_shrink_chart_data={
          consumerIntBCData.brands_growth_shrink_chart
        }
      />
      <BCStackedBar1
        product_association_with_brands_chart_data={
          consumerIntBCData.product_association_with_brands_chart
        }
      />
      <BCStackedBar2
        feature_association_with_brands_chart_data={
          consumerIntBCData.feature_association_with_brands_chart
        }
      />
    </>
  ) : null;
}

export default BrandComparison;
