import React from 'react';

interface LegendItem {
  label: string;
  icon: string;
}

interface LegendsData {
  [key: string]: LegendItem[];
}

const legendsData: LegendsData = {
  set1: [
    { label: 'Organic revenue Brand Generic', icon: `${process.env.PUBLIC_URL}/static/img/organic_brand_generic.svg` },
    { label: 'Paid revenue Brand Generic', icon: `${process.env.PUBLIC_URL}/static/img/paid_brand_generic.svg` },
    { label: 'Organic revenue Product Line', icon: `${process.env.PUBLIC_URL}/static/img/organic_product_line.svg` },
    { label: 'Paid revenue Product Line', icon: `${process.env.PUBLIC_URL}/static/img/paid_product_line.svg` },
    { label: 'Organic revenue Other', icon: `${process.env.PUBLIC_URL}/static/img/organic_other.svg` },
    { label: 'Paid revenue Other', icon: `${process.env.PUBLIC_URL}/static/img/paid_other.svg` },
  ],
  set1_1: [
    { label: 'Organic transactions Brand Generic', icon: `${process.env.PUBLIC_URL}/static/img/organic_brand_generic.svg` },
    { label: 'Paid transactions Brand Generic', icon: `${process.env.PUBLIC_URL}/static/img/paid_brand_generic.svg` },
    { label: 'Organic transactions Product Line', icon: `${process.env.PUBLIC_URL}/static/img/organic_product_line.svg` },
    { label: 'Paid transactions Product Line', icon: `${process.env.PUBLIC_URL}/static/img/paid_product_line.svg` },
    { label: 'Organic transactions Other', icon: `${process.env.PUBLIC_URL}/static/img/organic_other.svg` },
    { label: 'Paid transactions Other', icon: `${process.env.PUBLIC_URL}/static/img/paid_other.svg` },
  ],
  set2: [
    { label: 'Organic', icon: `${process.env.PUBLIC_URL}/static/img/organic.svg` },
    { label: 'Paid', icon: `${process.env.PUBLIC_URL}/static/img/paid.svg` },
    { label: 'Holistic', icon: `${process.env.PUBLIC_URL}/static/img/holistic.svg` },
  ],
  set3: [
    { label: 'Paid', icon: `${process.env.PUBLIC_URL}/static/img/organic.svg` },
    { label: 'Organic', icon: `${process.env.PUBLIC_URL}/static/img/paid.svg` },
  ],
  set4: [
    { label: 'Paid', icon: `${process.env.PUBLIC_URL}/static/img/paid_rounded.svg` },
    { label: 'Organic', icon: `${process.env.PUBLIC_URL}/static/img/organic_rounded.svg` },
  ]
};

interface CustomLegendProps {
  legendSet: keyof LegendsData;
}

const CustomLegend: React.FC<CustomLegendProps> = ({ legendSet }) => {
  const legends = legendsData[legendSet];

  return React.createElement(
    'div',
    { style: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '10px' } },
    legends.map((legend, index) =>
      React.createElement(
        'div',
        { key: index, style: { display: 'flex', alignItems: 'center', marginBottom: '5px' } },
        React.createElement('img', {
          src: legend.icon,
          alt: legend.label,
          style: { width: '16px', height: '16px', marginRight: '10px' }
        }),
        React.createElement('span', null, legend.label)
      )
    )
  );
};

export default CustomLegend;
