import { CompIntSearchOptions } from 'types';
import { useOutletContext } from 'react-router-dom';

export type CompIntContextState = {
  clientDomain: string;
  searchOptions: CompIntSearchOptions;
  selectedSearchOptions: CompIntSearchOptions;
  onSelectSearchGroups: (selections: string[]) => void;
  onSelectSearchTerms: (selections: string[]) => void;
};

export const useCmpIntContext = () => useOutletContext<CompIntContextState>();
