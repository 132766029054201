import React, { useState, forwardRef, useImperativeHandle } from 'react';

interface GoogleSearchAdsAccount {
  customer_id: string[];
  login_customer_id: string;
}

interface GoogleAdsAccount {
  customer_id: string[];
  login_customer_id: string;
}

interface GoogleSearchConsoleAccount {
  properties: string[];
}

interface GoogleAnalyticsAccount {
  property_id: string;
}

interface MicrosoftAdsAccount {
  customer_id: string;
  account_id: string;
}

type Account =
  | GoogleSearchAdsAccount
  | GoogleAdsAccount
  | GoogleSearchConsoleAccount
  | GoogleAnalyticsAccount
  | MicrosoftAdsAccount;

interface Api {
  name: string;
  id: string;
  logo: string;
  accounts: Account[];
}

interface APIsSetupProps {
  apis: Api[];
  setApis: React.Dispatch<React.SetStateAction<Api[]>>;
}

const handleAccountChange = (
  account: Account,
  key: keyof Account,
  value: string | string[],
  index?: number
) => {
  if (isGoogleSearchAdsOrGoogleAdsAccount(account) && key === 'customer_id' && typeof index === 'number') {
    account.customer_id[index] = value as string;
  } else if (isGoogleSearchConsoleAccount(account) && key === 'properties' && typeof index === 'number') {
    account.properties[index] = value as string;
  } else if (isMicrosoftAdsAccount(account)) {
    if (key === 'customer_id' || key === 'account_id') {
      account[key as 'customer_id' | 'account_id'] = value as string;
    }
  } else {
    (account as unknown as { [key: string]: string })[key] = value as string;
  }
};

const isGoogleSearchConsoleAccount = (
  account: Account
): account is GoogleSearchConsoleAccount => {
  return 'properties' in account;
};

const isGoogleSearchAdsOrGoogleAdsAccount = (
  account: Account
): account is GoogleSearchAdsAccount | GoogleAdsAccount => {
  return 'login_customer_id' in account;
};

const isGoogleAnalyticsAccount = (
  account: Account
): account is GoogleAnalyticsAccount => {
  return 'property_id' in account;
};

const isMicrosoftAdsAccount = (
  account: Account
): account is MicrosoftAdsAccount => {
  return 'account_id' in account;
};

const APIsSetup = forwardRef<{ validateAPIs: () => boolean }, APIsSetupProps>(
  ({ apis, setApis }, ref) => {
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useImperativeHandle(ref, () => ({
      validateAPIs,
    }));

    const handleAddMore = (apiIndex: number) => {
      const newApis = [...apis];
      const account = newApis[apiIndex].accounts[0];
    
      if (isGoogleSearchAdsOrGoogleAdsAccount(account)) {
        if (account.customer_id.length < 3) {
          account.customer_id.push('');
        }
      } else if (isGoogleSearchConsoleAccount(account)) {
        if (account.properties.length < 3) {
          account.properties.push('');
        }
      }
      
      setApis(newApis);
    };    

    const handleRemove = (apiIndex: number, itemIndex: number) => {
      const newApis = [...apis];
      const account = newApis[apiIndex].accounts[0];
      if (isGoogleSearchAdsOrGoogleAdsAccount(account)) {
        if (account.customer_id.length > 1) {
          account.customer_id.splice(itemIndex, 1);
        }
      } else if (isGoogleSearchConsoleAccount(account)) {
        if (account.properties.length > 1) {
          account.properties.splice(itemIndex, 1);
        }
      }
      setApis(newApis);
    };

    const validateInput = (name: string, value: string) => {
      if (name === 'properties') {
        return '';
      } else {
        if (value === '') return '';
        if (!/^\d*$/.test(value)) {
          return `${name.replace('_', ' ')} should only contain numbers.`;
        }
      }
      return '';
    };

    const validateAPIs = () => {
      const newErrors: { [key: string]: string } = {};
      apis.forEach((api, apiIndex) => {
        api.accounts.forEach((account, accountIndex) => {
          if (isGoogleSearchAdsOrGoogleAdsAccount(account)) {
            account.customer_id.forEach((id, idIndex) => {
              const customerIDError = validateInput('customer_id', id);
              if (customerIDError) {
                newErrors[`${apiIndex}-${accountIndex}-customer_id-${idIndex}`] = customerIDError;
              }
            });
            const loginCustomerIDError = validateInput('login_customer_id', account.login_customer_id);
            if (loginCustomerIDError) {
              newErrors[`${apiIndex}-${accountIndex}-login_customer_id`] = loginCustomerIDError;
            }
          } else if (isGoogleSearchConsoleAccount(account)) {
            account.properties.forEach((property, propertyIndex) => {
              const propertiesError = validateInput('properties', property);
              if (propertiesError) {
                newErrors[`${apiIndex}-${accountIndex}-properties-${propertyIndex}`] = propertiesError;
              }
            });
          } else if (isGoogleAnalyticsAccount(account)) {
            const propertyIDError = validateInput('property_id', account.property_id);
            if (propertyIDError) {
              newErrors[`${apiIndex}-${accountIndex}-property_id`] = propertyIDError;
            }
          } else if (isMicrosoftAdsAccount(account)) {
            const customerIDError = validateInput('customer_id', account.customer_id);
            if (customerIDError) {
              newErrors[`${apiIndex}-${accountIndex}-customer_id`] = customerIDError;
            }
            const accountIDError = validateInput('account_id', account.account_id);
            if (accountIDError) {
              newErrors[`${apiIndex}-${accountIndex}-account_id`] = accountIDError;
            }
          }
        });
      });
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      apiIndex: number,
      accountIndex: number,
      itemIndex?: number
    ) => {
      const { name, value } = e.target;
      const newApis = [...apis];
      const account = newApis[apiIndex].accounts[accountIndex] as Account;

      handleAccountChange(account, name as keyof Account, value, itemIndex);

      const error = validateInput(name, value);

      const errorKey = itemIndex !== undefined
        ? `${apiIndex}-${accountIndex}-${name}-${itemIndex}`
        : `${apiIndex}-${accountIndex}-${name}`;

      setErrors((prevErrors) => ({
        ...prevErrors,
        [errorKey]: error,
      }));

      setApis(newApis);
    };

    return (
      <div>
        <h2 className="text-[32px] font-bold">APIs Setup</h2>
        <h3 className="font-bold text-grayscale70 mt-2">Configure and integrate APIs</h3>
        <div className="mt-8 space-y-8">
          {apis.map((api, index) => (
            <div key={api.id} className="space-y-2">
              <div className="flex items-center space-x-2">
                <img src={api.logo} alt={`${api.name} logo`} className="w-6 h-6" />
                <span className="text-primarygray font-bold">{api.name}</span>
                {['google-search-ads-360', 'google-ads', 'google-search-console'].includes(api.id) && (
                  <div className="border border-thirdgray rounded-[20px] px-2">
                    <button onClick={() => handleAddMore(index)} className="text-sm text-thirdgray font-bold flex items-center">
                      <img
                        src={`${process.env.PUBLIC_URL}/static/img/plus_gray.svg`}
                        alt="plus_icon"
                        className="w-[18px] h-[18px] mr-1"
                      />
                      ADD MORE
                    </button>
                  </div>
                )}
              </div>
              {isGoogleSearchAdsOrGoogleAdsAccount(api.accounts[0]) && Array.isArray(api.accounts[0].customer_id) && (
                api.accounts[0].customer_id.map((customerId: string, customerIdIndex: number) => (
                  <div key={customerIdIndex} className="relative flex flex-col space-y-4">
                    {customerIdIndex > 0 && (
                      <div className="absolute -top-2 right-0">
                        <button
                          onClick={() => handleRemove(index, customerIdIndex)}
                          className="text-error"
                        >
                          &times;
                        </button>
                      </div>
                    )}
                    <div className="flex items-center space-x-6">
                      <div className="flex items-center space-x-2 flex-grow">
                        <label className="text-sm font-bold">Customer ID</label>
                        <input
                          type="text"
                          placeholder="Type here"
                          name="customer_id"
                          value={customerId || ''}
                          onChange={(e) => handleChange(e, index, 0, customerIdIndex)}
                          className="text-gray600 text-sm border border-gray200 rounded p-2 flex-grow"
                        />
                      </div>
                      {customerIdIndex === 0 && (
                        <div className="flex items-center space-x-2 flex-grow">
                          <label className="text-sm font-bold">Login Customer ID</label>
                          <input
                            type="text"
                            placeholder="Type here"
                            name="login_customer_id"
                            value={(api.accounts[0] as GoogleSearchAdsAccount | GoogleAdsAccount).login_customer_id || ''}
                            onChange={(e) => handleChange(e, index, 0)}
                            className="text-gray600 text-sm border border-gray200 rounded p-2 flex-grow"
                          />
                        </div>
                      )}
                    </div>
                    {errors[`${index}-0-customer_id-${customerIdIndex}`] && (
                      <span className="text-error text-sm">
                        {errors[`${index}-0-customer_id-${customerIdIndex}`]}
                      </span>
                    )}
                  </div>
                ))
              )}
              {api.id === 'google-search-console' && isGoogleSearchConsoleAccount(api.accounts[0]) && api.accounts[0].properties.map((property, propertyIndex) => (
                <div key={propertyIndex} className="relative flex flex-col space-y-4">
                  {propertyIndex > 0 && (
                    <div className="absolute -top-2 right-0">
                      <button
                        onClick={() => handleRemove(index, propertyIndex)}
                        className="text-error"
                      >
                        &times;
                      </button>
                    </div>
                  )}
                  <div className="flex items-center space-x-2 flex-grow">
                    <label className="text-sm font-bold">Properties</label>
                    <input
                      type="text"
                      placeholder="Type here"
                      name="properties"
                      value={property || ''}
                      onChange={(e) => handleChange(e, index, 0, propertyIndex)}
                      className="text-gray600 text-sm border border-gray200 rounded p-2 flex-grow"
                    />
                  </div>
                  {errors[`${index}-0-properties-${propertyIndex}`] && (
                    <span className="text-error text-sm">
                      {errors[`${index}-0-properties-${propertyIndex}`]}
                    </span>
                  )}
                </div>
              ))}
              {api.id === 'google-analytics' && isGoogleAnalyticsAccount(api.accounts[0]) && (
                <div className="flex items-center space-x-2 flex-grow">
                  <label className="text-sm font-bold">Property ID</label>
                  <input
                    type="text"
                    placeholder="Type here"
                    name="property_id"
                    value={(api.accounts[0] as GoogleAnalyticsAccount).property_id || ''}
                    onChange={(e) => handleChange(e, index, 0)}
                    className="text-gray600 text-sm border border-gray200 rounded p-2 flex-grow"
                  />
                </div>
              )}
              {api.id === 'microsoft-ads' && isMicrosoftAdsAccount(api.accounts[0]) && (
                <div className="flex items-center space-x-6">
                  <div className="flex items-center space-x-2 flex-grow">
                    <label className="text-sm font-bold">Customer ID</label>
                    <input
                      type="text"
                      placeholder="Type here"
                      name="customer_id"
                      value={api.accounts[0].customer_id || ''}
                      onChange={(e) => handleChange(e, index, 0)}
                      className="text-gray600 text-sm border border-gray200 rounded p-2 flex-grow"
                    />
                  </div>
                  <div className="flex items-center space-x-2 flex-grow">
                    <label className="text-sm font-bold">Account ID</label>
                    <input
                      type="text"
                      placeholder="Type here"
                      name="account_id"
                      value={api.accounts[0].account_id || ''}
                      onChange={(e) => handleChange(e, index, 0)}
                      className="text-gray600 text-sm border border-gray200 rounded p-2 flex-grow"
                    />
                  </div>
                </div>
              )}
              {errors[`${index}-0-login_customer_id`] && (
                <span className="text-error text-sm">
                  {errors[`${index}-0-login_customer_id`]}
                </span>
              )}
              {errors[`${index}-0-property_id`] && (
                <span className="text-error text-sm">
                  {errors[`${index}-0-property_id`]}
                </span>
              )}
              {errors[`${index}-0-customer_id`] && (
                <span className="text-error text-sm">
                  {errors[`${index}-0-customer_id`]}
                </span>
              )}
              {errors[`${index}-0-account_id`] && (
                <span className="text-error text-sm">
                  {errors[`${index}-0-account_id`]}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
);

APIsSetup.displayName = 'APIsSetup';

export default APIsSetup;