import React, { useState } from 'react';

interface ThreeStateCheckboxProps {
    preselectedState?: string;
    onChange?: (state: string) => void;
}

const ThreeStateCheckbox: React.FC<ThreeStateCheckboxProps> = ({ preselectedState = 'empty', onChange}) => {
    const [state, setState] = useState<string>(preselectedState);

    const toggleState = () => {
        let newState = '';
        if (state === 'empty') {
            newState = 'positive';
        } else if (state === 'positive') {
            newState = 'negative';
        } else {
            newState = 'empty';
        }

        setState(newState);

        if (onChange) {
            onChange(newState);
        }
    };

    const getCheckboxLabel = () => {
        switch (state) {
            case 'positive':
                return <img src={`${process.env.PUBLIC_URL}/static/img/plus_icon_bg_green.svg`} alt="checkbox_active" className="w-5 h-5 min-w-5 min-h-5" />;
            case 'negative':
                return <img src={`${process.env.PUBLIC_URL}/static/img/Checkbox_red_dash.svg`} alt="checkbox_onboard" className="w-5 h-5 min-w-5 min-h-5" />;
            default:
                return <img src={`${process.env.PUBLIC_URL}/static/img/Checkbox_gray_x.svg`} alt="checkbox_inactive" className="w-5 h-5 min-w-5 min-h-5" />;
        }
    };

    return (
        <div onClick={toggleState}
        className={`select-none cursor-pointer flex items-center justify-center`}>
            <span className='text-lg'>{getCheckboxLabel()}</span>
        </div>
    );
};

export default ThreeStateCheckbox;
