import { currentENV } from 'api';
import Snippets, { SnippetTagsParams } from './snippets';

type DataLayerParams = {
  dataLayer?: unknown;
  dataLayerName?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export const gtmENVid = () => {
  switch (currentENV()) {
    case 'prod':
      return 'GTM-N2DX4MV5';

    case 'qa':
      return 'GTM-TTKSS5WQ';

    case 'local':
      return;
  }
};

const TagManager = {
  dataScript: function (dataLayer: string) {
    const script = document.createElement('script');
    script.innerHTML = dataLayer;
    return script;
  },

  gtm: function (params: SnippetTagsParams) {
    const snippets = Snippets.tags(params);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };

    const script = () => {
      const script = document.createElement('script');
      script.innerHTML = snippets.script;
      return script;
    };

    const dataScript = this.dataScript(snippets.dataLayerVar);

    return {
      noScript,
      script,
      dataScript,
    };
  },

  initialize: function ({
    id,
    events = {},
    dataLayer,
    dataLayerName = 'dataLayer',
    auth = '',
    preview = '',
  }: SnippetTagsParams) {
    if (!id) return;

    const gtm = this.gtm({
      id,
      events: events,
      dataLayer: dataLayer || undefined,
      dataLayerName: dataLayerName,
      auth,
      preview,
    });

    if (dataLayer) document.head.appendChild(gtm.dataScript);
    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },

  dataLayer: function ({
    dataLayer,
    dataLayerName = 'dataLayer',
  }: DataLayerParams) {
    if (window[dataLayerName]) {
      return window[dataLayerName].push(dataLayer);
    }

    const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
    const dataScript = this.dataScript(snippets);

    document.head.insertBefore(dataScript, document.head.childNodes[0]);
  },
};

export default TagManager;
