// COMPONENTS
import DataTable, { TableColumns } from '../DataTable';

// TYPES
import { ChangeLogTableRowData } from 'types';

interface ChangeLogTableProps {
  data: ChangeLogTableRowData[];
}

const columns: TableColumns<ChangeLogTableRowData>[] = [
  { header: 'Keyword', bind: 'keyword' },
  { header: 'Status', bind: 'status' },
  { header: 'Record Count', bind: 'total_count' },
];

function ChangeLogTable({ data }: ChangeLogTableProps) {
  // JSX
  return <DataTable data={data} columns={columns} pagination={false} />;
}

export default ChangeLogTable;
