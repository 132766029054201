import { useQuery } from 'react-query';
import React, { useState, useEffect, useRef } from 'react';

// COMPONENTS
import Dropdown from '../../Dropdown';
import LoadingSpinner from './LoadingSpinner';

// UTILS
import { toFixedNum } from 'utils';
import { downloadImage, downloadXLS } from 'utils/download';

interface ClusterData {
  searchTerm: string;
  organicTransactions: number;
  organicRevenue: number;
  paidTransactions: number;
  paidRevenue: number;
  cluster_category: string;
}

interface ClusterDetailsProps {
  startDate: string;
  endDate: string;
}

interface ApiData {
  search_terms: {
    [key: string]: Array<{
      cluster_category: string;
      search_term: string;
      organic_transactions: number;
      organic_revenue: number;
      paid_transactions: number;
      paid_revenue: number;
    }>;
  };
}

function ClusterDetails({ startDate, endDate }: ClusterDetailsProps) {
  const [clusterData, setClusterData] = useState<ClusterData[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof ClusterData | null;
    direction: 'ascending' | 'descending';
  }>({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isError404, setIsError404] = useState(false);
  const itemsPerPage = 10;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const fetchClusterData = async (): Promise<ApiData> => {
    const response = await fetch(
      `/get_insights_category_keyword_data/${startDate}/${endDate}`
    );
    if (!response.ok) {
      if (response.status === 404) {
        setIsError404(true);
      }
      const errorData = await response.json();
      if (errorData.error === "'json_data'") {
        throw new Error(
          'No data for this time period, please change the date range'
        );
      }
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  };

  const { data, isLoading, isError } = useQuery(
    ['clusterData', startDate, endDate],
    fetchClusterData
  );

  const isAllNumbersZero = (data: ApiData): boolean => {
    return Object.values(data.search_terms)
      .flat()
      .every(
        (item) =>
          item.organic_revenue === 0 &&
          item.paid_revenue === 0 &&
          item.organic_transactions === 0 &&
          item.paid_transactions === 0
      );
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (data) {
      if (isAllNumbersZero(data)) {
        return;
      }
      const searchTerms = data.search_terms;
      const formattedData = Object.entries(searchTerms).flatMap(
        ([, detailsArray]) =>
          detailsArray.map((details) => ({
            searchTerm: details.search_term,
            organicTransactions: details.organic_transactions,
            organicRevenue: details.organic_revenue,
            paidTransactions: details.paid_transactions,
            paidRevenue: details.paid_revenue,
            cluster_category: details.cluster_category,
          }))
      );
      setClusterData(formattedData);
    }
  }, [data]);

  const uniqueCategories = Array.from(
    new Set(
      Object.values(data?.search_terms || {}).flatMap((detailsArray) =>
        detailsArray.map((details) => details.cluster_category)
      )
    )
  );

  const headers: { key: keyof ClusterData; label: string }[] = [
    { key: 'searchTerm', label: 'Search Term' },
    { key: 'organicTransactions', label: 'Organic Transactions' },
    { key: 'organicRevenue', label: 'Organic Revenue' },
    { key: 'paidTransactions', label: 'Paid Transactions' },
    { key: 'paidRevenue', label: 'Paid Revenue' },
  ];

  const handleSort = (key: keyof ClusterData) => {
    let direction: 'ascending' | 'descending' = 'ascending';

    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = [...clusterData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setClusterData(sortedData);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleCategoryChange = (option: string) => {
    setSelectedCategory(option === 'All Categories' ? '' : option);
    setCurrentPage(1); // Reset to first page on new category filter
  };

  const filteredData = clusterData.filter(
    (data) =>
      data.searchTerm.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory ? data.cluster_category === selectedCategory : true)
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError404) {
    return (
      <div className="relative">
        <div className="bg-light rounded-2xl shadow-sm mt-8 blur">
          <div className="flex justify-between items-center border-b border-success mb-2">
            <div>
              <h2 className="text-primarygray text-2xl font-bold mb-2 pl-4 pt-2">
                Cluster Details
              </h2>
              <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">
                Download detail per search term, campaign type, cluster, and
                category
              </h3>
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
              alt="dots"
              className="h-6 w-6 mr-4"
            />
          </div>
          <div className="overflow-x-auto mx-4 px-1">
            <table className="min-w-full bg-light mt-4">
              <thead className="uppercase">
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      className="py-2 px-1 text-primarygray text-xs font-normal text-left whitespace-nowrap"
                    >
                      {header.label}
                      <img
                        src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`}
                        alt="sort icon"
                        className="inline ml-1 cursor-pointer"
                        onClick={() => handleSort(header.key)}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedData.length > 0 ? (
                  paginatedData.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className={
                        rowIndex % 2 === 0 ? 'bg-fourthgray' : 'bg-gray100'
                      }
                    >
                      <td className="py-2 px-1 text-primarygray text-xs font-normal text-left">
                        {row.searchTerm}
                      </td>
                      <td className="py-2 text-primarygray text-xs font-normal text-left">
                        {toFixedNum(row.organicTransactions)}
                      </td>
                      <td className="py-2 text-primarygray text-xs font-normal text-left">{`$${toFixedNum(
                        row.organicRevenue
                      )}`}</td>
                      <td className="py-2 text-primarygray text-xs font-normal text-left">
                        {toFixedNum(row.paidTransactions)}
                      </td>
                      <td className="py-2 text-primarygray text-xs font-normal text-left">{`$${toFixedNum(
                        row.paidRevenue
                      )}`}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center py-10 text-secondarygray text-3xl font-bold bg-fourthgray rounded mx-4"
                    ></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col lg:flex-row justify-end items-center px-5 py-2">
            <span className="text-xs text-gray700 font-normal mr-3 mb-2 lg:mb-0">
              Showing
              <span className="text-secondarygray font-bold tabular-nums">
                {' '}
                {Math.min(
                  (currentPage - 1) * itemsPerPage + 1,
                  filteredData.length
                )}
              </span>{' '}
              to
              <span className="text-secondarygray font-bold tabular-nums">
                {' '}
                {Math.min(currentPage * itemsPerPage, filteredData.length)}
              </span>{' '}
              of
              <span className="text-secondarygray font-bold tabular-nums">
                {' '}
                {filteredData.length}
              </span>{' '}
              Entries
            </span>
            <div className="flex items-center relative">
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className="text-sm text-light bg-primarygray px-3 py-[6px] rounded-l-lg"
              >
                PREV
              </button>
              <div
                style={{
                  width: '1px',
                  backgroundColor: 'white',
                  margin: '0.1px',
                }}
              ></div>
              <button
                onClick={handleNextPage}
                disabled={
                  currentPage === Math.ceil(filteredData.length / itemsPerPage)
                }
                className="text-sm text-light bg-primarygray px-3 py-[6px] rounded-r-lg"
              >
                NEXT
              </button>
            </div>
          </div>
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-light mt-4 bg-thirdgray rounded-2xl shadow-sm py-2 px-4">
            An error has occurred
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div>No data for this time period, please change the date range</div>
    );
  }

  if (data && isAllNumbersZero(data)) {
    return null;
  }

  const downloadClusterDataXLS = () => {
    const headers = [
      'Search Term',
      'Organic Transactions',
      'Organic Revenue',
      'Paid Transactions',
      'Paid Revenue',
      'Cluster Category',
    ];

    const labels = clusterData.map((item) => item.searchTerm);

    const columns = [
      clusterData.map((item) => toFixedNum(item.organicTransactions)),
      clusterData.map((item) => `$${toFixedNum(item.organicRevenue)}`),
      clusterData.map((item) => toFixedNum(item.paidTransactions)),
      clusterData.map((item) => `$${toFixedNum(item.paidRevenue)}`),
      clusterData.map((item) => item.cluster_category),
    ];

    downloadXLS('cluster_details', headers, labels, columns);
  };

  const downloadChartImage = () => {
    downloadImage('cluster_details');
  };

  return (
    <div id="cluster_details" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex flex-col lg:flex-row justify-between px-4 pt-2 border-b border-success pb-2">
        <div className="mb-4 lg:mb-0">
          <h2 className="text-primarygray text-2xl font-bold mb-2">
            Cluster Details
          </h2>
          <h3 className="text-primarygray text-sm font-bold">
            Download detail per search term, campaign type, cluster, and
            category
          </h3>
        </div>
        <div className="flex flex-col lg:flex-row items-center w-full lg:w-auto gap-4">
          <div className="relative text-thirdgray text-sm font-bold border border-gray300 rounded-full w-full lg:w-[401px] mb-4 lg:mb-0">
            <input
              type="search"
              name="search"
              placeholder="Search"
              className="h-10 text-sm pl-10 pr-4 w-full rounded-full"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <img
              src={`${process.env.PUBLIC_URL}/static/img/Trailing_icon.svg`}
              alt="search_ico"
              className="absolute left-3 top-3 h-4 w-4 fill-current"
            />
          </div>
          <Dropdown
            mode="light"
            title={selectedCategory ? selectedCategory : 'All Categories'}
            options={['All Categories', ...(uniqueCategories || [])]}
            onOptionClick={handleCategoryChange}
            position="right-0"
            dropdownLength="max-h-36"
          />
          <div
            className="flex items-center"
            ref={dropdownRef}
            id="downloadDropdownMenu-cluster_details"
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
              alt="dots"
              className="h-6 w-6 lg:ml-4 cursor-pointer"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div
                className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
              >
                <ul>
                  {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadClusterDataXLS();
                      closeDropdown();
                    }}
                  >
                    Download XLS
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartImage();
                      closeDropdown();
                    }}
                  >
                    Download PNG image
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="overflow-x-auto mx-4 px-1">
        <table className="min-w-full bg-light mt-4">
          <thead className="uppercase">
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  className="py-2 px-1 text-primarygray text-xs font-normal text-left whitespace-nowrap"
                >
                  {header.label}
                  <img
                    src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`}
                    alt="sort icon"
                    className="inline ml-1 cursor-pointer"
                    onClick={() => handleSort(header.key)}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedData.length > 0 ? (
              paginatedData.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={
                    rowIndex % 2 === 0 ? 'bg-fourthgray' : 'bg-gray100'
                  }
                >
                  <td className="py-2 px-1 text-primarygray text-xs font-normal text-left">
                    {row.searchTerm}
                  </td>
                  <td className="py-2 text-primarygray text-xs font-normal text-left">
                    {toFixedNum(row.organicTransactions)}
                  </td>
                  <td className="py-2 text-primarygray text-xs font-normal text-left">{`$${toFixedNum(
                    row.organicRevenue
                  )}`}</td>
                  <td className="py-2 text-primarygray text-xs font-normal text-left">
                    {toFixedNum(row.paidTransactions)}
                  </td>
                  <td className="py-2 text-primarygray text-xs font-normal text-left">{`$${toFixedNum(
                    row.paidRevenue
                  )}`}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-center py-10 text-secondarygray text-3xl font-bold bg-fourthgray rounded mx-4"
                ></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col lg:flex-row justify-end items-center px-5 py-2">
        <span className="text-xs text-gray700 font-normal mr-3 mb-2 lg:mb-0">
          Showing
          <span className="text-secondarygray font-bold tabular-nums">
            {' '}
            {Math.min(
              (currentPage - 1) * itemsPerPage + 1,
              filteredData.length
            )}
          </span>{' '}
          to
          <span className="text-secondarygray font-bold tabular-nums">
            {' '}
            {Math.min(currentPage * itemsPerPage, filteredData.length)}
          </span>{' '}
          of
          <span className="text-secondarygray font-bold tabular-nums">
            {' '}
            {filteredData.length}
          </span>{' '}
          Entries
        </span>
        <div className="flex items-center relative">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="text-sm text-light bg-primarygray px-3 py-[6px] rounded-l-lg"
          >
            PREV
          </button>
          <div
            style={{ width: '1px', backgroundColor: 'white', margin: '0.1px' }}
          ></div>
          <button
            onClick={handleNextPage}
            disabled={
              currentPage === Math.ceil(filteredData.length / itemsPerPage)
            }
            className="text-sm text-light bg-primarygray px-3 py-[6px] rounded-r-lg"
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  );
}

export default ClusterDetails;
