import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import CustomLegend3 from './CustomLegend3';
import { ChartOptions } from 'chart.js';

interface TimeSeriesData {
  period_formatted: string;
  total_demand: number;
}

interface BrandTimeSeriesData {
  period_formatted: string;
  total_retail_demand: number;
}

interface BrandData {
  label: string;
  color: string;
  visibility: boolean;
}

interface RCLineChartProps {
  totalDemandPerPeriod: TimeSeriesData[];
  totalRetailDemandPerPeriod: BrandTimeSeriesData[];
}

const formatYAxisTick = (tickValue: number) => {
  if (tickValue >= 1_000_000) {
    return `${(tickValue / 1_000_000).toFixed(1)}M`;
  } else if (tickValue >= 1_000) {
    return `${(tickValue / 1_000).toFixed(1)}K`;
  } else {
    return tickValue.toString();
  }
};

const RCLineChart: React.FC<RCLineChartProps> = ({ totalDemandPerPeriod, totalRetailDemandPerPeriod }) => {
  const labels = totalDemandPerPeriod.map(item => item.period_formatted);

  const demandData = totalDemandPerPeriod.map(item => item.total_demand);
  const brandDemandData = totalRetailDemandPerPeriod.map(item => item.total_retail_demand);

  const initialBrandsData: BrandData[] = [
    { label: 'Retail Demand', color: '#222222', visibility: true },
    { label: 'Demand', color: '#595959', visibility: true },
  ];

  const [brandsData, setBrandsData] = useState(initialBrandsData);

  const handleLegendClick = (label: string) => {
    setBrandsData(brandsData.map(brand => (
      brand.label === label ? { ...brand, visibility: !brand.visibility } : brand
    )));
  };

  const visibility = brandsData.reduce((acc, brand) => {
    acc[brand.label] = brand.visibility;
    return acc;
  }, {} as Record<string, boolean>);

  const visibleDatasets = brandsData
    .filter(brand => brand.visibility)
    .map(brand => {
      if (brand.label === 'Retail Demand') {
        return {
          label: brand.label,
          data: brandDemandData,
          borderColor: brand.color,
          borderWidth: 1.5,
          fill: false,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: brand.color,
          pointRadius: 1.5,
          tension: 0.2,
        };
      } else {
        return {
          label: brand.label,
          data: demandData,
          borderColor: brand.color,
          borderWidth: 1.5,
          fill: false,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: brand.color,
          pointRadius: 1.5,
          tension: 0.4,
        };
      }
    });

  const revenueOverviewData = {
    labels: labels,
    datasets: visibleDatasets,
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Growth',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return formatYAxisTick(tickValue);
            }
            return tickValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  return (
    <div className="bg-light rounded-2xl">
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]">
          <Line data={revenueOverviewData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <div className="flex items-center">
            <CustomLegend3
              brandsData={brandsData}
              onLegendClick={handleLegendClick}
              visibility={visibility}
            />
            <div className="ml-2 mb-8 group inline-block relative">
              <img src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`} alt="tooltip" className="tooltip-image" />
              <span className="absolute bottom-full left-1/2 transform -translate-x-[90%] mb-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                <p className='px-4 py-2'>Demand for a given retailer within your<br></br>
                  total category demand
                </p>
                <img src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`} alt="arrow" className="absolute left-[90%] transform -translate-x-1/2 -bottom-[6px]" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RCLineChart;
