import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// CONSTANTS
import { APP_ROUTES } from '../constants';

interface InsightsPageProps {
  setTopBarButton: (button: React.ReactNode) => void;
}

function InsightsPage({ setTopBarButton }: InsightsPageProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === APP_ROUTES.INSIGHTS) {
      navigate(APP_ROUTES.INSIGHTS_OVERVIEW);
    }
  }, [pathname, navigate]);

  useEffect(() => {
    if (pathname.includes(APP_ROUTES.INSIGHTS_RECOMMENDATIONS)) {
      const isConfigActive = pathname === APP_ROUTES.INSIGHTS_CONFIGURATION;

      setTopBarButton(
        <button
          className={`text-sm font-bold py-2 px-4 rounded-full items-center hidden lg:flex ${
            isConfigActive ? 'text-primarygray' : 'bg-success text-light'
          }`}
          onClick={() =>
            navigate(
              isConfigActive
                ? APP_ROUTES.INSIGHTS_RECOMMENDATIONS
                : APP_ROUTES.INSIGHTS_CONFIGURATION
            )
          }
        >
          {isConfigActive ? (
            <>
              <img
                src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
                alt="Configuration"
                className="w-[18px] h-[18px] mr-2 rotate-180"
              />
              BACK
            </>
          ) : (
            <>
              <img
                src={`${process.env.PUBLIC_URL}/static/img/settings-01.svg`}
                alt="Configuration"
                className="w-[18px] h-[18px] mr-2"
              />
              CONFIGURATION
            </>
          )}
        </button>
      );
    } else {
      setTopBarButton(null);
    }
  }, [pathname, navigate, setTopBarButton]);

  const renderMenu = () => (
    <div className="sticky top-[94px] z-10 flex bg-body border-b border-gray300 whitespace-nowrap">
      <div
        className={`text-primarygray font-medium cursor-pointer py-4 px-8 ${
          pathname === APP_ROUTES.INSIGHTS_OVERVIEW
            ? 'border-b-2 border-primarygray'
            : ''
        }`}
        onClick={() => {
          navigate(APP_ROUTES.INSIGHTS_OVERVIEW);
        }}
      >
        Overview
      </div>
      <div
        className={`text-primarygray font-medium cursor-pointer py-4 px-8 ${
          pathname === APP_ROUTES.INSIGHTS_CLUSTER
            ? 'border-b-2 border-primarygray'
            : ''
        }`}
        onClick={() => {
          navigate(APP_ROUTES.INSIGHTS_CLUSTER);
        }}
      >
        Cluster
      </div>
      <div
        className={`text-primarygray font-medium cursor-pointer py-4 px-8 ${
          pathname.includes(APP_ROUTES.INSIGHTS_RECOMMENDATIONS)
            ? 'border-b-2 border-primarygray'
            : ''
        }`}
        onClick={() => {
          navigate(APP_ROUTES.INSIGHTS_RECOMMENDATIONS);
        }}
      >
        Recommendations
      </div>
    </div>
  );

  return (
    <div className="flex flex-col">
      {renderMenu()}
      <div className="flex-1">
        <Outlet />
      </div>
    </div>
  );
}

export default InsightsPage;
