import React, { useState, useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import CustomLegend from '../CustomLegend';
import Dropdown from '../../Dropdown';
import { downloadImage, downloadXLS } from 'utils/download';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import 'chart.js/auto';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface PaidVsOrganicVsPLAData {
  data: {
    [platform: string]: {
      [site: string]: {
        [date: string]: {
          paid: number;
          organic: number;
          pla?: number;
        };
      };
    };
  };
  clientDomain: string;
}

interface Output {
  label: string[];
  columns: (string[] | number[])[];
}

const xlsGetPaidVsOrganicVsPLA = (
  data: PaidVsOrganicVsPLAData, 
  selectedCompetitor: string,
  view: 'desktop' | 'mobile' 
): Output => {
  const dates: string[] = [];
  const paidData: number[] = [];
  const organicData: number[] = [];
  const plaData: number[] = [];

  if (data.data[view] && data.data[view][selectedCompetitor]) {
    Object.keys(data.data[view][selectedCompetitor]).forEach((date) => {
      dates.push(date);
      const values = data.data[view][selectedCompetitor][date];
      paidData.push(values.paid || 0);
      organicData.push(values.organic || 0);
      plaData.push(values.pla || 0);
    });
  }

  const output: Output = {
    label: dates,
    columns: [
      paidData.map((v) => `${v*100}%`),
      organicData.map((v) => `${v*100}%`),
      plaData.map((v) => `${v*100}%`),
    ],
  };

  return output;
};

const PaidVsOrganicVsPLA: React.FC<PaidVsOrganicVsPLAData> = ({ data, clientDomain }) => {
  const [view, setView] = useState<'desktop' | 'mobile'>('desktop');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const defaultData: ChartData<'bar'> = {
    labels: [
      'No Data',
      'No Data',
      'No Data',
      'No Data',
      'No Data',
      'No Data',
      'No Data',
    ],
    datasets: [
      {
        label: 'Paid',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderWidth: 1,
        barThickness: 24,
      },
      {
        label: 'Organic',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(144, 144, 144, 0.80)',
        borderWidth: 1,
        barThickness: 24,
      },
      {
        label: 'PLA',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(217, 217, 217, 0.80)',
        borderWidth: 1,
        barThickness: 24,
      },
    ],
  };

  const [postionDesktopData, setPositionDesktopData] = useState(defaultData);
  const [postionMobileData, setPositionMobileData] = useState(defaultData);

  const combinedSet = new Set<string>();
  ['desktop', 'mobile'].forEach((device) => {
    const deviceKeys = Object.keys(data?.[device] || {});
    deviceKeys.forEach((key) => combinedSet.add(key));
  });

  const competitorOptions: string[] = Array.from(combinedSet);

  const [selectedCompetitor, setSelectedCompetitor] = useState<string>(
    clientDomain ? clientDomain : competitorOptions[0]
  );

  const getBarData = (device: 'desktop' | 'mobile') => {
    const paidData: number[] = [];
    const organicData: number[] = [];
    const plaData: number[] = [];

    if (!data?.[device]?.[selectedCompetitor]) {
      return defaultData;
    }

    Object.keys(data?.[device]?.[selectedCompetitor]).forEach((date) => {
      const values = data?.[device]?.[selectedCompetitor][date];
      paidData.push(parseFloat((values.paid * 100).toFixed(2)));
      organicData.push(parseFloat((values.organic * 100).toFixed(2)));
      plaData.push(parseFloat(((values.pla || 0) * 100).toFixed(2)));
    });

    const barData: ChartData<'bar'> = {
      labels: Object.keys(data?.[device]?.[selectedCompetitor]),
      datasets: [
        {
          label: 'Paid',
          data: paidData,
          backgroundColor: 'rgba(34, 34, 34, 0.80)',
          borderWidth: 1,
          barThickness: 24,
        },
        {
          label: 'Organic',
          data: organicData,
          backgroundColor: 'rgba(144, 144, 144, 0.80)',
          borderWidth: 1,
          barThickness: 24,
        },
        {
          label: 'Shopping',
          data: plaData,
          backgroundColor: 'rgba(217, 217, 217, 0.80)',
          borderWidth: 1,
          barThickness: 24,
        },
      ],
    };

    return barData;
  };

  useEffect(() => {
    setPositionDesktopData(getBarData('desktop'));
    setPositionMobileData(getBarData('mobile'));
  }, [data, view, selectedCompetitor]);

  useEffect(() => {
    setSelectedCompetitor(clientDomain ? clientDomain : competitorOptions[0]);
  }, [data]);

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Date',
          color: '#4D4D4D',
          font: {
            size: 14,
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        grid: {
          color: '#B3B3B3',
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Coverage (%)',
          color: '#4D4D4D',
          font: {
            size: 14,
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          callback: (value) => `${value}%`,
        },
        grid: {
          color: '#B3B3B3',
        },
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const downloadChartImage = () => {
    downloadImage('PaidvsOrganicvsShopping');
  };
  
  const downloadXLSForSelectedView = () => {
    const wrappedData = {
      data: { ...data },
      clientDomain,
    };
    const xlsData = xlsGetPaidVsOrganicVsPLA(wrappedData, selectedCompetitor, view);
    downloadXLS(
      `PaidVsOrganicVsPLA_${selectedCompetitor}_${view}`,
      ['Date', 'Paid (%)', 'Organic (%)', 'PLA (%)'],
      xlsData.label,
      xlsData.columns
    );
  };

  return (
    <div id="PaidvsOrganicvsShopping" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold pl-4 pt-2">
            Paid vs Organic vs Shopping
          </h2>
          <p className="text-gray800 text-sm font-bold mb-2 pl-4">
            Understand the SERP visibility for your brand and your chosen
            competitors
          </p>
        </div>
        <div className="flex items-center">
          <div className="flex items-center mr-4 text-xs">
            <span
              className={
                view === 'desktop'
                  ? 'text-primarygray mr-2'
                  : 'text-gray400 mr-2'
              }
            >
              Desktop
            </span>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={view === 'mobile'}
                onChange={() =>
                  setView(view === 'desktop' ? 'mobile' : 'desktop')
                }
              />
              <div className="relative w-10 h-5 bg-lightblue rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
            </label>
            <span
              className={
                view === 'mobile'
                  ? 'text-primarygray ml-2'
                  : 'text-gray400 ml-2'
              }
            >
              Mobile
            </span>
          </div>
          <div className="relative h-6 w-6 mr-4" ref={dropdownRef} id='downloadDropdownMenu-PaidvsOrganicvsShopping'>
            <img
              src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
              alt="dots"
              className="cursor-pointer"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div
                className="absolute right-0 mt-2 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
              >
                <ul>
                  {/* <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={closeDropdown}
                  >
                    View data table
                  </li> */}
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadXLSForSelectedView();
                      closeDropdown();
                    }}
                  >
                    Download XLS
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartImage();
                      closeDropdown();
                    }}
                  >
                    Download PNG image
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex relative px-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[calc(92%-200px)] md:w-[65%] w-[60%] h-[295px]">
          <Bar
            data={view === 'desktop' ? postionDesktopData : postionMobileData}
            options={options}
          />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <CustomLegend legendSet="set2" />
          <div className="mt-12 text-sm font-bold flex flex-col items-start">
            <p className="text-secondarygray">Filter:</p>
            <Dropdown
              title={`${selectedCompetitor}`}
              options={competitorOptions}
              onOptionClick={setSelectedCompetitor}
              dropdownLength='max-h-44'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaidVsOrganicVsPLA;
